import axios, { AxiosError } from 'axios'

import { unauthHttpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

type Result = {
   status: number
}

// dodać token
export function newPasswordService(token: string, payload: NewPasswordPayloadType) {
   return unauthHttpClient
      .post<Result>(`users/reset-password/${token}`, payload)
      .then((res) => ({ status: res.status }))
      .catch((err: Error | AxiosError<BackendError>) => {
         let errorMessage = ''

         if (axios.isAxiosError(err)) {
            errorMessage = err.response?.data?.message || ''
         } else {
            errorMessage = err.message
         }
         consoleMessage('resetPassword newPasswordService()', errorMessage, 'error')
         throw new Error(errorMessage)
      })
}
