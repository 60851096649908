import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import GrayButton from 'shared/components/Buttons/GrayButton'
import Typography from '@mui/material/Typography'

import { ButtonContainer } from './styles'

interface Props {
   status: CooperatingWholesalerStatus
   totalAvailability: Nullable<number>
   errorMessage: Nullable<string>
   isLoading: boolean
   wholesaleUuid: string
   isRefetching: boolean
   netPrice: Nullable<number>
   disabledReconnecting: string[]
   onReportMissingProduct: () => void
   onSwapProduct: () => void
   onRefetchingData: () => void
}

export default function Actions({
   onRefetchingData,
   onReportMissingProduct,
   onSwapProduct,
   disabledReconnecting,
   status,
   netPrice,
   errorMessage,
   wholesaleUuid,
   isRefetching,
   totalAvailability,
   isLoading,
}: Props) {
   const { t } = useTranslation('MAIN_CART')

   function renderConnectionProblemContent() {
      if (!!errorMessage && !errorMessage.includes('NOT_HANDLED')) {
         return (
            <Typography
               sx={{ fontWeight: 'bold', color: 'red', border: '1px solid red', p: 1, borderRadius: '5px' }}
               variant="body1"
            >
               {errorMessage}
            </Typography>
         )
      }
      if (disabledReconnecting.includes(wholesaleUuid)) {
         return (
            <Typography
               sx={{ fontWeight: 'bold', color: 'red', border: '1px solid red', p: 1, borderRadius: '5px' }}
               variant="body1"
            >
               {t('swap.wholesaleReconnectFailed')}
            </Typography>
         )
      }
      return (
         <Tooltip title={t('swap.tryAgainTooltip') || ''}>
            <span>
               <ContainedButton
                  disabled={isRefetching || disabledReconnecting.includes(wholesaleUuid)}
                  variant="contained"
                  onClick={onRefetchingData}
               >
                  {t('swap.tryAgain')}
               </ContainedButton>
            </span>
         </Tooltip>
      )
   }

   if (status === 'ONLINE_CONNECTION_PROBLEM' || (status === 'OK_ONLINE_AVAILABILITY' && !netPrice)) {
      return <ButtonContainer>{renderConnectionProblemContent()}</ButtonContainer>
   }
   if (!totalAvailability) {
      return (
         <ButtonContainer>
            <Tooltip
               placement="right"
               disableHoverListener={status !== 'OK_ONLINE_AVAILABILITY'}
               title={t('swap.sendReportTooltip') || ''}
            >
               <span>
                  <GrayButton
                     variant="outlined"
                     type="button"
                     onClick={onReportMissingProduct}
                     disabled={status !== 'OK_ONLINE_AVAILABILITY' || isLoading}
                  >
                     {t('swap.reportButton')}
                  </GrayButton>
               </span>
            </Tooltip>
         </ButtonContainer>
      )
   }
   return (
      <ButtonContainer>
         <OutlinedButton
            variant="outlined"
            onClick={onSwapProduct}
            disabled={status !== 'OK_ONLINE_AVAILABILITY' || isLoading}
         >
            {t('swap.swapButton')}
         </OutlinedButton>
      </ButtonContainer>
   )
}
