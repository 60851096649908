import { AxiosError } from 'axios'
import { unauthHttpClient } from 'shared/lib'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { SearchResponseType } from '../types'

export function getSearchDataService(reflinkUuid: string, verificationHeader: string, searchPhrase: string) {
   let errMessage = ''
   return unauthHttpClient
      .post<SearchResponseType>(
         `/client-carts/reflinks/${reflinkUuid}/products/search`,
         { searchPhrase },
         {
            headers: {
               'X-Reflink-Verification-Code': verificationHeader,
            },
         }
      )
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((err: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(err)) {
            errMessage = err.response?.data.errors[0].defaultMessage || 'Something went wrong with checkTokenService'
         } else {
            errMessage = err.message
         }
         throw new Error(errMessage)
      })
}
