import { useSearchParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import TableSortLabel from '@mui/material/TableSortLabel'
import Typography from '@mui/material/Typography'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import Collapse from '@mui/material/Collapse'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'

import { httpClient } from 'shared/lib'

import { FetchErpProductType, SortingDetailsType, WholesaleStateType } from './types'
import { GetAvailabilityDot } from './helpers/getAvailabilityDot'
import { fetchErpData, getWholesalesService } from './services'
import ProductInfo from './components/ProductInfo'
import {
   StyledBoldTableCell,
   StyledTable,
   StyledTableCell,
   StyledTableHeadCell,
   StyledLink,
   TableContainer,
   ClickableTableRow,
} from './styles'
import { en, pl, de } from './locales'

export default function ErpProductCard() {
   const [searchParams] = useSearchParams()
   const { i18n, t } = useTranslation(['TRANSLATION', 'ERP_PRODUCT_CARD'])
   const [sortDetails, setSortDetails] = useState<SortingDetailsType>({
      sortBy: 'wholesalePrice',
      sortingMethod: 'asc',
   })
   const [wholesales, setWholesales] = useState<WholesaleStateType[]>([])
   const [opened, setOpened] = useState<string[]>([])
   const [data, setData] = useState<FetchErpProductType>()

   i18n.addResourceBundle('pl-PL', 'ERP_PRODUCT_CARD', pl)
   i18n.addResourceBundle('en-EN', 'ERP_PRODUCT_CARD', en)
   i18n.addResourceBundle('de-DE', 'ERP_PRODUCT_CARD', de)

   function handleRowOpen(productUuid: string) {
      if (opened.includes(productUuid)) {
         setOpened((prevState) => prevState.filter((item) => item !== productUuid))
      } else {
         getWholesales(productUuid)
      }
   }

   function getWholesales(productUuid: string) {
      if (!wholesales.find((item) => item.uuid === productUuid)) {
         getWholesalesService(productUuid).then((res) => {
            setWholesales([...wholesales, res])
         })
      }
      setOpened([...opened, productUuid])
   }

   function handleSortingChange(columnName: 'wholesalePrice' | 'retailPrice') {
      if (sortDetails.sortingMethod === 'asc' && sortDetails.sortBy === columnName) {
         return setSortDetails({ sortBy: columnName, sortingMethod: 'desc' })
      }
      return setSortDetails({ sortBy: columnName, sortingMethod: 'asc' })
   }

   function getPriceOrNoData(price: number | undefined) {
      if (price) {
         return t('TRANSLATION:price', { price })
      }
      return t('ERP_PRODUCT_CARD:noData')
   }

   function sortTable(a: any, b: any, sortBy: 'wholesalePrice' | 'retailPrice', sortingMethod: 'asc' | 'desc') {
      if (sortingMethod === 'desc') {
         if (a[sortBy] === null) return 1
         if (b[sortBy] === null) return -1
         if (b[sortBy] < a[sortBy]) return -1
         if (b[sortBy] > a[sortBy]) return 1
      } else {
         if (a[sortBy] === null) return 1
         if (b[sortBy] === null) return -1
         if (b[sortBy] < a[sortBy]) return 1
         if (b[sortBy] > a[sortBy]) return -1
      }
      return 0
   }

   function renderCollapseContent(productUuid: string) {
      const activeWholesale = wholesales.find((item) => item.uuid === productUuid)

      if (!activeWholesale) {
         return (
            <Table>
               <TableBody>
                  <TableRow>
                     <StyledTableCell colSpan={3} style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress color="primary" />
                     </StyledTableCell>
                  </TableRow>
               </TableBody>
            </Table>
         )
      }
      if (activeWholesale.wholesales.length === 0) {
         return (
            <Table>
               <TableBody>
                  <TableRow>
                     <StyledTableCell colSpan={3} style={{ display: 'flex', justifyContent: 'center' }}>
                        {t('ERP_PRODUCT_CARD:noWholesales')}
                     </StyledTableCell>
                  </TableRow>
               </TableBody>
            </Table>
         )
      }
      return (
         <Table>
            <TableHead sx={{ backgroundColor: 'rgba(33,147,224,0.07)' }}>
               <StyledTableHeadCell> {t('TRANSLATION:columnNames.wholesaler')}</StyledTableHeadCell>
               <StyledTableHeadCell>{t('ERP_PRODUCT_CARD:table.availability')}</StyledTableHeadCell>
               <StyledTableHeadCell>{t('ERP_PRODUCT_CARD:table.wholesalePrices')}*</StyledTableHeadCell>
            </TableHead>
            <TableBody>
               {activeWholesale.wholesales.map((wholesale) => (
                  <TableRow>
                     <StyledTableCell>{wholesale.wholesaleName}</StyledTableCell>
                     <StyledTableCell>{GetAvailabilityDot(wholesale.status)}</StyledTableCell>
                     <StyledTableCell>{getPriceOrNoData(wholesale.price)}</StyledTableCell>
                  </TableRow>
               ))}
            </TableBody>
         </Table>
      )
   }

   useEffect(() => {
      if (searchParams) {
         const searchParam = searchParams.get('value')
         if (searchParam) {
            fetchErpData(searchParam).then((res) => {
               setData(res)
            })
         }
      }
   }, [searchParams, httpClient])

   return (
      <Box sx={{ margin: 5 }}>
         {data ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8, padding: 3 }}>
               <ProductInfo data={data} searchPhrase={searchParams.get('value') || ''} />
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: { md: 'row', xs: 'column' },
                     gap: { xs: 8 },
                     justifyContent: 'space-between',
                     width: '80%',
                  }}
               >
                  <TableContainer>
                     <Typography variant="body1" color="grayText">
                        {t('ERP_PRODUCT_CARD:originalPrices')}*
                     </Typography>
                     <StyledTable sx={{ backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <TableHead sx={{ backgroundColor: 'rgba(33,147,224,0.1)' }}>
                           <TableRow>
                              <StyledTableHeadCell />
                              <StyledTableHeadCell>Min</StyledTableHeadCell>
                              <StyledTableHeadCell>{t('ERP_PRODUCT_CARD:table.mean')}</StyledTableHeadCell>
                              <StyledTableHeadCell>Max</StyledTableHeadCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           <TableRow>
                              <StyledBoldTableCell>{`${t(
                                 'ERP_PRODUCT_CARD:table.wholesalePrices'
                              )} (OE)`}</StyledBoldTableCell>
                              <StyledTableCell>{getPriceOrNoData(data.minOEPrice)}</StyledTableCell>
                              <StyledTableCell>{getPriceOrNoData(data.meanOEPrice)}</StyledTableCell>
                              <StyledTableCell>{getPriceOrNoData(data.maxOEPrice)}</StyledTableCell>
                           </TableRow>
                           <TableRow>
                              <StyledBoldTableCell>{t('ERP_PRODUCT_CARD:table.retailPrices')}</StyledBoldTableCell>
                              <StyledTableCell>{getPriceOrNoData(data.minRetailPrice)}</StyledTableCell>
                              <StyledTableCell>{getPriceOrNoData(data.meanRetailPrice)}</StyledTableCell>
                              <StyledTableCell>{getPriceOrNoData(data.maxRetailPrice)}</StyledTableCell>
                           </TableRow>
                        </TableBody>
                     </StyledTable>
                     {data.minRetailUrl && (
                        <Typography variant="body1" color="grayText">
                           {`${t('ERP_PRODUCT_CARD:table.lowestPriceLink')}: `}
                           <StyledLink
                              target="_blank"
                              rel="noopener noreferrer"
                              href={data.minRetailUrl}
                           >{`${data.minRetailUrl.slice(0, 30)}...`}</StyledLink>
                        </Typography>
                     )}
                  </TableContainer>
                  <TableContainer>
                     <Typography variant="body1" color="grayText">
                        {t('ERP_PRODUCT_CARD:table.substitutesPrices')}*
                     </Typography>
                     <StyledTable>
                        <TableHead sx={{ backgroundColor: 'rgba(33,147,224,0.1)' }}>
                           <TableRow>
                              <StyledTableHeadCell />
                              <StyledTableHeadCell>Min</StyledTableHeadCell>
                              <StyledTableHeadCell>{t('ERP_PRODUCT_CARD:table.mean')}</StyledTableHeadCell>
                              <StyledTableHeadCell>Max</StyledTableHeadCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           <TableRow>
                              <StyledBoldTableCell>{`${t(
                                 'ERP_PRODUCT_CARD:table.wholesalePrices'
                              )} (OE)`}</StyledBoldTableCell>
                              <StyledTableCell>
                                 {getPriceOrNoData(data.replacements.wholesalePrices.minPrice)}
                              </StyledTableCell>
                              <StyledTableCell>
                                 {getPriceOrNoData(data.replacements.wholesalePrices.meanPrice)}
                              </StyledTableCell>
                              <StyledTableCell>
                                 {getPriceOrNoData(data.replacements.wholesalePrices.maxPrice)}
                              </StyledTableCell>
                           </TableRow>
                           <TableRow>
                              <StyledBoldTableCell>{t('ERP_PRODUCT_CARD:table.retailPrices')}</StyledBoldTableCell>
                              <StyledTableCell>
                                 {getPriceOrNoData(data.replacements.retailPrices.minPrice)}
                              </StyledTableCell>
                              <StyledTableCell>
                                 {getPriceOrNoData(data.replacements.retailPrices.meanPrice)}
                              </StyledTableCell>
                              <StyledTableCell>
                                 {getPriceOrNoData(data.replacements.retailPrices.maxPrice)}
                              </StyledTableCell>
                           </TableRow>
                        </TableBody>
                     </StyledTable>
                  </TableContainer>
               </Box>
               {data.replacements.replacements.sort((a, b) =>
                  sortTable(a, b, sortDetails.sortBy, sortDetails.sortingMethod)
               ).length > 0 && (
                  <Box>
                     <TableContainer>
                        <Typography variant="body1" color="grayText">
                           {t('ERP_PRODUCT_CARD:table.substitutes')}
                        </Typography>
                        <StyledTable sx={{ maxWidth: 700, backgroundColor: 'rgba(255,255,255,0.7)' }}>
                           <TableHead sx={{ backgroundColor: 'rgba(33,147,224,0.1)' }}>
                              <TableRow>
                                 <StyledTableHeadCell>{t('TRANSLATION:columnNames.partIndex')}</StyledTableHeadCell>
                                 <StyledTableHeadCell>{t('ERP_PRODUCT_CARD:table.availability')}</StyledTableHeadCell>
                                 <StyledTableHeadCell>{t('TRANSLATION:columnNames:producer')}</StyledTableHeadCell>
                                 <StyledTableHeadCell>
                                    <TableSortLabel
                                       active={sortDetails.sortBy === 'wholesalePrice'}
                                       direction={sortDetails.sortingMethod}
                                       onClick={() => handleSortingChange('wholesalePrice')}
                                    >
                                       {t('ERP_PRODUCT_CARD:table.wholesalePrice')}*
                                    </TableSortLabel>
                                 </StyledTableHeadCell>
                                 <StyledTableHeadCell>
                                    <TableSortLabel
                                       active={sortDetails.sortBy === 'retailPrice'}
                                       direction={sortDetails.sortingMethod}
                                       onClick={() => handleSortingChange('retailPrice')}
                                    >
                                       {t('ERP_PRODUCT_CARD:table.retailPrices')}
                                    </TableSortLabel>
                                 </StyledTableHeadCell>
                                 <StyledTableHeadCell />
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {data.replacements.replacements.map((replacement) => (
                                 <React.Fragment key={replacement.uuid}>
                                    <ClickableTableRow onClick={() => handleRowOpen(replacement.uuid)}>
                                       <StyledTableCell>{replacement.index}</StyledTableCell>
                                       <StyledTableCell>{GetAvailabilityDot(replacement.status)}</StyledTableCell>
                                       <StyledTableCell>{replacement.producer}</StyledTableCell>
                                       <StyledTableCell>{getPriceOrNoData(replacement.wholesalePrice)}</StyledTableCell>
                                       <StyledTableCell>{getPriceOrNoData(replacement.retailPrice)}</StyledTableCell>
                                       <StyledTableCell onClick={(e) => e.stopPropagation()}>
                                          {replacement.minRetailUrl && (
                                             <StyledLink
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={replacement.minRetailUrl}
                                             >
                                                LINK
                                             </StyledLink>
                                          )}
                                       </StyledTableCell>
                                    </ClickableTableRow>
                                    <StyledTableCell style={{ padding: 0 }} colSpan={6}>
                                       <Collapse
                                          timeout="auto"
                                          in={opened.includes(replacement.uuid)}
                                          style={{ overflowY: 'scroll', maxHeight: '250px' }}
                                       >
                                          <div>{renderCollapseContent(replacement.uuid)}</div>
                                       </Collapse>
                                    </StyledTableCell>
                                 </React.Fragment>
                              ))}
                           </TableBody>
                        </StyledTable>
                     </TableContainer>
                     <Typography sx={{ mt: 3, fontSize: 15, fontWeight: 'bold' }}>
                        {t('ERP_PRODUCT_CARD:pricesAreGross')}
                     </Typography>
                  </Box>
               )}
            </Box>
         ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
               <CircularProgress color="primary" />
            </Box>
         )}
      </Box>
   )
}
