import { Yup } from 'shared/lib'

const validName =
   /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßẞÁČĎÉĚèàèChÍŇÓŘŠŤÚŮÝŽáčďéěèàèchíňóřštúůýž'-]\s?)*([^\s\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßẞÁČĎÉĚèàèChÍŇÓŘŠŤÚŮÝŽáčďéěèàèchíňóřštúůýž])$/

const validTaxID = /^(?:\d{10}|[1-9][0-9]{6,8}|[1-9]\d{8}|[1-9]\d{7,9}|ATU\d{8})$/

const validCompanyName =
   /^([a-zA-Z0-9żźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßẞÁČĎÉĚChÍŇÓŘŠŤÚŮÝŽáčďéěchíňóřštúůýž'",-][.]?[\s]*)*([a-zA-Z0-9żźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßẞÁČĎÉĚChÍŇÓŘŠŤÚŮÝŽáčďéěchíňóřštúůýž]\.?)\s*['"\s]*$/
const validPostCode = /^(?:[A-Za-z]{2})?(?:\d{4}|\d{5}|\d{6}|\d{2}-\d{3}|\d{3}\s?\d{2})$/

const validCity =
   /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßẞÁČĎÉĚèàèChÍŇÓŘŠŤÚŮÝŽáčďéěèàèchíňóřštúůýž'-]\s?)*([^\s\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßẞÁČĎÉĚèàèChÍŇÓŘŠŤÚŮÝŽáčďéěèàèchíňóřštúůýž])$/
const validSmsCode = /\b\d{6}\b/

const validEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

export const firstStepSchema = Yup.object().shape({
   firstName: Yup.string().min(3).max(32).matches(validName, 'validation.validName').required(),
   lastName: Yup.string().min(3).max(32).matches(validName, 'validation.validSurname').required(),
   email: Yup.string().min(3).max(64).email().matches(validEmail, 'validation.validEmail').required(),
   telephoneNumber: Yup.string().required(),
})

export const secondStepSchema = Yup.object().shape(
   {
      taxID: Yup.string().required().matches(validTaxID, 'validation.taxIdPattern'),
      companyName: Yup.string().max(128).matches(validCompanyName, 'validation.companyNamePattern').required(),
      street: Yup.string().max(100).required(),
      houseNumber: Yup.string().max(15).required(),
      postCode: Yup.string().matches(validPostCode, 'validation.postCodePattern').required(),
      city: Yup.string().matches(validCity, 'validation.cityPattern').required(),
      country: Yup.string().required(),
      county: Yup.string().required(),
      voivodeship: Yup.string().required(),
      workshopEmployees: Yup.number().when('shopEmployees', {
         is: (value: number) => value === 0,
         then: (schema) => schema.min(1, 'validation.greaterThan0').required(),
      }),
      shopEmployees: Yup.number().when('workshopEmployees', {
         is: (value: number) => value === 0,
         then: (schema) => schema.min(1, 'validation.greaterThan0').required(),
      }),
   },

   [
      ['workshopEmployees', 'shopEmployees'], // <--- adding your fields which need validation
   ]
)

export const thirdStepSchema = Yup.object().shape({
   smsCode: Yup.string().required().matches(validSmsCode, { message: 'validation.smsCode', excludeEmptyString: true }),
   acceptRegulations: Yup.boolean().required().isTrue(),
})
