import React, { useState } from 'react'
import Collapse from '@mui/material/Collapse'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'

import { Product } from 'shared/store/useCartSummary/types'
import RadiusTableWrapper from 'shared/components/RadiusTableWrapper'
import HiddenWholesalerLogo from 'shared/components/HiddenWholesalerLogo'

import {
   StyledDeleteIcon,
   StyledTableCell,
   StyledTableRow,
   StyledTitleRow,
   StyledTitleTableCell,
   StyledCollapseTableCell,
} from '../../../styles'
import { MobileTableHeaders } from '../../../data/TableHeaders'
import getProductName from '../../../helpers/getProductName'
import { ModalInfoType } from '../../../types'

interface Props {
   products: Product[]
   setModalInfo: (modalInfo: ModalInfoType) => void
}

export default function UnavailableProductTableMobile({ products, setModalInfo }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const [collapse, setCollapse] = useState<string[]>([])

   function handleCollapseProduct(uuid: string) {
      if (collapse.includes(uuid)) {
         setCollapse(collapse.filter((item) => item !== uuid))
      } else {
         const tempCollapseProducts = [...collapse]
         tempCollapseProducts.push(uuid)
         setCollapse([...tempCollapseProducts])
      }
   }

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
         <Box sx={{ mt: 6 }}>
            <Typography variant="body1" sx={{ color: 'red' }}>
               {t('MAIN_CART:unavailableProducts')}
            </Typography>
         </Box>
         <RadiusTableWrapper>
            <Table
               sx={{
                  backgroundColor: 'rgba(255,255,255,0.7)',
                  margin: '0 auto',
                  mt: 1,
               }}
            >
               <TableHead sx={{ padding: 3, backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                  <StyledTitleRow>
                     {MobileTableHeaders.map((item) => (
                        <StyledTitleTableCell key={item}>{t(`TRANSLATION:columnNames.${item}`)}</StyledTitleTableCell>
                     ))}
                     <StyledTitleTableCell />
                  </StyledTitleRow>
               </TableHead>
               <TableBody>
                  {products.map((product) => (
                     <React.Fragment key={product.uuid}>
                        <StyledTableRow onClick={() => handleCollapseProduct(product.uuid)}>
                           <StyledTableCell>{getProductName(product)}</StyledTableCell>
                           <StyledTableCell>
                              <img
                                 height="20px"
                                 width="auto"
                                 src={`https://files.motorro.eu/img?name=${product.producerNameWithoutSpecialChars.toLowerCase()}`}
                                 alt={product.producerName}
                              />
                           </StyledTableCell>
                           <StyledTableCell>
                              <StyledDeleteIcon
                                 onClick={(e) => {
                                    e.stopPropagation()
                                    setModalInfo({
                                       modalType: 'DELETE',
                                       uuid: product.uuid,
                                       removeFromUnavailable: true,
                                       index: product.index,
                                    })
                                 }}
                              />
                           </StyledTableCell>
                           <StyledTableCell />
                        </StyledTableRow>
                        <TableRow>
                           <TableCell style={{ padding: '0' }} colSpan={6}>
                              <Collapse
                                 style={{ overflowY: 'scroll', maxHeight: '300px' }}
                                 in={collapse.includes(product.uuid)}
                                 timeout="auto"
                              >
                                 <Table>
                                    <TableBody>
                                       <TableRow>
                                          <StyledCollapseTableCell colSpan={3}>
                                             {t('TRANSLATION:columnNames.wholesaler')}
                                          </StyledCollapseTableCell>
                                          <StyledCollapseTableCell colSpan={3}>
                                             <HiddenWholesalerLogo
                                                wholesalerName={product.cartWholesaleName}
                                                imgHeight="20px"
                                                imgWidth="auto"
                                             />
                                          </StyledCollapseTableCell>
                                       </TableRow>
                                       <TableRow>
                                          <StyledCollapseTableCell colSpan={3}>
                                             {t('TRANSLATION:columnNames.quantity')}
                                          </StyledCollapseTableCell>
                                          <StyledCollapseTableCell colSpan={3}>
                                             {product.cartWholesaleDTO.quantity}
                                          </StyledCollapseTableCell>
                                       </TableRow>
                                       <TableRow>
                                          <StyledCollapseTableCell colSpan={3}>
                                             {t('TRANSLATION:columnNames.netPrice')}
                                          </StyledCollapseTableCell>
                                          <StyledCollapseTableCell colSpan={3}>
                                             {t('TRANSLATION:price', { price: product.cartWholesaleDTO })}
                                          </StyledCollapseTableCell>
                                       </TableRow>
                                       <TableRow>
                                          <StyledCollapseTableCell colSpan={3}>VAT</StyledCollapseTableCell>
                                          <StyledCollapseTableCell colSpan={3}>23%</StyledCollapseTableCell>
                                       </TableRow>
                                       <TableRow>
                                          <StyledCollapseTableCell colSpan={3}>
                                             {t('TRANSLATION:columnNames.grossValue')}
                                          </StyledCollapseTableCell>
                                          <StyledCollapseTableCell colSpan={3}>
                                             {t('TRANSLATION:price', {
                                                price: product.cartWholesaleDTO.grossPrice,
                                             })}
                                          </StyledCollapseTableCell>
                                       </TableRow>
                                    </TableBody>
                                 </Table>
                              </Collapse>
                           </TableCell>
                        </TableRow>
                     </React.Fragment>
                  ))}
               </TableBody>
            </Table>
         </RadiusTableWrapper>
      </Box>
   )
}
