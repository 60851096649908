import { httpClient } from 'shared/lib'
import { CsvPreferencesType, Result } from '../../../types'

export default function createConfigService(locationUuid: string, payload: CsvPreferencesType) {
   return httpClient
      .post<Result>(`locations/${locationUuid}/location-preferences`, payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((err) => {
         throw new Error(err.message)
      })
}
