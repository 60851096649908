import axios, { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import { httpClient } from 'shared/lib'

import { FetchMessagesResult, ChangeMessageStatusPayload } from './types'

export function fetchMessages(): Promise<FetchMessagesResult> {
   let errMessage = ''

   return httpClient
      .get('messages/')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something goes wrong in fetchMessages()'
         } else {
            errMessage = err.message
         }
         consoleMessage('Messages fetchMessages()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function changeMessageStatus(payload: ChangeMessageStatusPayload) {
   let errMessage = ''

   return httpClient
      .put(`messages/${payload.messageUuid}/isRead/`)
      .then((res) => ({ status: res.status }))
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong in messages changeMessageStatus()'
         } else {
            errMessage = err.message
         }
         consoleMessage('MESSAGES changeMessageStatus()', errMessage, 'error')
         throw new Error(errMessage)
      })
}
