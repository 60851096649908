import { httpClient } from 'shared/lib'

export default function swapUserLocationService(locationUuid: string) {
   return httpClient
      .post<{ status: number }>(`locations/swap/${locationUuid}`)
      .then((res) => ({ status: res.status, data: res.data }))
      .catch(() => {
         throw new Error('apiErrors.500')
      })
}
