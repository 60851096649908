import { unauthHttpClient } from 'shared/lib'

import { CountriesType } from './types'

export function fetchCountries(): Promise<CountriesType> {
   return unauthHttpClient
      .get('countries')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch(() => {
         throw new Error('apiErrors.500')
      })
}
