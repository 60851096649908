import { styled } from '@mui/material'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

export const StyledLocationTab = styled(Tab)(
   ({ locationUuid, activeLocationUuid }: { locationUuid: string; activeLocationUuid: string }) => ({
      minWidth: '100%',
      backgroundColor: locationUuid === activeLocationUuid ? 'rgba(228,238,247,255)' : 'transparent',
      lineBreak: 'loose',
      fontSize: 13,
      color: locationUuid === activeLocationUuid ? 'main' : 'grayText',
      '&:hover': {
         color: 'primary',
         backgroundColor: '#E4EEF7',
      },
   })
)

export const LocationTabContainer = styled(Box)({
   display: 'flex',
   justifyContent: 'space-between',
   flexDirection: 'column',
   paddingBottom: 15,
   paddingLeft: 30,
   paddingRight: 30,
   minHeight: 400,
})
