import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import isAxiosError from 'shared/utils/helpers/isAxiosError'
import {
   AskCooperationPayload,
   ChangeWholesalerDataPayload,
   DeleteWholesalerPayload,
   EditDeliveryTimesPayload,
   FetchDeliveryTimesResult,
   WholesalerDeliveryTimes,
   WholesalerType,
} from './types'

export function fetchWholesalers(locationId?: string) {
   let errMessage = ''

   return httpClient
      .get<WholesalerType[]>(`cooperation/${locationId}`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || ''
         } else {
            errMessage = err.message
         }
         consoleMessage('WHOLESALERS fetchWholesalers()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function fetchWholesalerDeliveryTimes(
   wholesalerUuid: string,
   locationUuid: string
): Promise<WholesalerDeliveryTimes> {
   let errMessage = ''

   return httpClient
      .get<FetchDeliveryTimesResult>(`delivery/time/${wholesalerUuid}/${locationUuid}`)
      .then((res) => {
         if (res.data) {
            return {
               deliveryTimes: res.data.deliveryTimes,
               uuid: res.data.uuid,
            }
         }
         throw new Error('No data in request')
      })
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || ''
         } else {
            errMessage = err.message
         }
         consoleMessage('WHOLESALERS fetchWholesalerDeliveryTimes()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function editWholesalerDeliveryTimes(payload: EditDeliveryTimesPayload) {
   let errMessage = ''

   return httpClient
      .put<Result>(`delivery/time/${payload.uuid}`, {
         deliveryTimes: payload.deliveryTimes,
         wholesaleUuid: payload.wholesaleUuid,
      })
      .then((res) => {
         if (res.data) {
            return {
               status: 200,
            }
         }
         throw new Error('No data in request')
      })
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || ''
         } else {
            errMessage = err.message
         }
         consoleMessage('WHOLESALERS editWholesalerDeliveryTimes()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function deleteWholesaler(payload: DeleteWholesalerPayload) {
   let errMessage = ''

   return httpClient
      .delete(`cooperation/${payload.wholesalerUuid}/${payload.activeLocationUuid}`)
      .then((res) => ({
         status: res.status,
      }))
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || ''
         } else {
            errMessage = err.message
         }
         consoleMessage('WHOLESALERS deleteWholesaler()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function addWholesaler(payload: ChangeWholesalerDataPayload) {
   let errMessage = ''

   return httpClient
      .post('cooperation/collaborate/', payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((err: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(err)) {
            errMessage = err.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = err.message
         }
         consoleMessage('WHOLESALERS addWholesaler()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function editWholesaler(payload: ChangeWholesalerDataPayload) {
   let errMessage = ''

   return httpClient
      .post('cooperation/collaborate/', payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((err: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(err)) {
            errMessage = err.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = err.message
         }
         consoleMessage('wholesalers editWholesalers', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function askForCooperation(payload: AskCooperationPayload) {
   return httpClient
      .post('cooperation/establish/', payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((err: Error | AxiosError<ErrorType>) => {
         let errorMessage = ''
         if (isAxiosError<ErrorType>(err)) {
            errorMessage = err.response?.data?.errors[0].defaultMessage || ''
         } else {
            errorMessage = err.message
         }
         consoleMessage('WHOLESALERS askForCooperation()', errorMessage, 'error')
         throw new Error(errorMessage)
      })
}
