import React from 'react'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { useTranslation } from 'react-i18next'
import Grow from '@mui/material/Grow'
import Box from '@mui/material/Box'

import BannersSlider from 'shared/components/BannersSlider'
import { useStatisticsQuery } from 'shared/store/useStatistics'

import PointsAndSavedTime from './components/PointsAndSavedTime'
import ShoppingChart from './components/ShoppingChart'
import ActiveCart from './components/ActiveCart'
import Features from './components/Features'
import { en, pl, de } from './locales'
import { Grid } from './styles'

export default function Homepage(): JSX.Element {
   const { i18n } = useTranslation()
   const { data, isLoading, isError } = useStatisticsQuery()

   i18n.addResourceBundle('pl-PL', 'HOMEPAGE', pl)
   i18n.addResourceBundle('en-EN', 'HOMEPAGE', en)
   i18n.addResourceBundle('de-DE', 'HOMEPAGE', de)

   return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
         {data && !isLoading ? (
            <Grid>
               <Box gridColumn={{ lg: '1 / span 4', md: '1 / span 8', xs: '1 / span 8' }} gridRow="1">
                  <ActiveCart />
               </Box>
               <Grow in timeout={1000}>
                  <Box gridColumn={{ lg: '5 / span 4', md: '1 / span 8', xs: '1 / span 8' }} maxHeight={350}>
                     <BannersSlider variant="HOMEPAGE" />
                  </Box>
               </Grow>
               <Box
                  maxHeight={300}
                  gridColumn={{ md: '1 / span 8', xs: '1 / span 8', lg: '1/ span 8' }}
                  gridRow={{ lg: '2', md: '3', xs: '3' }}
               >
                  <ShoppingChart
                     formattedCurrency={data.spentMoney.formattedCurrency}
                     chartData={data.spentMoney}
                     statsFailed={isError}
                  />
               </Box>
               <Box
                  gridColumn={{ md: '1 / span 8', xs: '1 / span 8', lg: '1 / span 4' }}
                  gridRow={{ lg: '3', md: '4', xs: '4' }}
               >
                  <PointsAndSavedTime savedTime={data.savedTime} statsFailed={isError} />
               </Box>
               <Box
                  gridColumn={{ md: '1 / span 8', xs: '1 / span 8', lg: '5 / span 4' }}
                  gridRow={{ lg: '3', md: '5', xs: '5' }}
               >
                  <Features />
               </Box>
            </Grid>
         ) : (
            <CircularProgress />
         )}
      </Box>
   )
}
