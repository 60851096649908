import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import { FetchUserProfileResult, UpdateProfilePayload, UpdateUserProfilePayload } from './types'

export function fetchProfileData() {
   return httpClient
      .get<FetchUserProfileResult>('users')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err) => {
         consoleMessage('PROFILE', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}

export function saveProfileData(payload: UpdateProfilePayload | UpdateUserProfilePayload) {
   return httpClient
      .put<Result>(`companies/users/${payload.uuid}`, payload)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         consoleMessage('PROFILE', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}
