import React from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import DialogContent from '@mui/material/DialogContent'
import { useTheme } from '@mui/material'

import { FormType, NotificationInfo } from '../../types'
import DeleteNotification from '../DeleteNotification'
import OrderNotification from '../OrderNotification'
import PaymentNotification from '../PaymentNotification'
import CsvSummary from '../CSVSummary'
import OversizeOrderNotification from '../OversizeOrderNotification'
import ClientCartCreatedNotification from '../ClientCartCreatedNotification'
import ClientOrderCreatedNotification from '../ClientOrderCreatedNotification'

interface Props {
   open: boolean
   type?: FormType
   handleClose: () => void
   modalInfo?: NotificationInfo
}

export default function Modal({ open, type, handleClose, modalInfo }: Props) {
   const { t } = useTranslation()
   const theme = useTheme()

   function renderContent() {
      switch (type) {
         case 'CLIENT_FINISHED_ORDER':
            return <OrderNotification handleClose={handleClose} modalInfo={modalInfo} />
         case 'WAITING_FOR_PAYMENT':
            return <PaymentNotification handleClose={handleClose} modalInfo={modalInfo} />
         case 'DELETE':
            return <DeleteNotification handleClose={handleClose} notificationUuid={modalInfo?.notificationUuid} />
         case 'AUTO_CART_CREATED':
         case 'AUTO_CART_CREATION_FAILED':
            return (
               <CsvSummary
                  cartUuid={modalInfo?.cartUuid || ''}
                  handleClose={handleClose}
                  type={type || 'AUTO_CART_CREATED'}
                  message={modalInfo?.message || ''}
               />
            )
         case 'ORDER_CREATED':
         case 'ORDER_CREATION_FAILED':
            return (
               <OversizeOrderNotification
                  handleClose={handleClose}
                  orderType={type || 'ORDER_CREATED'}
                  cartUuid={modalInfo?.cartUuid}
               />
            )
         case 'CLIENT_REFLINK_CART_CREATED':
            return (
               <ClientCartCreatedNotification
                  handleClose={handleClose}
                  cartUuid={modalInfo?.cartUuid || ''}
                  cartName={modalInfo?.cartName || ''}
               />
            )
         case 'CLIENT_REFLINK_ORDER_CREATED':
            return <ClientOrderCreatedNotification orderUuid={modalInfo?.orderUuid || ''} handleClose={handleClose} />
         default:
            return null
      }
   }
   return (
      <Dialog open={open} onClose={handleClose}>
         <DialogTitle>
            <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>
               {t(`NOTIFICATIONS:${type}`)}
            </Typography>
         </DialogTitle>
         <DialogContent>{renderContent()}</DialogContent>
      </Dialog>
   )
}
