import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'

import consoleMessage from '../utils/helpers/consoleMessage'

export async function fetchCooperatingWholesalersData(productUuid: string): Promise<FetchCooperationDataResult> {
   return httpClient
      .get<FetchCooperatingWholesalersDataType>(`/products/${productUuid}/offline-availability-in-cooperating`)
      .then((response) => {
         if (response.data) {
            return {
               availableWholesalers: response.data.filter((item) => item.status === 'OK_OFFLINE_AVAILABILITY'),
               unAvailableWholesalers: response.data.filter((item) => item.status === 'PRODUCT_NOT_EXIST_IN_WHOLESALE'),
            }
         }
         throw new Error('noData')
      })
      .catch((err: Error | AxiosError<BackendError>) => {
         let errMessage
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with fetchCooperationData()'
         } else {
            errMessage = err.message
         }
         consoleMessage('fetchCooperationData()', errMessage, 'error')
         throw new Error(errMessage)
      })
}
