import React, { useEffect, useState } from 'react'
import { useFormik, Form, FormikProvider } from 'formik'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import validatePhoneNumber from 'shared/utils/helpers/validatePhoneNumber'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useLocationsQuery } from 'shared/store/useLocations'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import InputField from 'shared/components/InputField'
import MuiPhone from 'shared/components/PhoneInput'
import { useProfileQuery } from 'shared/store/useProfile'

import { EditUserInitialValues } from '../../types'
import { editUserService } from '../../services'
import { editUserSchema } from '../../schema'
import { handleLocationAllowedChange } from '../../helpers/handleAllowedLocations'

interface Props {
   modalInfo: EditUserInitialValues
   locationUuids: string[]
   handleClose: () => void
   handleRefetch: () => void
}

export default function EditUserModal({ handleRefetch, locationUuids, modalInfo, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const queryClient = useQueryClient()
   const { data } = useLocationsQuery()
   const { data: userData } = useProfileQuery()
   const [phoneNumberError, setPhoneNumberError] = useState(false)
   const [allowedLocations, setAllowedLocations] = useState<string[]>([])
   const [locationsError, setLocationError] = useState<boolean>(false)
   const [isLoading, setIsLoading] = useState(false)
   const { t } = useTranslation(['TRANSLATION', 'USERS_AND_SUBSCRIPTIONS'])

   const formik = useFormik<EditUserInitialValues>({
      initialValues: {
         name: '',
         phoneNumber: '',
         surname: '',
         email: '',
         uuid: '',
      },
      validationSchema: editUserSchema,
      onSubmit: editUser,
   })

   const { values, setValues } = formik

   function handlePhoneNumber(value: string, country: string) {
      formik.setFieldValue('phoneNumber', value)
      const result = validatePhoneNumber(value, country)
      if (result === false) {
         setPhoneNumberError(true)
      } else {
         setPhoneNumberError(false)
      }
   }

   function editUser() {
      setIsLoading(true)
      if (!phoneNumberError) {
         editUserService(
            {
               ...values,
               locationUuids: allowedLocations,
            },
            values.uuid
         )
            .then((res) => {
               if (res.status === 200) {
                  addNotification('successSave', 'success')
                  queryClient.invalidateQueries('locations')
                  handleRefetch()
                  handleClose()
               }
            })
            .catch((err) => {
               if (isAxiosError(err)) {
                  addNotification('failedSave', 'error')
               } else {
                  addNotification(err.message, 'error')
               }
            })
            .finally(() => setIsLoading(false))
      }
   }

   useEffect(() => {
      if (modalInfo) {
         setValues({
            ...modalInfo,
         })
         setAllowedLocations([...locationUuids])
      }
   }, [modalInfo])

   useEffect(() => {
      if (allowedLocations.length === 0) {
         setLocationError(true)
      } else {
         setLocationError(false)
      }
   }, [allowedLocations])

   return (
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
         <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            {t('USERS_AND_SUBSCRIPTIONS:createUserLabel')}
         </Typography>
         <FormikProvider value={formik}>
            <Form>
               <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <Box sx={{ display: 'flex', gap: 5, justifyContent: 'center' }}>
                     <InputField<EditUserInitialValues>
                        variant="standard"
                        label={t('TRANSLATION:formFields.name')}
                        name="name"
                        required
                     />
                     <InputField<EditUserInitialValues>
                        variant="standard"
                        label={t('TRANSLATION:formFields.surname')}
                        name="surname"
                        required
                     />
                  </Box>
                  <Box sx={{ display: 'flex', gap: 5, justifyContent: 'center' }}>
                     <InputField<EditUserInitialValues>
                        variant="standard"
                        label={t('TRANSLATION:formFields.email')}
                        name="email"
                        disabled={userData?.uuid === values.uuid}
                        required
                     />
                     <MuiPhone
                        variant="standard"
                        value={values.phoneNumber || modalInfo.phoneNumber}
                        onChange={(value, country) => handlePhoneNumber(value, country)}
                     />
                  </Box>
                  <Box>
                     <Typography variant="body1" color="grayText">
                        {t('USERS_AND_SUBSCRIPTIONS:locationsAccess')}
                     </Typography>
                     <Box
                        sx={{
                           display: 'flex',
                           flexDirection: 'column',
                           maxWidth: 'fit-content',
                           margin: '0 auto',
                        }}
                     >
                        {data &&
                           data.locations.map((location) => (
                              <FormControlLabel
                                 control={
                                    <Checkbox
                                       checked={allowedLocations.includes(location.locationSettings.uuid)}
                                       disabled={
                                          userData?.uuid === values.uuid ||
                                          location.locationSettings.adminUuid === values.uuid
                                       }
                                       onChange={() =>
                                          handleLocationAllowedChange(
                                             location.locationSettings.uuid,
                                             allowedLocations,
                                             setAllowedLocations
                                          )
                                       }
                                       name={location.locationSettings.name}
                                    />
                                 }
                                 label={location.locationSettings.name}
                              />
                           ))}
                     </Box>
                     {locationsError && (
                        <Typography sx={{ textAlign: 'center' }} variant="body2" color="error">
                           {t('USERS_AND_SUBSCRIPTIONS:locationsAccessWarning')}
                        </Typography>
                     )}
                  </Box>
                  <Box sx={{ display: 'flex', gap: 3, justifyContent: 'center', mb: 3 }}>
                     <OutlinedButton variant="outlined" onClick={handleClose}>
                        {t('TRANSLATION:cancel')}
                     </OutlinedButton>
                     <ContainedButton
                        disabled={isLoading || locationsError || phoneNumberError || !formik.isValid}
                        variant="contained"
                        type="submit"
                     >
                        {t('TRANSLATION:save')}
                     </ContainedButton>
                  </Box>
               </Box>
            </Form>
         </FormikProvider>
      </DialogContent>
   )
}
