import React, { useState, useEffect } from 'react'
import { useFormik, Form, FormikProvider } from 'formik'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import Typography from '@mui/material/Typography'

import { Yup } from 'shared/lib'
import useLogin, { getInitialState } from 'shared/store/useLogin'
import { AuthorizationResult } from 'shared/store/useLogin/types'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { initGoogleTag } from 'shared/lib/GoogleTag'
import { StyledContactLink } from '../../styles'
import ActiveSessionsModal from './ActiveSessionsModal'

const VALIDATION_SCHEMA = Yup.object().shape({
   email: Yup.string().email().min(3).max(64).required(),
   password: Yup.string().required(),
})

type InitialValues = {
   email: string
   password: string
   smsCode?: string
}

export default function LoginForm() {
   const { t } = useTranslation(['TRANSLATION', 'HOMEPAGE'])
   const [fingerPrint, setFingerPrint] = useState('')
   const [showPassword, setShowPassword] = useState(false)
   const [hasStartedVerification, setHasStartedVerification] = useState(false)
   const [openModal, setOpenModal] = useState(false)
   const [afterSubmit, setAfterSubmit] = useState<AlertInfo>()
   const navigate = useNavigate()
   const { user, login, reLogin, changeLogin, error, isLoading } = useLogin()

   function handleClose() {
      setOpenModal(false)
      getInitialState()
   }

   const formik = useFormik<InitialValues>({
      initialValues: {
         email: '',
         password: '',
      },
      validationSchema: VALIDATION_SCHEMA,
      onSubmit: async (formValues, { setSubmitting, resetForm }) => {
         try {
            setSubmitting(true)
            setAfterSubmit(undefined)
            const hashedPassword = btoa(formValues.password)
            const payload = {
               email: formValues.email,
               password: hashedPassword,
               fingerPrint,
               smsCode: formValues.smsCode,
            }
            login(payload)
         } catch (err) {
            resetForm()
            setSubmitting(false)
         }
      },
   })
   const { errors, touched, handleSubmit, getFieldProps } = formik

   async function getFingerprint() {
      const fingerPrintFromLs = localStorage.getItem('FINGERPRINT')
      if (fingerPrintFromLs) {
         return fingerPrintFromLs
      }
      const fpPromise = await FingerprintJS.load()
         .then((fp) => fp.get())
         .then((res) => res.visitorId)
      return fpPromise
   }

   function handleShowPassword() {
      setShowPassword((show) => !show)
   }

   useEffect(() => {
      if (!fingerPrint) {
         getFingerprint().then((res) => {
            localStorage.setItem('FINGERPRINT', res)
            setFingerPrint(res)
         })
      }
   }, [])

   useEffect(() => {
      initGoogleTag('loginPage')
   }, [])

   useEffect(() => {
      if (error?.message) {
         if (error.message === 'VERIFICATION_CODE_SENT') {
            setHasStartedVerification(true)
         }
         const newAfterSubmit = translateLoginError(error.message)
         setAfterSubmit(newAfterSubmit)
      }
   }, [error?.message])

   useEffect(() => {
      if (user.loginStatus === 'logged') {
         navigate('/dashboard')
      } else if (user.loginStatus === 'pending') {
         setOpenModal(true)
      }
   }, [user])

   return (
      <FormikProvider value={formik}>
         <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
               {afterSubmit && <Alert severity={afterSubmit.status}>{t(afterSubmit.message)}</Alert>}

               {!hasStartedVerification ? (
                  <>
                     <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label={t('TRANSLATION:formFields.email')}
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email && t(errors.email)}
                     />

                     <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label={t('TRANSLATION:formFields.password')}
                        {...getFieldProps('password')}
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position="end">
                                 <IconButton onClick={handleShowPassword} edge="end">
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password && t(errors.password)}
                     />
                  </>
               ) : (
                  <TextField
                     fullWidth
                     type="text"
                     label={t('TRANSLATION:formFields.smsCode')}
                     {...getFieldProps('smsCode')}
                     error={Boolean(touched.smsCode && errors.smsCode)}
                     helperText={touched.smsCode && errors.smsCode && t(errors.smsCode)}
                  />
               )}
            </Stack>
            <Typography sx={{ my: 2 }} variant="body1">
               {t('UNAUTHORIZED:usingRegulations')}
               <StyledContactLink href="https://motorro.pl/regulamin/" target="_blank">
                  {t('UNAUTHORIZED:termsOfUse')}
               </StyledContactLink>
               {t('UNAUTHORIZED:and')}
               <StyledContactLink href="https://motorro.pl/polityka-prywatnosci/" target="_blank">
                  {t('UNAUTHORIZED:privacyPolicy')}
               </StyledContactLink>
            </Typography>
            <ContainedButton
               variant="contained"
               fullWidth
               size="large"
               type="submit"
               disabled={Boolean(errors.email || errors.password) || isLoading}
            >
               {t('HOMEPAGE:loginForm.login')}
            </ContainedButton>
         </Form>
         <ActiveSessionsModal
            open={openModal}
            onClose={handleClose}
            user={user}
            reLogin={reLogin}
            changeLogin={changeLogin}
         />
      </FormikProvider>
   )
}

function translateLoginError(err: PropType<AuthorizationResult, 'error'>): AlertInfo {
   switch (err) {
      case 'INVALID_CREDENTIALS':
      case 'Unauthorized':
         return {
            status: 'error',
            message: 'HOMEPAGE:loginForm.invalidCredentials',
         }
      case 'INVALID_VERIFICATION_CODE':
         return {
            status: 'error',
            message: 'HOMEPAGE:loginForm.wrongSmsCode',
         }
      case 'SMS_LIMIT_REACHED':
         return {
            status: 'error',
            message: 'HOMEPAGE:loginForm.smsCodeLimit',
         }
      case 'VERIFICATION_CODE_SENT':
         return {
            status: 'success',
            message: 'HOMEPAGE:loginForm.smsCodeSent',
         }
      case 'There is no valid subscriptions for that company':
         return {
            status: 'error',
            message: 'HOMEPAGE:loginForm.noFreeSubscriptions',
         }
      default:
         return {
            status: 'error',
            message: 'HOMEPAGE:loginForm.problemWithApi',
         }
   }
}
