import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import {
   AddLocationPayload,
   FetchLocationResult,
   LocationErrorType,
   RemoveLocationPayload,
   SaveLocationDetailsPayload,
} from './types'

export function fetchLocations(): Promise<FetchLocationResult> {
   return httpClient
      .get('locations')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err) => {
         consoleMessage('LOCATIONS fetchLocations()', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}

export function saveLocationDetails(payload: SaveLocationDetailsPayload, uuid: string) {
   const url = `locations/${uuid}`

   return httpClient
      .put<Result>(url, payload)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         if (isAxiosError<LocationErrorType>(err)) {
            throw new Error(`apiErrors.${err.response?.data.errors[0].code}`)
         }
         consoleMessage('LOCATIONS saveLocationDetails()', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}

export function addLocation(payload: AddLocationPayload) {
   return httpClient
      .post<Result>('locations/', payload)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         if (isAxiosError<LocationErrorType>(err)) {
            throw new Error(`apiErrors.${err.response?.data.errors[0].code}`)
         }
         consoleMessage('LOCATIONS addLocation()', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}

export function removeLocation(payload: RemoveLocationPayload) {
   return httpClient
      .delete<Result>(`locations/${payload.locationUuid}`)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         if (isAxiosError<LocationErrorType>(err)) {
            throw new Error(`apiErrors.${err.response?.data.errors[0].code}`)
         }
         consoleMessage('LOCATIONS removeLocation()', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}
