import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import { isEqual } from 'lodash'

import { useNotificationContext } from 'shared/context/Notifications'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'

import {
   addServiceToCart,
   createNewCart,
   fetchCartSummary,
   removeProduct,
   updateCartInfo,
   updatePrices,
   updateProductInfo,
   updateCartServiceInfo,
   deleteCartService,
} from './services'
import {
   FetchCartSummaryResult,
   RemoveProductPayload,
   UpdatePricesResult,
   UpdateProductType,
   UpdateCartInfoPayload,
   EditServicePayload,
} from './types'

export function useCartSummaryQuery(): UseQueryResult<FetchCartSummaryResult> {
   return useQuery('mainCart', fetchCartSummary, { refetchOnWindowFocus: 'always' })
}

export function useRemoveProductMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, unknown, RemoveProductPayload, unknown>((payload) => removeProduct(payload), {
      onSuccess: (data) => {
         if (data.status === 200) {
            queryClient.invalidateQueries('mainCart')
         }
      },
      onSettled: (data, error) => {
         if (data?.status === 200) {
            addNotification('successSave', 'success')
         } else if (error) {
            addNotification('failedSave', 'error')
         }
      },
   })
}

export function useUpdatePricesMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<UpdatePricesResult, unknown, string, unknown>((cartUuid) => updatePrices(cartUuid), {
      onSuccess: (data) => {
         if (data.status === 200) {
            invalidateCarts(queryClient)
         }
      },
      onSettled: (data, error) => {
         if (error && data?.status !== 200) {
            addNotification('failedSave', 'error')
         }
      },
   })
}

export function useNewCartMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, unknown, unknown, unknown>(createNewCart, {
      onSuccess: (data) => {
         if (data.status === 200) {
            queryClient.invalidateQueries('mainCart')
            queryClient.invalidateQueries('minimums')
            queryClient.invalidateQueries('savedCarts')
         }
      },
      onSettled: (data, error) => {
         if (data?.status === 200) {
            addNotification('successCartCreate', 'success')
         } else if (error) {
            addNotification('failedCartCreate', 'error')
         }
      },
   })
}

export function useUpdateProductMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, unknown, UpdateProductType, unknown>((payload) => updateProductInfo(payload), {
      onSuccess: (data) => {
         if (data.status === 200) {
            queryClient.invalidateQueries('mainCart')
         }
      },
      onSettled: (data, error) => {
         if (data?.status === 200) {
            addNotification('successSave', 'success')
         } else if (error) {
            addNotification('failedSave', 'error')
         }
      },
   })
}

export function useUpdateCartInfoMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, Error | AxiosError<BackendError>, UpdateCartInfoPayload, unknown>(
      (payload) => {
         const initialData = queryClient.getQueryData<FetchCartSummaryResult>('mainCart')
         const initialPayload = initialData
            ? {
                 cartName: initialData.cartDetailsDTO.cartName,
                 cartDescription: initialData.cartDetailsDTO.cartDescription,
              }
            : {}
         if (
            isEqual(initialPayload.cartName, payload.payload.cartName) &&
            isEqual(initialPayload.cartDescription, payload.payload.cartDescription)
         ) {
            throw new Error('apiErrors.nothingChanged')
         } else {
            return updateCartInfo(payload)
         }
      },
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('mainCart')
            }
         },
         onSettled: (data, error) => {
            if (data?.status === 200) {
               addNotification('successSave', 'success')
            } else if (error) {
               addNotification(error.message, 'error')
            }
         },
      }
   )
}

export function useAddServiceMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, unknown, { cartUuid: string; serviceUuid: string }, unknown>(
      ({ cartUuid, serviceUuid }) => addServiceToCart(cartUuid, serviceUuid),
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('mainCart')
            }
         },
         onSettled: (data, error) => {
            if (data?.status === 204) {
               addNotification('successSave', 'success')
            } else if (error) {
               addNotification('failedSave', 'error')
            }
         },
      }
   )
}

export function useUpdateServiceMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, unknown, { payload: EditServicePayload; cartUuid: string; serviceUuid: string }, unknown>(
      ({ payload, cartUuid, serviceUuid }) => updateCartServiceInfo(payload, cartUuid, serviceUuid),
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('mainCart')
            }
         },
         onSettled: (data, error) => {
            if (data?.status === 204) {
               addNotification('successSave', 'success')
            } else if (error) {
               addNotification('failedSave', 'error')
            }
         },
      }
   )
}

export function useDeleteServiceMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, unknown, { cartUuid: string; serviceUuid: string }, unknown>(
      ({ cartUuid, serviceUuid }) => deleteCartService(cartUuid, serviceUuid),
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('mainCart')
            }
         },
         onSettled: (data, error) => {
            if (data?.status === 204) {
               addNotification('successSave', 'success')
            } else if (error) {
               addNotification('failedSave', 'error')
            }
         },
      }
   )
}
