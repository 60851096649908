import axios, { AxiosRequestConfig, AxiosError, AxiosRequestHeaders } from 'axios'
import { jwtDecode } from 'jwt-decode'

import { JwtTokenData } from 'shared/store/useLogin/types'
import { LocalStorageKeys } from 'shared/consts'
import { loginOut } from 'shared/store/useLogin'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import i18n from 'locales/i18n'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export const authRequest = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
   headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
   },
})

export const httpClient = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
   headers: {
      Accept: 'application/json',
   },
})

export const unauthHttpClient = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
   headers: {
      Accept: 'application/json',
   },
})

export const pythonHttpClient = axios.create({
   baseURL: process.env.REACT_APP_PYTHON_URL,
   headers: {
      Accept: 'application/json',
   },
})

httpClient.interceptors.request.use((config: AxiosRequestConfig) => {
   const headers = {
      'Accept-Language': i18n.language,
   } as AxiosRequestHeaders
   const token = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)
   if (token !== null) {
      headers['X-Authorization'] = `Bearer ${token}`
   }
   return {
      ...config,
      headers: { ...config.headers, ...headers },
   }
})

async function getFingerprint() {
   const fingerPrintFromLs = localStorage.getItem('FINGERPRINT')
   if (fingerPrintFromLs) {
      return fingerPrintFromLs
   }
   const fpPromise = await FingerprintJS.load()
      .then((fp) => fp.get())
      .then((res) => res.visitorId)
   return fpPromise
}

pythonHttpClient.interceptors.request.use(async (config: AxiosRequestConfig) => {
   let fingerprint
   await getFingerprint().then((res) => {
      fingerprint = res
   })
   const headers = {
      'Accept-Language': i18n.language,
      'X-Fingerprint': fingerprint || '',
   } as AxiosRequestHeaders
   const token = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)
   if (token !== null) {
      headers['X-Authorization'] = `Bearer ${token}`
   }
   return {
      ...config,
      headers: { ...config.headers, ...headers },
   }
})

let isRefreshing = false

httpClient.interceptors.response.use(
   (response) => {
      return response
   },
   async (error: Error | AxiosError<ErrorType>) => {
      if (axios.isAxiosError(error)) {
         const originalRequest = error.config
         const status = error.response?.status
         if (status === 498) {
            const refreshToken = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN) || ''
            const params = new URLSearchParams()
            params.append('refresh_token', refreshToken)

            if (!isRefreshing) {
               isRefreshing = true

               try {
                  const res = await authRequest.post<{ accessToken: string }>('token/refresh', params)
                  const token = res.data.accessToken
                  const user = jwtDecode<JwtTokenData>(token)
                  const expirationDate = new Date(new Date().getTime() + user.expires * 1000)
                  localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, token)
                  localStorage.setItem(LocalStorageKeys.EXPIRES_IN, `${expirationDate}`)

                  if (originalRequest?.headers) {
                     originalRequest.headers.Authorization = `Bearer ${token}`
                  } else {
                     originalRequest.headers = { Authorization: `Bearer ${token}` }
                  }

                  return await httpClient(originalRequest)
               } catch (err: any) {
                  if (err.response?.status === 423) {
                     loginOut('/session-end').then(() => {
                        if (isAxiosError<ErrorType>(err)) {
                           switch (err.response?.data.errors[0].defaultMessage) {
                              case 'Refresh token expired':
                                 window.location.href = '/session-end?reason=expired'
                                 break
                              case 'Refresh token already invalidated':
                                 window.location.href = '/session-end?reason=loggedOut'
                                 break
                              case 'Subscription assigned to that session expired':
                                 window.location.href = '/session-end?reason=subscription'
                                 break
                              default:
                                 window.location.href = '/session-end?reason=expired'
                           }
                        }
                     })
                  }
                  return Promise.reject(err)
               } finally {
                  isRefreshing = false
               }
            } else {
               await new Promise<void>((resolve) => {
                  const interval = setInterval(() => {
                     if (!isRefreshing) {
                        clearInterval(interval)
                        resolve()
                     }
                  }, 100)
               })
               return httpClient(originalRequest)
            }
         }
      }

      return Promise.reject(error)
   }
)

pythonHttpClient.interceptors.response.use(
   (response) => {
      return response
   },
   async (error: Error | AxiosError<ErrorType>) => {
      if (axios.isAxiosError(error)) {
         const originalRequest = error.config
         const status = error.response?.status
         if (status === 417) {
            const refreshToken = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN) || ''
            const params = new URLSearchParams()
            params.append('refresh_token', refreshToken)

            if (!isRefreshing) {
               isRefreshing = true

               try {
                  const res = await authRequest.post<{ accessToken: string }>('token/refresh', params)
                  const token = res.data.accessToken
                  const user = jwtDecode<JwtTokenData>(token)
                  const expirationDate = new Date(new Date().getTime() + user.expires * 1000)

                  localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, token)
                  localStorage.setItem(LocalStorageKeys.EXPIRES_IN, `${expirationDate}`)

                  if (originalRequest?.headers) {
                     originalRequest.headers.Authorization = `Bearer ${token}`
                  } else {
                     originalRequest.headers = { Authorization: `Bearer ${token}` }
                  }

                  return await httpClient(originalRequest)
               } catch (err: any) {
                  if (err.response?.status === 423) {
                     loginOut('/session-end').then(() => {
                        if (isAxiosError<ErrorType>(err)) {
                           switch (err.response?.data.errors[0].defaultMessage) {
                              case 'Refresh token expired':
                                 window.location.href = '/session-end?reason=expired'
                                 break
                              case 'Refresh token already invalidated':
                                 window.location.href = '/session-end?reason=loggedOut'
                                 break
                              case 'Subscription assigned to that session expired':
                                 window.location.href = '/session-end?reason=subscription'
                                 break
                              default:
                                 window.location.href = '/session-end?reason=expired'
                           }
                        }
                     })
                  }
                  return Promise.reject(err)
               } finally {
                  isRefreshing = false
               }
            } else {
               await new Promise<void>((resolve) => {
                  const interval = setInterval(() => {
                     if (!isRefreshing) {
                        clearInterval(interval)
                        resolve()
                     }
                  }, 100)
               })
               return httpClient(originalRequest)
            }
         }
      }
      return Promise.reject(error)
   }
)

// eslint-disable-next-line consistent-return
