import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import { GetOrderResult } from './types'

export default function getOrderService(orderUuid: string) {
   let errMessage = ''

   return httpClient
      .get<GetOrderResult>(`orders/${orderUuid}`)
      .then((res) => ({
         status: res.status,
         data: res.data,
      }))
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || ''
         } else {
            errMessage = err.message
         }
         consoleMessage('BASELINKER_ORDER', errMessage, 'error')
         throw new Error(errMessage)
      })
}
