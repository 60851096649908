import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from '@mui/material/styles/styled'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import SearchIcon from '@mui/icons-material/Search'
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumn } from 'mui-datatables'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

import reverseDateRepresentation from 'shared/utils/helpers/reverseDateRepresentation'
import { useFilterSavedCarts, useSavedCartsQuery, useSetActiveCartMutation } from 'shared/store/useSavedCarts'
import { sortByDate } from 'shared/utils/helpers/sorters'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useProfileQuery } from 'shared/store/useProfile'
import TableHeadCell from 'shared/components/TableHeadCell'

import { ModalInfoType, StatusType } from './types'
import { StyledCloseIcon } from './styles'
import Modal from './components/Modal'
import { pl, en, de } from './locales'

export default function SavedCarts() {
   const { i18n, t } = useTranslation(['TRANSLATION', 'SAVED_CARTS'])
   i18n.addResourceBundle('pl-PL', 'SAVED_CARTS', pl)
   i18n.addResourceBundle('en-EN', 'SAVED_CARTS', en)
   i18n.addResourceBundle('de-DE', 'SAVED_CARTS', de)
   const { mutate } = useSetActiveCartMutation()
   const [selectedFilter, setSelectedFilter] = useState<string>('LAST_90_DAYS')
   const [searchPhrase, setSearchPhrase] = useState<string>()
   const { data, isLoading } = useSavedCartsQuery()
   const { mutate: savedCartsMutate, isLoading: isMutateLoading } = useFilterSavedCarts()

   const navigate = useNavigate()
   const { data: profileData } = useProfileQuery()
   const [modalInfo, setModalInfo] = useState<ModalInfoType>({
      open: false,
      cartName: undefined,
      userName: undefined,
      modalType: undefined,
      uuid: undefined,
   })

   function handleOpen(status: StatusType, userName: string, uuid: string, processingState?: string) {
      if (processingState === 'LOCKED') {
         setModalInfo({ open: true, modalType: 'inProcessing' })
      } else if (status === 'INUSE') {
         setModalInfo({ open: true, modalType: 'alreadyInUse', userName })
      } else if (!status || status === 'FREE' || status === 'MAIN') {
         mutate(uuid, {
            onSuccess: () => {
               navigate('/dashboard/main-cart')
            },
         })
      }
   }

   function returnCartStatus(status: StatusType, processingState: string) {
      if (processingState === 'LOCKED') {
         return 'LOCKED'
      }
      switch (status) {
         case 'MAIN':
         case 'INUSE':
            return status
         default:
            return 'FREE'
      }
   }

   function returnPriceTypeHeader(name: string) {
      return (
         <span>
            {t(`TRANSLATION:columnNames.${name}`)} {t(`TRANSLATION:${profileData?.priceType}`)}
         </span>
      )
   }

   const columns: MUIDataTableColumn[] = [
      {
         name: 'uuid',
         options: {
            filter: false,
            searchable: false,
            display: 'excluded',
         },
      },
      {
         name: 'name',
         options: {
            customHeadRender: (columnMeta, updateDirection, sortOrder) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
               }),
            sortThirdClickReset: true,
            filter: false,
         },
      },
      {
         name: 'createdDate',
         options: {
            sortThirdClickReset: true,
            filter: false,
            customHeadRender: (columnMeta, updateDirection, sortOrder) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
               }),
            sortCompare: (order) => (obj1: { data: string }, obj2: { data: string }) =>
               order === 'asc'
                  ? sortByDate(reverseDateRepresentation(obj1.data), reverseDateRepresentation(obj2.data))
                  : -sortByDate(reverseDateRepresentation(obj1.data), reverseDateRepresentation(obj2.data)),
            setCellProps: () => ({
               style: { width: 250 },
            }),
         },
      },
      {
         name: 'updatedDate',
         options: {
            sortThirdClickReset: true,
            filter: false,
            customHeadRender: (columnMeta, updateDirection, sortOrder) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
               }),
            sortCompare: (order) => (obj1: { data: string }, obj2: { data: string }) =>
               order === 'asc'
                  ? sortByDate(reverseDateRepresentation(obj1.data), reverseDateRepresentation(obj2.data))
                  : -sortByDate(reverseDateRepresentation(obj1.data), reverseDateRepresentation(obj2.data)),
            setCellProps: () => ({
               style: { width: 250 },
            }),
         },
      },
      {
         name: 'description',
         options: {
            sort: false,
            filter: false,
            setCellProps: () => ({
               style: { width: 250 },
            }),
         },
      },
      {
         name: 'userName',
         options: {
            customHeadRender: (columnMeta, updateDirection, sortOrder) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
               }),
            sortThirdClickReset: true,
            filter: false,
         },
      },
      {
         name: 'formattedValue',
         options: {
            sortThirdClickReset: true,
            filter: false,
            customHeadLabelRender: (value) => returnPriceTypeHeader(value.name),
            customHeadRender: (columnMeta, updateDirection, sortOrder) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
                  shouldShowPriceType: true,
               }),
         },
      },
      {
         name: 'formattedClientValue',
         options: {
            sortThirdClickReset: true,
            filter: false,
            customHeadLabelRender: (value) => returnPriceTypeHeader(value.name),
            customHeadRender: (columnMeta, updateDirection, sortOrder) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
                  shouldShowPriceType: true,
               }),
         },
      },
      {
         name: 'status',
         options: {
            sortThirdClickReset: true,
            filter: false,
            customHeadRender: (columnMeta, updateDirection, sortOrder) =>
               React.createElement(TableHeadCell, {
                  value: columnMeta.name,
                  updateDirection,
                  index: columnMeta.index,
                  sortName: sortOrder.name,
                  sortDirection: sortOrder.direction,
               }),
            customBodyRender: (value: StatusType, tableMeta) =>
               `${t(`SAVED_CARTS:status.${returnCartStatus(value, tableMeta.rowData[8])}`)}`,
         },
      },
      {
         name: 'processingState',
         options: {
            display: 'excluded',
         },
      },
      {
         name: 'removeCart',
         options: {
            sortThirdClickReset: true,
            filter: false,
            sort: false,
            // eslint-disable-next-line react/no-unstable-nested-components
            customBodyRenderLite: (RowIndex) => (
               <StyledCloseIcon
                  onClick={(e) => {
                     e.stopPropagation()
                     e.preventDefault()
                     const temp = data?.carts
                        .filter(
                           (item) =>
                              item.processingState === 'AVAILABLE' ||
                              item.processingState === 'LOCKED' ||
                              !item.processingState
                        )
                        .at(RowIndex)
                     setModalInfo({
                        open: true,
                        cartName: temp?.name,
                        uuid: temp?.uuid,
                        modalType: 'delete',
                     })
                  }}
               />
            ),
         },
      },
   ]

   const translation = t('TRANSLATION:searchAndFilters.searchEverywhere') || ''

   const options: MUIDataTableOptions = {
      responsive: 'simple',
      selectableRows: 'none',
      rowsPerPageOptions: [10, 25, 50],
      rowsPerPage: Number(window.localStorage.getItem('itemsPerPage')) || 25,
      onChangeRowsPerPage: (numberOfRows: number) =>
         window.localStorage.setItem('itemsPerPage', numberOfRows.toString()),
      print: false,
      searchAlwaysOpen: true,
      textLabels: {
         body: {
            noMatch: t('SAVED_CARTS:noResults'),
            columnHeaderTooltip: (column) => `${t('SAVED_CARTS:sortBy')} ${column.label}`,
         },
         toolbar: {
            search: t('SAVED_CARTS:search'),
         },
         pagination: {
            rowsPerPage: t('SAVED_CARTS:rowsPerPage'),
            displayRows: t('SAVED_CARTS:of'),
         },
      },
      search: true,
      download: false,
      filter: false,
      viewColumns: false,
      customSearchRender(searchText, handleSearch) {
         return (
            <Box sx={{ display: 'flex', flexDirection: { md: 'row', xs: 'column' }, gap: 2, mt: 2 }}>
               <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                  <TextField
                     variant="standard"
                     value={searchPhrase}
                     InputProps={{
                        startAdornment: (
                           <InputAdornment position="start">
                              <SearchIcon />
                           </InputAdornment>
                        ),
                     }}
                     onChange={(e) => {
                        setSearchPhrase(e.target.value)
                        handleSearch(e.target.value)
                     }}
                  />
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={selectedFilter === 'ALL'}
                           onChange={() => {
                              if (selectedFilter === 'ALL') {
                                 setSelectedFilter('LAST_90_DAYS')
                              } else {
                                 setSelectedFilter('ALL')
                              }
                           }}
                        />
                     }
                     label={translation}
                  />
               </Box>
               <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <ContainedButton
                     variant="contained"
                     onClick={() => {
                        setSelectedFilter('LAST_90_DAYS')
                        setSearchPhrase('')
                        handleSearch('')
                     }}
                  >
                     {t('TRANSLATION:searchAndFilters.clearFilters')}
                  </ContainedButton>
                  {data?.years.availableFilters
                     .filter((item) => item !== 'ALL')
                     .reverse()
                     .map((item) => (
                        <ContainedButton
                           onClick={() => {
                              setSelectedFilter(item)
                           }}
                           disabled={selectedFilter === item || isMutateLoading}
                           variant="contained"
                           key={item}
                        >
                           {t(`TRANSLATION:searchAndFilters.filters.${item}`)}
                        </ContainedButton>
                     ))}
               </Box>
            </Box>
         )
      },
      fixedHeader: false,
      setRowProps: () => ({ style: { textAlign: 'center', cursor: 'pointer' } }),
      onRowClick: (rowData) => {
         const id = rowData[0]
         const cartToOpen = data?.carts
            .filter(
               (item) =>
                  item.processingState === 'AVAILABLE' || item.processingState === 'LOCKED' || !item.processingState
            )
            .find((item) => item.uuid === id)
         if (cartToOpen) handleOpen(cartToOpen.status, cartToOpen.userName, id, cartToOpen.processingState)
      },
   }

   useEffect(() => {
      if (data) {
         savedCartsMutate(selectedFilter)
      }
   }, [selectedFilter])

   return (
      <Box>
         {data && !isLoading && Array.isArray(data.carts) ? (
            <StyledTableWrapper>
               <MUIDataTable
                  title=""
                  data={data.carts.filter(
                     (item) =>
                        item.processingState === 'AVAILABLE' ||
                        item.processingState === 'LOCKED' ||
                        !item.processingState
                  )}
                  columns={columns.map((el) => ({
                     ...el,
                     key: el.name,
                     label: t(`TRANSLATION:columnNames.${el.name}`),
                  }))}
                  options={options}
               />
            </StyledTableWrapper>
         ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
               <CircularProgress />
            </Box>
         )}
         <Modal
            modalInfo={modalInfo}
            handleClose={() =>
               setModalInfo({
                  ...modalInfo,
                  open: false,
               })
            }
         />
      </Box>
   )
}

const StyledTableWrapper = styled('div')({
   '.MuiTable-root': {
      overflowX: 'scroll',
   },
   '.MuiTable-head': {
      backgroundColor: 'rgba(33,147,224, 0.1)',
   },
   '.MuiTableCell-head': {
      padding: '6px 4px 6px 4px',
      backgroundColor: 'rgba(33,147,224, 0.1)',
      color: 'grayText',
      textAlign: 'center',
   },
   '.MuiTableCell-root': {
      padding: '6px 4px 6px 4px',
      textAlign: 'center',
   },
   '.MuiTableFooter-root': {
      color: 'grayText',
      backgroundColor: 'rgba(33,147,224, 0.1)',
   },
})
