import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import Typography from '@mui/material/Typography'

export const BoldTypography = styled(Typography)({
   fontWeight: 'bold',
   color: 'grayText',
})

export const StyledTable = styled(Table)({
   margin: '0 auto',
   marginTop: 40,
   WebkitBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   MozBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   boxShadow: ' 0px 0px 10px -4px rgba(66, 68, 90, 1)',
   borderRadius: '10px',
   '>*': {
      color: 'grayText',
   },
})

export const QuantityHolder = styled(Box)({
   display: 'flex',
   flexDirection: 'row',
   justifyContent: 'space-between',
   margin: '0 auto',
   padding: 4,
   paddingLeft: 20,
   paddingRight: 20,
   maxWidth: 120,
   border: '1px solid #C4C2C4',
   borderRadius: 10,
})

export const StyledQuantityButton = styled('button')(({ theme }) => ({
   border: 'none',
   background: 'rgba(0, 0, 0, 0)',
   fontWeight: 'bold',
   fontSize: 15,
   '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.light,
   },
}))

export const StyledTableRow = styled(TableRow)({
   backgroundColor: 'white',
   '&:hover': {
      backgroundColor: 'rgba(28,202,255,0.03)',
      cursor: 'pointer',
   },
})

export const TypographyLink = styled(Typography)(({ theme }) => ({
   color: theme.palette.primary.main,
   userSelect: 'none',
   '&:hover': {
      color: theme.palette.primary.darker,
      cursor: 'pointer',
      transform: 'scale(1.01)',
   },
}))
export const StyledTitleTableCell = styled(TableCell)({
   textAlign: 'center',
   color: '#637381',
   fontWeight: 'bold',
})

export const UnitHolder = styled(Box)({
   display: 'flex',
   flexDirection: 'column',
   gap: 0.5,
})

export const StyledCollapseTableCell = styled(TableCell)({
   textAlign: 'center',
   color: 'grayText',
})

export const StyledTableCell = styled(TableCell)({
   textAlign: 'center',
   fontWeight: 'bold',
})
