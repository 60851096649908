import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'

import consoleMessage from '../utils/helpers/consoleMessage'

export async function fetchNotCooperatingWholesalersData(productUuid: string): Promise<FetchNoCooperationDataResult> {
   return httpClient
      .get<FetchNotCooperatingWholesalersDataType>(`/products/${productUuid}/offline-availability-in-not-cooperating`)
      .then((response) => {
         if (response.data) {
            return {
               notCooperatingWholesalers: response.data,
            }
         }
         throw new Error('noData')
      })
      .catch((err: Error | AxiosError<BackendError>) => {
         let errMessage
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with fetchCooperationData()'
         } else {
            errMessage = err.message
         }
         consoleMessage('fetchCooperationData()', errMessage, 'error')
         throw new Error(errMessage)
      })
}
