import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import { FetchOilParamsResults } from './types'

export function fetchOilParams(): Promise<FetchOilParamsResults> {
   return httpClient
      .get('products/oils/getChoices')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err) => {
         consoleMessage('HEADER fetchOilParams()', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}
