import React, { useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import { pythonHttpClient } from 'shared/lib'

import { DuplicatedProduct } from '../../types'

interface Props {
   open: boolean
   duplicatedProducts: DuplicatedProduct[]
   handleClose: () => void
}

export default function MergeProductsModal({ open, duplicatedProducts, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const { t } = useTranslation(['TRANSLATION', 'PRODUCTS_LIST'])
   const [mainProductUuid, setMainProductUuid] = useState('')
   const [isLoading, setIsLoading] = useState(false)

   function mergeDuplicates() {
      setIsLoading(true)
      const duplicatesWithoutMain = duplicatedProducts
         .filter((item) => item.uuid !== mainProductUuid)
         .map((item) => item.uuid)
      pythonHttpClient
         .post('products/merge', {
            debug_mode: false,
            sort: false,
            products_uuids: [mainProductUuid, ...duplicatesWithoutMain],
         })
         .then(() => {
            addNotification('successSave', 'success')
            handleClose()
         })
         .catch(() => {
            addNotification('failedSave', 'error')
         })
         .finally(() => {
            setIsLoading(false)
         })
   }

   return (
      <Dialog open={open} onClose={handleClose}>
         <DialogTitle sx={{ textAlign: 'center' }} color="primary.dark">
            {t('PRODUCTS_LIST:mergeModalTitle')}
         </DialogTitle>
         <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {duplicatedProducts.map((item) => (
               <Box>
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={item.uuid === mainProductUuid}
                           onChange={() => setMainProductUuid(item.uuid)}
                        />
                     }
                     label={`${item.index} ${item.producer} (${t('PRODUCTS_LIST:wholesaleCount')} ${
                        item.wholesaleCount
                     })`}
                  />
               </Box>
            ))}
         </DialogContent>
         <DialogActions>
            <OutlinedButton variant="outlined" onClick={handleClose}>
               {t('TRANSLATION:cancel')}
            </OutlinedButton>
            <ContainedButton
               disabled={isLoading || mainProductUuid === ''}
               variant="contained"
               onClick={mergeDuplicates}
            >
               {t('TRANSLATION:save')}
            </ContainedButton>
         </DialogActions>
      </Dialog>
   )
}
