import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import isAxiosError from 'shared/utils/helpers/isAxiosError'
import {
   CreateConfigResult,
   GetTokenResult,
   ModifyPayload,
   StatusesResult,
   ExternalStatusType,
   GetExternalFieldsType,
} from '../types'

export function getBaselinkerTokenService() {
   const errMessage = ''
   return httpClient
      .get<GetTokenResult>('baselinker/')
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((err: Error | ErrorType) => {
         if (isAxiosError<ErrorType>(err)) {
            throw new Error(err.response?.data.errors[0].defaultMessage)
         }
         consoleMessage('Wholesalers Baselinker getBaselinkerTokenService', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function getExternalInformationsService() {
   const errMessage = ''
   return httpClient
      .get<GetExternalFieldsType>('baselinker/extra-fields')
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((err: Error | ErrorType) => {
         if (isAxiosError<ErrorType>(err)) {
            throw new Error(err.response?.data.errors[0].defaultMessage)
         }
         consoleMessage('Wholesalers Baselinker getExternalInformationsService', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function createBaselinkerConfigService(token: string) {
   const errMessage = ''
   return httpClient
      .post<CreateConfigResult>('baselinker/', { token })
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((err: Error | ErrorType) => {
         if (isAxiosError<ErrorType>(err)) {
            throw new Error(err.response?.data.errors[0].defaultMessage)
         }
         consoleMessage('Wholesalers Baselinker createBaselinkerConfigService', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function getBaselinkerConfigService(configUuid: string) {
   const errMessage = ''
   return httpClient
      .get<GetTokenResult>(`baselinker/${configUuid}`)
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((err: Error | ErrorType) => {
         if (isAxiosError<ErrorType>(err)) {
            throw new Error(err.response?.data.errors[0].defaultMessage)
         }
         consoleMessage('Wholesalers Baselinker getBaselinkerConfigService', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function getBaselinkerStatusesService() {
   const errMessage = ''
   return httpClient
      .get<ExternalStatusType>('baselinker/order-statuses')
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((err: Error | ErrorType) => {
         if (isAxiosError<ErrorType>(err)) {
            throw new Error(err.response?.data.errors[0].defaultMessage)
         }
         consoleMessage('Wholesalers Baselinker getBaselinkerStatusesService', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function editBaselinkerConfigService(configUuid: string, payload: ModifyPayload) {
   const errMessage = ''
   return httpClient
      .patch<StatusesResult>(`baselinker/${configUuid}`, payload)
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((err: Error | ErrorType) => {
         if (isAxiosError<ErrorType>(err)) {
            throw new Error(err.response?.data.errors[0].defaultMessage)
         }
         consoleMessage('Wholesalers Baselinker editBaselinkerConfigService', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function deleteBaselinkerConfigService(configUuid: string) {
   const errMessage = ''
   return httpClient
      .delete<GetTokenResult>(`baselinker/${configUuid}`)
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((err: Error | ErrorType) => {
         if (isAxiosError<ErrorType>(err)) {
            throw new Error(err.response?.data.errors[0].defaultMessage)
         }
         consoleMessage('Wholesalers Baselinker getBaselinkerTokenService', errMessage, 'error')
         throw new Error(errMessage)
      })
}
