import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import { CompanySettingsType } from './types'

export function fetchCompanyData() {
   return httpClient
      .get<CompanySettingsType>('companies')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err) => {
         consoleMessage('COMPANY', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}

export function saveCompanyData(payload: CompanySettingsType) {
   return httpClient
      .patch<Result>(`companies`, payload)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         consoleMessage('PROFILE', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}
