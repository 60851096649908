import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import { Result } from './types'

export function sendLogoService(logo: FormData) {
   let errMessage = ''
   return httpClient
      .put<Result>('companies/logo', logo)
      .then((res) => ({ status: res.status }))
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with sendLogoService'
         } else {
            errMessage = err.message
         }
         consoleMessage('profile sendLogoService', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function removeLogoService() {
   let errMessage = ''
   return httpClient
      .delete<Result>('companies/logo')
      .then((res) => ({ status: res.status }))
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with sendLogoService'
         } else {
            errMessage = err.message
         }
         consoleMessage('profile sendLogoService', errMessage, 'error')
         throw new Error(errMessage)
      })
}
