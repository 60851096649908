import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import Collapse from '@mui/material/Collapse'
import Checkbox from '@mui/material/Checkbox'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'

import { getProductName } from '../../helpers'
import {
   StyledTable,
   StyledTableCell,
   StyledTableRow,
   StyledTitleTableCell,
   StyledCollapseTableCell,
   QuantityHolder,
   StyledQuantityButton,
} from '../../styles'
import { AdditionalService, Product } from '../../types'

interface Props {
   products: Product[]
   handleIncludeInOrder: (cartProductUuid: string, includeProductInOrder: boolean) => void
   handleChangeQuantity: (quantity: number, uuid: string) => void
   formattedNetValue: string
   formattedGrossValue: string
   disableButtons: boolean
   services: AdditionalService[]
   formattedAdditionalServicesGrossValue: string
   formattedAdditionalServicesNetValue: string
   formattedClientGrossValueWithAdditionalServices: string
   formattedClientNetValueWithAdditionalServices: string
}

export default function MobileTable({
   products,
   handleIncludeInOrder,
   handleChangeQuantity,
   disableButtons,
   formattedGrossValue,
   formattedNetValue,
   services,
   formattedAdditionalServicesGrossValue,
   formattedAdditionalServicesNetValue,
   formattedClientGrossValueWithAdditionalServices,
   formattedClientNetValueWithAdditionalServices,
}: Props) {
   const [collapsed, setCollapsed] = useState<string[]>([])
   const { t } = useTranslation(['TRANSLATION', 'ORDER_REFLINK'])

   function handleCollapse(uuid: string) {
      if (collapsed.includes(uuid)) {
         setCollapsed(collapsed.filter((item) => item !== uuid))
      } else {
         const tempCollapseProducts = [...collapsed]
         tempCollapseProducts.push(uuid)
         setCollapsed([...tempCollapseProducts])
      }
   }

   return (
      <>
         <StyledTable sx={{ mt: 3, mb: 5 }}>
            <TableHead sx={{ backgroundColor: 'rgba(33,137,224,0.1)' }}>
               <TableRow>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.productName')}</StyledTitleTableCell>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.producer')}</StyledTitleTableCell>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.includeInOrder')}</StyledTitleTableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {products.map((product) => (
                  <React.Fragment key={product.uuid}>
                     <StyledTableRow onClick={() => handleCollapse(product.uuid)}>
                        <StyledTableCell>{getProductName(product)}</StyledTableCell>
                        <StyledTableCell>
                           <img
                              height="20px"
                              width="auto"
                              src={`https://files.motorro.eu/img?name=${product.producerNameWithoutSpecialChars.toLowerCase()}`}
                              alt={product.producerName}
                           />
                        </StyledTableCell>
                        <StyledTableCell>
                           <Checkbox
                              disabled={disableButtons || product.cartWholesaleDTO.quantity === 0}
                              color="primary"
                              onClick={(e) => {
                                 e.stopPropagation()
                                 handleIncludeInOrder(product.uuid, !product.includedProductInOrder)
                              }}
                              checked={product.includedProductInOrder}
                           />
                        </StyledTableCell>
                     </StyledTableRow>
                     <TableCell colSpan={3} sx={{ padding: 0 }}>
                        <Collapse
                           sx={{ oveflowY: 'scroll', maxHeight: 300 }}
                           in={collapsed.includes(product.uuid)}
                           timeout="auto"
                        >
                           <Table>
                              <TableBody>
                                 <TableRow>
                                    <StyledCollapseTableCell colSpan={2}>
                                       {t('TRANSLATION:columnNames.quantity')}
                                    </StyledCollapseTableCell>
                                    <StyledCollapseTableCell>
                                       <QuantityHolder>
                                          <StyledQuantityButton
                                             disabled={product.cartWholesaleDTO.quantity === 0 || disableButtons}
                                             onClick={() =>
                                                handleChangeQuantity(
                                                   product.cartWholesaleDTO.quantity - product.quantityInSet,
                                                   product.uuid
                                                )
                                             }
                                          >
                                             -
                                          </StyledQuantityButton>
                                          <StyledQuantityButton type="button" style={{ fontWeight: 'normal' }}>
                                             {product.cartWholesaleDTO.quantity}
                                          </StyledQuantityButton>
                                          <StyledQuantityButton
                                             disabled={
                                                product.cartWholesaleDTO.quantity ===
                                                   product.cartWholesaleDTO.initialQuantity || disableButtons
                                             }
                                             onClick={() =>
                                                handleChangeQuantity(
                                                   product.cartWholesaleDTO.quantity + product.quantityInSet,
                                                   product.uuid
                                                )
                                             }
                                          >
                                             +
                                          </StyledQuantityButton>
                                       </QuantityHolder>
                                    </StyledCollapseTableCell>
                                 </TableRow>
                                 <TableRow>
                                    <StyledCollapseTableCell colSpan={2}>
                                       {t('TRANSLATION:columnNames.netPrice')}
                                    </StyledCollapseTableCell>
                                    <StyledCollapseTableCell>
                                       {product.cartWholesaleDTO.formattedClientNetPrice}
                                    </StyledCollapseTableCell>
                                 </TableRow>
                                 <TableRow>
                                    <StyledCollapseTableCell colSpan={2}>
                                       {t('TRANSLATION:columnNames.grossPrice')}
                                    </StyledCollapseTableCell>
                                    <StyledCollapseTableCell>
                                       {product.cartWholesaleDTO.formattedClientGrossPrice}
                                    </StyledCollapseTableCell>
                                 </TableRow>
                                 <TableRow>
                                    <StyledCollapseTableCell colSpan={2}>VAT</StyledCollapseTableCell>
                                    <StyledCollapseTableCell>
                                       {product.cartWholesaleDTO.taxRate}%
                                    </StyledCollapseTableCell>
                                 </TableRow>
                                 <TableRow>
                                    <StyledCollapseTableCell colSpan={2}>
                                       {t('TRANSLATION:columnNames.grossValue')}
                                    </StyledCollapseTableCell>
                                    <StyledCollapseTableCell>
                                       {product.cartWholesaleDTO.formattedClientGrossValue}
                                    </StyledCollapseTableCell>
                                 </TableRow>
                              </TableBody>
                           </Table>
                        </Collapse>
                     </TableCell>
                  </React.Fragment>
               ))}
               <TableRow sx={{ backgroundColor: 'rgba(33,137,224,0.1)' }}>
                  <StyledTableCell sx={{ fontSize: 14, textAlign: 'right' }}>{`${t(
                     'TRANSLATION:columnNames.netSum'
                  )}: ${formattedNetValue}`}</StyledTableCell>
                  <StyledTableCell />
                  <StyledTableCell sx={{ fontSize: 14, textAlign: 'left' }}>{`${t(
                     'TRANSLATION:columnNames.grossSum'
                  )}: ${formattedGrossValue} `}</StyledTableCell>
               </TableRow>
               {services.map((service) => (
                  <React.Fragment key={service.uuid}>
                     <StyledTableRow onClick={() => handleCollapse(service.uuid)}>
                        <StyledTableCell colSpan={3}>{service.name}</StyledTableCell>
                     </StyledTableRow>
                     <TableCell colSpan={3} sx={{ padding: 0 }}>
                        <Collapse
                           sx={{ oveflowY: 'scroll', maxHeight: 300 }}
                           in={collapsed.includes(service.uuid)}
                           timeout="auto"
                        >
                           <Table>
                              <TableBody>
                                 <TableRow>
                                    <StyledCollapseTableCell colSpan={2}>
                                       {t('TRANSLATION:columnNames.quantity')}
                                    </StyledCollapseTableCell>
                                    <StyledCollapseTableCell>{service.quantity}</StyledCollapseTableCell>
                                 </TableRow>
                                 <TableRow>
                                    <StyledCollapseTableCell colSpan={2}>
                                       {t('TRANSLATION:columnNames.netPrice')}
                                    </StyledCollapseTableCell>
                                    <StyledCollapseTableCell>{service.formattedNetPrice}</StyledCollapseTableCell>
                                 </TableRow>
                                 <TableRow>
                                    <StyledCollapseTableCell colSpan={2}>
                                       {t('TRANSLATION:columnNames.grossPrice')}
                                    </StyledCollapseTableCell>
                                    <StyledCollapseTableCell>{service.formattedGrossPrice}</StyledCollapseTableCell>
                                 </TableRow>
                                 <TableRow>
                                    <StyledCollapseTableCell colSpan={2}>VAT</StyledCollapseTableCell>
                                    <StyledCollapseTableCell>{service.taxRate}%</StyledCollapseTableCell>
                                 </TableRow>
                                 <TableRow>
                                    <StyledCollapseTableCell colSpan={2}>
                                       {t('TRANSLATION:columnNames.grossValue')}
                                    </StyledCollapseTableCell>
                                    <StyledCollapseTableCell>{service.formattedGrossValue}</StyledCollapseTableCell>
                                 </TableRow>
                              </TableBody>
                           </Table>
                        </Collapse>
                     </TableCell>
                  </React.Fragment>
               ))}
               <TableRow sx={{ backgroundColor: 'rgba(33,137,224,0.1)' }}>
                  <StyledTableCell sx={{ fontSize: 14, textAlign: 'right' }}>{`${t(
                     'TRANSLATION:columnNames.netSum'
                  )}: ${formattedAdditionalServicesNetValue}`}</StyledTableCell>
                  <StyledTableCell />
                  <StyledTableCell sx={{ fontSize: 14, textAlign: 'left' }}>{`${t(
                     'TRANSLATION:columnNames.grossSum'
                  )}: ${formattedAdditionalServicesGrossValue} `}</StyledTableCell>
               </TableRow>
            </TableBody>
         </StyledTable>
         <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, pb: 3 }}>
            <Typography variant="h5">{`${t('TRANSLATION:columnNames.netSum')}: ${formattedClientNetValueWithAdditionalServices}`}</Typography>
            <Typography variant="h5">{`${t('TRANSLATION:columnNames.grossSum')}: ${formattedClientGrossValueWithAdditionalServices}`}</Typography>
         </Box>
      </>
   )
}
