import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import { AddServicePayload, AdditionalServicesResponseType } from './types'

export function fetchServices(locationUuid?: string) {
   let errMessage = ''

   return httpClient
      .get<AdditionalServicesResponseType>(`additional-services/${locationUuid}`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err: Error | AxiosError<ErrorType>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || ''
         } else {
            errMessage = err.message
         }
         consoleMessage('ADDITIONAL SERVICES fetchServices()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function addService(payload: AddServicePayload, locationUuid: string) {
   let errMessage = ''

   return httpClient
      .post(`additional-services/${locationUuid}`, payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((err: Error | AxiosError<ErrorType>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = err.message
         }
         consoleMessage('ADDITIONAL SERVICES addService()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function editService(payload: AddServicePayload, locationUuid: string, serviceUuid: string) {
   let errMessage = ''

   return httpClient
      .put(`additional-services/${locationUuid}/${serviceUuid}`, payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((err: Error | AxiosError<ErrorType>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = err.message
         }
         consoleMessage('ADDITIONAL SERVICES addService()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function deleteService(locationUuid: string, serviceUuid: string) {
   let errMessage = ''

   return httpClient
      .delete(`additional-services/${locationUuid}/${serviceUuid}`)
      .then((res) => ({
         status: res.status,
      }))
      .catch((err: Error | AxiosError<ErrorType>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = err.message
         }
         consoleMessage('ADDITIONAL SERVICES addService()', errMessage, 'error')
         throw new Error(errMessage)
      })
}
