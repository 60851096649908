import React, { useState } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { t } from 'i18next'
import Typography from '@mui/material/Typography'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import Grid from '@mui/material/Grid'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'

import useAdditionalServicesQuery from 'shared/store/useAddtionalServices'
import { LocationType } from 'shared/store/useLocations/types'
import { StyledEditIcon } from 'views/Authorized/views/UsersAndSubscription/styles'
import { StyledContentTableCell, StyledDeleteIcon } from 'views/Unauthorized/views/ClientCartReflink/styles'
import { StyledTableCell } from 'views/Unauthorized/views/OrderReflink/styles'

import { ModalInfoType } from '../../types'
import Modal from '../../Modal'
import { StyledTable } from '../../styles'

interface Props {
   activeLocation: LocationType
}
export default function ServicesList({ activeLocation }: Props) {
   const { data, isFetching } = useAdditionalServicesQuery(activeLocation.locationSettings.uuid)
   const [modalInfo, setModalInfo] = useState<ModalInfoType>()

   function renderContent() {
      if (isFetching) {
         return <CircularProgress color="primary" />
      }
      if (data && data.additionalServices.length === 0) {
         return (
            <Box sx={{ width: '100%', textAlign: 'center' }}>
               <Typography variant="h5" color="primary">
                  {t('SERVICES:noServices')}
               </Typography>
               <ContainedButton
                  onClick={() =>
                     setModalInfo({ locationUuid: activeLocation.locationSettings.uuid, modalType: 'CREATE' })
                  }
                  variant="contained"
                  sx={{ mt: 1 }}
               >
                  {t('SERVICES:addServiceButtonLabel')}
               </ContainedButton>
            </Box>
         )
      }
      if (data) {
         return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
               <StyledTable>
                  <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                     <TableRow>
                        <StyledTableCell>{t('TRANSLATION:formFields.serviceName')} </StyledTableCell>
                        <StyledTableCell>{t('TRANSLATION:formFields.defaultNetPrice')}</StyledTableCell>
                        <StyledTableCell>VAT</StyledTableCell>
                        <StyledTableCell>{t('TRANSLATION:formFields.defaultGrossPrice')}</StyledTableCell>
                        <StyledTableCell>{t('TRANSLATION:edit')}</StyledTableCell>
                        <StyledTableCell>{t('TRANSLATION:delete')}</StyledTableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {data.additionalServices.map((item) => (
                        <TableRow key={item.uuid}>
                           <StyledContentTableCell>{item.name}</StyledContentTableCell>
                           <StyledContentTableCell>{item.formattedDefaultNetPrice}</StyledContentTableCell>
                           <StyledContentTableCell>{item.taxRate}</StyledContentTableCell>
                           <StyledContentTableCell>{item.formattedDefaultGrossPrice}</StyledContentTableCell>
                           <StyledContentTableCell>
                              <IconButton
                                 onClick={() =>
                                    setModalInfo({
                                       locationUuid: activeLocation.locationSettings.uuid,
                                       modalType: 'EDIT',
                                       serviceUuid: item.uuid,
                                       serviceInfo: {
                                          ...item,
                                       },
                                    })
                                 }
                              >
                                 <StyledEditIcon />
                              </IconButton>
                           </StyledContentTableCell>
                           <StyledContentTableCell>
                              <IconButton
                                 onClick={() =>
                                    setModalInfo({
                                       locationUuid: activeLocation.locationSettings.uuid,
                                       serviceUuid: item.uuid,
                                       serviceName: item.name,
                                       modalType: 'DELETE',
                                    })
                                 }
                              >
                                 <StyledDeleteIcon />
                              </IconButton>
                           </StyledContentTableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </StyledTable>
               <ContainedButton
                  onClick={() =>
                     setModalInfo({ locationUuid: activeLocation.locationSettings.uuid, modalType: 'CREATE' })
                  }
                  variant="contained"
                  sx={{ mt: 1 }}
               >
                  {t('SERVICES:addServiceButtonLabel')}
               </ContainedButton>
            </Box>
         )
      }
      return null
   }

   return (
      <Grid item xs={12} md={10}>
         {renderContent()}
         {modalInfo && <Modal modalInfo={modalInfo} handleClose={() => setModalInfo(undefined)} />}
      </Grid>
   )
}
