import React from 'react'
import DialogActions from '@mui/material/DialogActions'
import { useTranslation } from 'react-i18next'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useDeleteReflinkMutation } from 'shared/store/useClientReflinks'

interface Props {
   reflinkUuid: string
   handleClose: () => void
}

export default function DeleteReflinkModal({ reflinkUuid, handleClose }: Props) {
   const { mutate } = useDeleteReflinkMutation()
   const { t } = useTranslation(['TRANSLATION', 'CLIENTS_CARTS'])

   function handleDelete() {
      mutate(reflinkUuid, {
         onSuccess: handleClose,
      })
   }

   return (
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
         <OutlinedButton variant="outlined" onClick={handleClose}>
            {t('TRANSLATION:no')}
         </OutlinedButton>
         <ContainedButton onClick={handleDelete} variant="contained">
            {t('TRANSLATION:yes')}
         </ContainedButton>
      </DialogActions>
   )
}
