import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import Hidden from 'shared/components/Hidden'

import { initGoogleTag } from 'shared/lib/GoogleTag'
import RegisterForm from './components/RegisterForm'
import { en, pl, de } from './locales'

const RootStyle = styled('div')({
   display: 'flex',
   gap: 150,
   paddingTop: '100px',
})

const SectionStyle = styled(Card)({
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
   marginTop: '70px',
   marginLeft: '200px',
   height: 'min-content',
   width: '100%',
   maxWidth: 664,
})

const ContentStyle = styled('div')({
   marginX: 'auto',
   maxWidth: 680,
})

export default function Register() {
   const theme = useTheme()
   const { t, i18n } = useTranslation('REGISTER')
   i18n.addResourceBundle('pl-PL', 'REGISTER', pl)
   i18n.addResourceBundle('en-EN', 'REGISTER', en)
   i18n.addResourceBundle('de-DE', 'REGISTER', de)

   useEffect(() => {
      initGoogleTag('registerPage')
   }, [])

   return (
      <RootStyle title="Register">
         <Hidden width="mdDown">
            <SectionStyle>
               <img alt="register" src="https://files.motorro.eu/img/slider/register.png" />
            </SectionStyle>
         </Hidden>
         <Container>
            <ContentStyle>
               <Box sx={{ mb: 1, ml: 1, display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flexGrow: 1 }}>
                     <Typography variant="h4" color="primary" gutterBottom>
                        {t('mainSite.registerTitle')}
                     </Typography>
                  </Box>
               </Box>
               <Box>
                  <RegisterForm />
               </Box>
               <Box>
                  <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                     {t('mainSite.byRegisteringTitle')}
                     <RouterLink
                        style={{
                           textDecoration: 'none',
                           color: theme.palette.primary.dark,
                        }}
                        to="https://motorro.pl/regulamin/"
                        target="_blank"
                     >
                        {` ${t('mainSite.termsOfService')}`}
                     </RouterLink>
                     <span> {t('mainSite.and')} </span>
                     <RouterLink
                        style={{
                           textDecoration: 'none',
                           color: theme.palette.primary.dark,
                        }}
                        to="https://motorro.pl/polityka-prywatnosci/"
                        target="_blank"
                     >
                        {t('mainSite.privacyPolicy')}
                     </RouterLink>
                     .
                  </Typography>
                  <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                     <span>{t('mainSite.backToLoginTitle')} </span>
                     <RouterLink
                        style={{
                           textDecoration: 'none',
                           color: theme.palette.primary.dark,
                        }}
                        to="/"
                     >
                        {t('mainSite.loginButton')}
                     </RouterLink>
                  </Typography>
               </Box>
            </ContentStyle>
         </Container>
      </RootStyle>
   )
}
