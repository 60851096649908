import { TableRow, styled } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'

export const StyledTableCell = styled(TableCell)({
   color: 'grayText',
   textAlign: 'center',
})

export const StyledTable = styled(Table)({
   WebkitBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   MozBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   boxShadow: ' 0px 0px 10px -4px rgba(66, 68, 90, 1)',
   borderRadius: '10px',
   color: 'grayText',
   marginTop: 40,
})

export const BasicTableCell = styled(TableCell)({
   padding: '8px 4px 8px 4px',
   textAlign: 'center',
})

export const StyledTableRow = styled(TableRow)({
   '&:hover': { backgroundColor: 'rgba(28,202,255,0.03)', cursor: 'pointer' },
})
