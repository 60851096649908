import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { useFormik, Form, FormikProvider } from 'formik'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import InputField from 'shared/components/InputField'
import { pythonHttpClient } from 'shared/lib'

import { EditWholesalerProductType } from './types'
import { EditModal } from '../../types'
import { schema } from './schema'

interface Props {
   modalInfo: EditModal | undefined
   handleClose: () => void
}

export default function EditWholesalerModal({ modalInfo, handleClose }: Props) {
   const { addNotification } = useNotificationContext()
   const [multipleIndexes, setMultipleIndex] = useState<string[]>()
   const [isLoading, setIsLoading] = useState(false)

   const formik = useFormik<EditWholesalerProductType>({
      initialValues: {
         deposit_index: '',
         wholesaler_index: '',
         times: 1,
         deposit_price: null,
      },
      validationSchema: schema,
      onSubmit: handleSubmit,
   })

   const { setValues, values, setFieldError } = formik

   useEffect(() => {
      setValues({
         deposit_index: '',
         deposit_price: null,
         wholesaler_index: '',
         times: 1,
      })
      if (modalInfo) {
         setIsLoading(true)
         pythonHttpClient
            .get(`products/${modalInfo.productUuid}/wholesales/${modalInfo.data.wholesaleUuid}`)
            .then((res: any) => {
               setValues({ ...res.data })
            })
            .finally(() => setIsLoading(false))
      }
   }, [modalInfo])

   function handleSubmit() {
      if (modalInfo) {
         setIsLoading(true)
         pythonHttpClient
            .patch(`products/${modalInfo.productUuid}/wholesales/${modalInfo.data.wholesaleUuid}`, {
               wholesaler_index: values.wholesaler_index,
               deposit_index: values.deposit_index || null,
               times: values.times,
               deposit_price: values.deposit_price ? values.deposit_price.toString() : null,
            })
            .then((res) => {
               if (res.status === 204) {
                  addNotification('successSave', 'success')
                  handleClose()
               }
            })
            .catch((err) => {
               if (err.response.status === 424) {
                  setFieldError('wholesaler_index', 'Taki indeks już istnieje')
                  pythonHttpClient
                     .post(`products/wholesales/${modalInfo.data.wholesaleUuid}`, {
                        wholesaler_index: values.wholesaler_index,
                     })
                     .then((res) => {
                        setMultipleIndex(res.data.products_uuids)
                     })
               } else {
                  addNotification('failedSave', 'error')
               }
            })
            .finally(() => setIsLoading(false))
      }
   }

   function handleWindowOpen(mainProductUuid?: string) {
      let params = ''
      if (multipleIndexes) {
         multipleIndexes.forEach((item) => {
            params = params.concat(`&productUuid=${item}`)
         })
      }
      if (mainProductUuid && params) {
         window.open(`${window.location.origin}/dashboard/internal-search?mainProductUuid=${mainProductUuid}${params}`)
      }
   }

   return (
      <Dialog open={!!modalInfo} onClose={handleClose}>
         <DialogTitle color="primary">Edytuj produkt w {modalInfo?.data.wholesaleName}</DialogTitle>
         <FormikProvider value={formik}>
            <Form>
               <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, ml: 10, mr: 10 }}>
                  <InputField disabled={isLoading} name="wholesaler_index" label="Index" />
                  <InputField
                     disabled={isLoading}
                     InputProps={{
                        inputProps: {
                           min: 1,
                        },
                     }}
                     type="number"
                     label="Krotność"
                     name="times"
                  />
                  <InputField disabled={isLoading} name="deposit_index" label="Indeks kaucji produktu" />
                  <InputField disabled={isLoading} type="number" name="deposit_price" label="Kwota kaucji produktu" />
                  {multipleIndexes && (
                     <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Button
                           color="error"
                           variant="contained"
                           onClick={() => handleWindowOpen(modalInfo?.productUuid)}
                        >
                           Pokaż listę produktów
                        </Button>
                     </Box>
                  )}
               </DialogContent>
               <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                  <OutlinedButton type="button" variant="outlined" onClick={handleClose}>
                     Zamknij
                  </OutlinedButton>
                  <ContainedButton disabled={isLoading} type="submit" variant="contained">
                     Zapisz
                  </ContainedButton>
               </DialogActions>
            </Form>
         </FormikProvider>
      </Dialog>
   )
}
