import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import SearchIcon from '@mui/icons-material/Search'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import InputField from 'shared/components/InputField'
import getCompanyData from 'shared/services/getCompanyData'
import useCountriesQuery from 'shared/store/useCountries'
import SelectField from 'shared/components/SelectField'

type Option = {
   name: string
   value: string
}

export function SecondStep() {
   const { t } = useTranslation('TRANSLATION')
   const { setFieldError, setFieldValue, setValues, values, errors, setTouched } =
      useFormikContext<RegisterInitialValues>()
   const { data } = useCountriesQuery()
   const [options, setOptions] = useState<{ voivodeshipOptions: Option[]; countyOptions: Option[] }>({
      voivodeshipOptions: [],
      countyOptions: [],
   })
   const taxId = values.taxID
   const isError = !!errors.taxID

   async function handleOnSearch() {
      await getCompanyData(taxId).then((res) => {
         if (res.error) {
            setFieldError('taxID', res.error)
         }
         if (res.data) {
            setValues({
               ...values,
               voivodeship: res.data.voivodeship,
               houseNumber: res.data.houseNumber,
               companyName: res.data.companyName,
               city: res.data.city,
               street: res.data.street,
               postCode: res.data.postalCode,
            })
         }
         const allFieldsTouched: { [key: string]: boolean } = Object.keys(values).reduce(
            (acc, key) => {
               acc[key] = true
               return acc
            },
            {} as { [key: string]: boolean }
         )

         setTouched(allFieldsTouched)
      })
   }

   useEffect(() => {
      if (data && values.country && values.country === 'Poland') {
         const selectedCountry = data[values.country]

         if (selectedCountry) {
            setOptions({
               voivodeshipOptions: Object.keys(selectedCountry.voivodeships).map((item) => ({
                  name: item,
                  value: item,
               })),
               countyOptions: values.voivodeship
                  ? selectedCountry.voivodeships[values.voivodeship].map((item) => ({
                       name: item,
                       value: item,
                    }))
                  : [],
            })
         }
      }
   }, [data, values])

   useEffect(() => {
      if (data && values.country === 'Poland') {
         const selectedCountry = data[values.country]

         if (!values.county || !selectedCountry?.voivodeships[values.voivodeship].includes(values.county)) {
            setFieldValue('county', '')
         }
      }
   }, [values.voivodeship])

   return (
      <Container>
         {values.country && data ? (
            <>
               <Grid container sx={{ marginBottom: 4 }}>
                  <Grid item xs={12}>
                     <InputField<RegisterInitialValues>
                        label={t('formFields.taxID')}
                        name="taxID"
                        InputProps={{
                           endAdornment: values.country === 'Poland' && (
                              <Button
                                 onClick={handleOnSearch}
                                 color="primary"
                                 variant="contained"
                                 sx={{ height: 40, ml: 1.5 }}
                                 disabled={isError}
                              >
                                 <Box component={SearchIcon} sx={{ color: 'white', width: 20, height: 20 }} />
                              </Button>
                           ),
                        }}
                     />
                  </Grid>
               </Grid>

               <Grid container sx={{ marginBottom: 4 }} justifyContent="space-between">
                  <Grid item xs={12}>
                     <InputField<RegisterInitialValues> label={t('formFields.companyName')} name="companyName" />
                  </Grid>
               </Grid>
               <Grid container sx={{ marginBottom: 4 }} justifyContent="space-between">
                  <Grid item xs={6}>
                     <InputField<RegisterInitialValues> label={t('formFields.city')} name="city" />
                  </Grid>
                  <Grid item xs={5}>
                     <InputField<RegisterInitialValues> label={t('formFields.postCode')} name="postCode" />
                  </Grid>
               </Grid>
               {values.country === 'Poland' ? (
                  <Grid container sx={{ marginBottom: 4 }} justifyContent="space-between">
                     <Grid item xs={6}>
                        <SelectField
                           searcher
                           noMinWidth
                           fullWidth
                           name="voivodeship"
                           inputVariant="outlined"
                           label={t('formFields.voivodeship')}
                           options={options.voivodeshipOptions}
                        />
                     </Grid>
                     <Grid item xs={5}>
                        <SelectField
                           disabled={!values.voivodeship}
                           searcher
                           noMinWidth
                           fullWidth
                           name="county"
                           inputVariant="outlined"
                           label={t('formFields.county')}
                           options={options.countyOptions}
                        />
                     </Grid>
                  </Grid>
               ) : (
                  <Grid container sx={{ marginBottom: 4 }} justifyContent="space-between">
                     <Grid item xs={6}>
                        <InputField fullWidth name="voivodeship" label={t('formFields.voivodeship')} />
                     </Grid>
                     <Grid item xs={5}>
                        <InputField
                           disabled={!values.voivodeship}
                           fullWidth
                           name="county"
                           label={t('formFields.county')}
                        />
                     </Grid>
                  </Grid>
               )}

               <Grid container sx={{ marginBottom: 4 }} justifyContent="space-between">
                  <Grid item xs={6}>
                     <InputField<RegisterInitialValues> label={t('formFields.street')} name="street" />
                  </Grid>
                  <Grid item xs={5}>
                     <InputField<RegisterInitialValues> label={t('formFields.houseNumber')} name="houseNumber" />
                  </Grid>
               </Grid>
               <Grid container sx={{ marginBottom: 4 }} justifyContent="space-between" alignItems="center">
                  <Grid item xs={6}>
                     <InputField<RegisterInitialValues>
                        type="number"
                        label={t('formFields.shopEmployees')}
                        name="shopEmployees"
                     />
                  </Grid>
                  <Grid item xs={5}>
                     <InputField<RegisterInitialValues>
                        type="number"
                        label={t('formFields.workshopEmployees')}
                        name="workshopEmployees"
                     />
                  </Grid>
               </Grid>
               <Button
                  onClick={() => {
                     setFieldValue('county', undefined)
                     setFieldValue('voivodeship', undefined)
                     setFieldValue('country', undefined)
                  }}
               >
                  <KeyboardBackspaceIcon sx={{ mr: 2 }} /> {t('REGISTER:mainSite.backToCountrySelect')}
               </Button>
            </>
         ) : (
            <Box
               sx={{
                  display: 'flex',
                  gap: 1,
                  minHeight: 250,
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
               }}
            >
               <Typography variant="h3">{t('REGISTER:mainSite.selectCountry')}</Typography>
               {data &&
                  Object.keys(data).map((key) => (
                     <Button key={key} sx={{ fontSize: 16 }} onClick={() => setFieldValue('country', key)}>
                        {data[key].name}
                     </Button>
                  ))}
            </Box>
         )}
      </Container>
   )
}
