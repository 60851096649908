import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

type Result = {
   status: number
}

export function sendResetEmailService(payload: SendResetEmailPayload) {
   return httpClient
      .post<Result>('users/reset-password', payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((err: Error | AxiosError<BackendError>) => {
         let errorMessage = ''

         if (axios.isAxiosError(err)) {
            errorMessage = err.response?.data?.message || ''
         } else {
            errorMessage = err.message
         }
         consoleMessage('remindPassword sendResetEmailService', errorMessage, 'error')
         throw new Error(errorMessage)
      })
}
