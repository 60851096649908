import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

type Result = {
   status: number
   data: string
}

export default function generateReflinkService(cartUuid: string) {
   let errMessage = ''

   return httpClient
      .post<Result>(`/order/reflink/generate/${cartUuid}`)
      .then((res) => ({
         status: res.status,
         reflink: res.data as unknown as string,
      }))
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || ''
         } else {
            errMessage = err.message
         }
         consoleMessage('MAINCART', errMessage, 'error')
         throw new Error(errMessage)
      })
}
