import { styled } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import TableCell from '@mui/material/TableCell'
import EditIcon from '@mui/icons-material/Edit'
import LockIcon from '@mui/icons-material/Lock'
import Table from '@mui/material/Table'

export const StyledTableCell = styled(TableCell)({
   color: 'grayText',
   textAlign: 'center',
})

export const StyledTable = styled(Table)({
   marginTop: 10,
   WebkitBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   MozBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   boxShadow: ' 0px 0px 10px -4px rgba(66, 68, 90, 1)',
   borderRadius: '10px',
   backgroundColor: 'rgba(255,255,255,0.7)',
   '>*': {
      color: 'grayText',
   },
})

export const StyledContentTableCell = styled(TableCell)({
   padding: '6px 4px 6px 4px',
   textAlign: 'center',
})

export const StyledCollapseTableCell = styled(TableCell)({
   textAlign: 'center',
   color: 'grayText',
})

export const StyledDeleteIcon = styled(DeleteIcon)({
   color: 'grayText',
   '&:hover': {
      cursor: 'pointer',
      color: 'red',
   },
})

export const StyledEditIcon = styled(EditIcon)(({ theme }) => ({
   color: 'grayText',
   '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.light,
   },
}))

export const StyledLockIcon = styled(LockIcon)(({ theme }) => ({
   color: 'grayText',
   '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.light,
   },
}))
