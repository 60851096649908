import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

export async function addToCartService(payload: AddToCartServicePayload, cartUuid: string) {
   return httpClient
      .post(`/carts/${cartUuid}/products`, payload)
      .then((res) => res)
      .catch((err: Error | AxiosError<BackendError>) => {
         let errMessage

         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with addToCartService'
         } else {
            errMessage = err.message
         }
         consoleMessage('addToCartService', errMessage, 'error')
         throw new Error(errMessage)
      })
}
