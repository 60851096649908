import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'

import MissingProducts from './components/MissingProducts'
import getOrderService from './services/getOrderService'
import OrderedTable from './components/OrderedTable'
import { GetOrderResult } from './services/types'
import { pl, en, de } from './locales'

export default function BaselinkerOrderSummary() {
   const [data, setData] = useState<GetOrderResult>()
   const [searchParams] = useSearchParams()
   const { i18n, t } = useTranslation('ORDER_SUMMARY')
   i18n.addResourceBundle('pl-PL', 'ORDER_SUMMARY', pl)
   i18n.addResourceBundle('en-EN', 'ORDER_SUMMARY', en)
   i18n.addResourceBundle('de-DE', 'ORDER_SUMMARY', de)
   //    const [isReporting, setIsReporting] = useState(false)
   //    const [isReported, setIsReported] = useState(false)
   const navigate = useNavigate()

   useEffect(() => {
      const uuid = searchParams.get('uuid')
      if (uuid) {
         getOrderService(uuid).then((res) => {
            if (res.data) {
               setData(res.data)
            }
         })
      } else {
         navigate('/dashboard')
      }
   }, [])

   return data ? (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px', py: 10, width: '100%' }}>
         <Typography variant="h4" color="primary">
            {`${t('summary')} ${data.externalOrderId}`}
         </Typography>
         <OrderedTable
            formattedNetValue={data.formattedNetValue}
            formattedGrossValue={data.formattedGrossValue}
            data={data.product.filter((item) => item.status !== 'PARTIALLY_AVAILABLE')}
         />
         {data.product.some((item) => item.status === 'PARTIALLY_AVAILABLE') && (
            <MissingProducts data={data.product.filter((item) => item.status === 'PARTIALLY_AVAILABLE')} />
         )}
         <Box sx={{ display: 'flex', gap: 2 }}>
            <ContainedButton onClick={() => navigate('/dashboard')} variant="contained">
               {t('backToMain')}
            </ContainedButton>
            {/* {data.product.some((item) => item.status === 'WHOLESALE_CONNECTION_PROBLEM') && !isReported && (
               <ContainedButton
                  onClick={() => handleReportProblem(data.uuid, data.cartUuid, data.product)}
                  sx={{ alignSelf: 'end', mx: '18%' }}
                  variant="contained"
                  disabled={isReporting}
               >
                  {t('reportOrder')}
               </ContainedButton>
            )} */}
         </Box>
      </Box>
   ) : (
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
         <CircularProgress color="primary" />
      </Box>
   )
}
