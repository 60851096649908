import React from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import InputField from 'shared/components/InputField'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useProfileQuery } from 'shared/store/useProfile'

import { CartDetailsType } from '../../types'

interface Props {
   handleClose: () => void
   clientNetValue?: string
   clientGrossValue?: string
}

export default function SaveCartModal({ handleClose, clientNetValue, clientGrossValue }: Props) {
   const { t } = useTranslation('MAIN_CART')
   const { data: profileData } = useProfileQuery()
   const { submitForm } = useFormikContext<CartDetailsType>()

   return (
      <>
         <DialogContent>
            <Box
               onKeyPress={(e) => {
                  if (e.key === 'Enter') submitForm()
               }}
               sx={{ display: 'flex', flexDirection: 'column', gap: 5, margin: 3 }}
            >
               <Box sx={{ display: 'flex', gap: 10 }}>
                  <InputField<CartDetailsType>
                     name="cartName"
                     onFocus={(e) => e.target.select()}
                     fullWidth={false}
                     variant="standard"
                     label={t('MAIN_CART:cartName')}
                  />
                  <TextField
                     variant="standard"
                     inputProps={{ readOnly: true }}
                     label={t('TRANSLATION:columnNames.clientPrice')}
                     defaultValue={profileData?.priceType === 'NET' ? clientNetValue : clientGrossValue}
                  />
               </Box>
               <InputField<CartDetailsType>
                  InputLabelProps={{
                     shrink: true,
                  }}
                  multiline
                  rows={6}
                  name="cartDescription"
                  label={t('MAIN_CART:cartDescription')}
               />
            </Box>
         </DialogContent>
         <DialogActions>
            <OutlinedButton variant="outlined" onClick={handleClose}>
               {t('TRANSLATION:cancel')}
            </OutlinedButton>
            <ContainedButton variant="contained" type="submit" onClick={submitForm}>
               {t('TRANSLATION:save')}
            </ContainedButton>
         </DialogActions>
      </>
   )
}
