import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import { LocationType } from 'shared/store/useLocations/types'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'
import { useTheme } from '@mui/material'

interface Props {
   active?: LocationType
   list?: LocationType[]
   onChangeLocation: (id: string) => void
}

export default function LocationsList({ list = [], onChangeLocation, active }: Props) {
   const { user } = useLogin()
   const theme = useTheme()

   return (
      <Grid item xs={12} md={2}>
         <Box sx={{ mt: 1 }}>
            <Box
               sx={{
                  mt: user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) ? 1.5 : 0,
                  display: 'flex',
                  justifyContent: 'flex-start',
               }}
            >
               {active && (
                  <Tabs sx={{ width: '100%' }} orientation="vertical" value={active.locationSettings.uuid}>
                     {list?.map((location) => (
                        <Tab
                           key={location.locationSettings.uuid}
                           value={location.locationSettings.uuid}
                           disabled={
                              !user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) &&
                              location.locationSettings.adminUuid !== user.uuid
                           }
                           label={location.locationSettings.name}
                           sx={{
                              minWidth: '100%',
                              lineBreak: 'loose',
                              px: 4,
                              color:
                                 location.locationSettings.uuid === active?.locationSettings.uuid ? 'main' : 'grayText',
                              '&:hover': {
                                 color: theme.palette.primary.main,
                                 backgroundColor: '#E4EEF7',
                              },
                           }}
                           onClick={() => onChangeLocation(location.locationSettings.uuid)}
                        />
                     ))}
                  </Tabs>
               )}
            </Box>
         </Box>
      </Grid>
   )
}
