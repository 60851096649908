import { styled } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

export const StyledTableCell = styled(TableCell)({
   color: 'grayText',
   textAlign: 'center',
})

export const StyledTable = styled(Table)({
   marginTop: 10,
   minWidth: 700,
   WebkitBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   MozBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   boxShadow: ' 0px 0px 10px -4px rgba(66, 68, 90, 1)',
   borderRadius: '10px',
   backgroundColor: 'rgba(255,255,255,0.7)',
   '>*': {
      color: 'grayText',
   },
})

export const StyledContentTableCell = styled(TableCell)({
   padding: '6px 4px 6px 4px',
   textAlign: 'center',
})

export const StyledDeleteIcon = styled(DeleteIcon)({
   color: 'grayText',
   '&:hover': {
      cursor: 'pointer',
      color: 'red',
   },
})

export const ProductTableRow = styled(TableRow)({
   background: 'white',
   '&:hover': {
      backgroundColor: 'rgba(28,202,255,0.05)',
      cursor: 'pointer',
   },
})

export const StyledSpan = styled('span')(({ theme }) => ({
   marginLeft: 5,
   color: theme.palette.primary.dark,
   fontSize: 17,
   fontWeight: 'bolder',
}))

export const BoldTypography = styled(Typography)({
   fontWeight: 'bold',
   color: 'grayText',
})

export const StyledAvailabilityDot = styled('div')(({ color }) => ({
   width: 15,
   margin: '0 auto',
   height: 15,
   borderRadius: 50,
   backgroundColor: color,
}))
