import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { changeMessageStatus, fetchMessages } from './services'
import { StateType, ChangeMessageStatusPayload } from './types'

async function getMessages() {
   const request = await fetchMessages().then((response) =>
      response.messages.map((message) => ({
         ...message,
      }))
   )

   return request
}

export function useMessagesQuery(): UseQueryResult<StateType> {
   return useQuery('messages', getMessages, { staleTime: 10 * (60 * 1000) })
}

export function useChangeMessageStatusMutation() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, ChangeMessageStatusPayload, unknown>((payload) => changeMessageStatus(payload), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('messages')
         }
      },
   })
}
