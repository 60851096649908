import * as libphonenumber from 'google-libphonenumber'

export default function validatePhoneNumber(value: string, country: string): boolean {
   const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
   try {
      const parsedNumber = phoneUtil.parse(value, country)
      return phoneUtil.isValidNumber(parsedNumber)
   } catch (error) {
      return false
   }
}
