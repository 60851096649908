import { Product } from 'shared/store/useCartSummary/types'

export default function getSum(products: Product[], priceType: 'price' | 'clientPrice', format: 'string' | 'number') {
   if (priceType === 'price') {
      return format === 'string'
         ? products
              .filter((item) => item.includedProductInOrder && item.status === 'AVAILABLE')
              .reduce((acc, current) => acc + current.cartWholesaleDTO.netPrice * current.cartWholesaleDTO.quantity, 0)
              .toFixed(2)
         : products
              .filter((item) => item.includedProductInOrder && item.status === 'AVAILABLE')
              .reduce((acc, current) => acc + current.cartWholesaleDTO.netPrice * current.cartWholesaleDTO.quantity, 0)
   }
   return format === 'string'
      ? products
           .filter((item) => item.includedProductInOrder && item.status === 'AVAILABLE')
           .reduce(
              (acc, current) => acc + current.cartWholesaleDTO.clientNetPrice * current.cartWholesaleDTO.quantity,
              0
           )
           .toFixed(2)
      : products
           .filter((item) => item.includedProductInOrder && item.status === 'AVAILABLE')
           .reduce(
              (acc, current) => acc + current.cartWholesaleDTO.clientNetPrice * current.cartWholesaleDTO.quantity,
              0
           )
}
