import { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

import { DeleteWatchedProductPayload, FetchWatchedProductsResult } from './types'

export function fetchWatchedProducts(): Promise<FetchWatchedProductsResult> {
   let errMessage = ''

   return httpClient
      .get('products/observed/')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err: Error | AxiosError<BackendError>) => {
         if (isAxiosError<BackendError>(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong in watchlist view'
         } else {
            errMessage = err.message
         }
         consoleMessage('watchlist fetchWatchedProducts', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function removeWatchedProduct(payload: DeleteWatchedProductPayload) {
   let errMessage = ''

   return httpClient
      .delete<Result>(`products/observed/${payload.uuid}`)
      .then((res) => ({ status: res.status }))
      .catch((err: Error | AxiosError<BackendError>) => {
         if (isAxiosError<BackendError>(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong in watchlist view'
         } else {
            errMessage = err.message
         }
         consoleMessage('watchlist removeWatchedProduct', errMessage, 'error')
         throw new Error(errMessage)
      })
}
