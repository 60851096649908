import axios, { AxiosError } from 'axios'
import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import { FetchErpProductType, GetWholesalesType, WholesaleStateType } from './types'

export function fetchErpData(searchPhrase: string) {
   return httpClient
      .get<FetchErpProductType>(`products/erp_search?searchPhrase=${searchPhrase}`)
      .then((response) => {
         if (response.data) {
            return response.data
         }
         throw new Error('noData')
      })
      .catch((err: Error | AxiosError<BackendError>) => {
         let errMessage
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with fetchErpData()'
         } else {
            errMessage = err.message
         }
         consoleMessage('fetchErpData()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function getWholesalesService(productUuid: string): Promise<WholesaleStateType> {
   return httpClient
      .get<GetWholesalesType>(`products/wholesalePrices?productUUID=${productUuid}`)
      .then((response) => {
         if (response.data) {
            return { uuid: productUuid, wholesales: [...response.data] }
         }
         throw new Error('noData')
      })
      .catch((err: Error | AxiosError<BackendError>) => {
         let errMessage
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with getWholesalesService()'
         } else {
            errMessage = err.message
         }
         consoleMessage('getWholesalesService()', errMessage, 'error')
         throw new Error(errMessage)
      })
}
