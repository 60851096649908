import { AxiosError } from 'axios'
import { unauthHttpClient } from 'shared/lib'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

export function validateSmsCodeService(reflinkUuid: string, smsCode: string) {
   let errMessage = ''
   return unauthHttpClient
      .post<string>(`/client-carts/reflinks/${reflinkUuid}/verification-codes/${smsCode}`)
      .then((res) => ({ status: res.status, data: res.data }))
      .catch((err: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(err)) {
            errMessage = err.response?.data.errors[0].defaultMessage || 'Something went wrong with checkTokenService'
         } else {
            errMessage = err.message
         }
         throw new Error(errMessage)
      })
}
