import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { useNotificationContext } from 'shared/context/Notifications'

import { deleteNotification, fetchNotifications, readNotification } from './services'
import { NotificationActionsPayload, StateType } from './types'

async function getNotifications() {
   const request = await fetchNotifications().then((response) =>
      response.notifications.map((notification) => ({
         ...notification,
      }))
   )

   return request
}

export function useNotificationsQuery(): UseQueryResult<StateType> {
   return useQuery('notifications', getNotifications, { refetchOnWindowFocus: 'always' })
}

export function useRemoveNotification() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()
   return useMutation<Result, unknown, NotificationActionsPayload, unknown>((payload) => deleteNotification(payload), {
      onSuccess: (data) => {
         if (data.status === 200) {
            queryClient.invalidateQueries('notifications')
         }
      },
      onSettled: (data, error) => {
         if (data?.status === 200) {
            addNotification('successSave', 'success')
         } else if (error) {
            addNotification('failedSave', 'error')
         }
      },
   })
}

export function useReadNotification() {
   const queryClient = useQueryClient()

   return useMutation<Result, unknown, NotificationActionsPayload, unknown>((payload) => readNotification(payload), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('notifications')
         }
      },
   })
}
