import axios, { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import { httpClient } from 'shared/lib'

import { FetchProductsPayload, FetchProductsResult, Result, SendDuplicatesPayload } from './types'

export function fetchProducts(payload: FetchProductsPayload): Promise<FetchProductsResult> {
   return httpClient
      .post('products/search', {
         ...payload,
      })
      .then((res) => res.data)
      .catch((err: AxiosError<BackendError>) => {
         consoleMessage('PRODUCTS_LIST', err.response?.data.message || 'PROBLEM WITH PRODUCTS LIST SEARCH', 'error')
         throw new Error('apiErrors.500')
      })
}

export function sendDuplicates(payload: SendDuplicatesPayload) {
   return httpClient
      .post<Result>('duplicates/add', payload)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         consoleMessage('productsList sendDuplicates()', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}

export async function getSubstitutesService(productUuid: string): Promise<FetchProductsResult> {
   return httpClient
      .get(`/products/replacements/${productUuid}`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((err: Error | AxiosError<BackendError>) => {
         let errMessage
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with getSubstitutesService()'
         } else {
            errMessage = err.message
         }
         consoleMessage('getSubstitutesService()', errMessage, 'error')
         throw new Error(errMessage)
      })
}
