import React, { useEffect, useState } from 'react'
import TableCell from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'

import { Product } from 'shared/store/useCartSummary/types'
import { useNotificationContext } from 'shared/context/Notifications'
import { MAX_PRODUCT_QUANTITY } from 'shared/consts'

import {
   QuantityHolder,
   StyledDeleteIcon,
   StyledQuantityButton,
   StyledTableCell,
   StyledTableRow,
   StyledInput,
} from '../../../styles'
import { ClientViewPricesType, ModalInfoType } from '../../../types'
import getProductName from '../../../helpers/getProductName'

interface Props {
   products: Product[]
   setModalInfo: (modalInfo: ModalInfoType) => void
   handleProductUpdate: (
      uuid: string,
      quantity?: number,
      includeProductInOrder?: boolean,
      netClientPrice?: number
   ) => void
   isProductUpdating: boolean
}

export default function TableForClientDesktop({
   products,
   setModalInfo,
   handleProductUpdate,
   isProductUpdating,
}: Props) {
   const [clientPrices, setClientPrices] = useState<ClientViewPricesType>([])
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const { addNotification } = useNotificationContext()

   function changeClientPrice(clientPrice: number, uuid: string, productPrice: number, taxRate: number) {
      const tempClientPrice = clientPrice <= productPrice ? productPrice + 0.01 : clientPrice
      if (clientPrice <= productPrice + 0.01) {
         addNotification(t('MAIN_CART:clientPriceError'), 'error')
         changeStateClientPrice(productPrice, uuid, taxRate)
      } else {
         handleProductUpdate(uuid, undefined, undefined, tempClientPrice)
      }
   }

   function changeStateClientPrice(newClientPrice: number, uuid: string, taxRate: number) {
      setClientPrices((prevState) => {
         const newState = prevState.map((product) => {
            if (product.uuid === uuid) {
               return {
                  ...product,
                  clientPriceNet: Number(newClientPrice.toFixed(2)),
                  clientPriceGross: Number((newClientPrice * (1 + taxRate / 100)).toFixed(2)),
               }
            }
            return product
         })

         return newState
      })
   }

   useEffect(() => {
      const tempClientPrices: ClientViewPricesType = []
      products.forEach((product) => {
         tempClientPrices.push({
            uuid: product.uuid,
            clientPriceNet: Number(product.cartWholesaleDTO.clientNetPrice),
            clientPriceGross: Number(product.cartWholesaleDTO.clientGrossPrice),
         })
      })
      setClientPrices([...tempClientPrices])
   }, [products])

   return products.length ? (
      <>
         {products
            .filter((item) => item.status !== 'NOT_AVAILABLE')
            .map((product) => (
               <StyledTableRow key={product.uuid}>
                  <Tooltip title={product.cartProductName || product.index}>
                     <StyledTableCell>{getProductName(product)}</StyledTableCell>
                  </Tooltip>
                  <StyledTableCell>
                     <img
                        title={product.producerName}
                        height="20px"
                        width="auto"
                        src={`https://files.motorro.eu/img?name=${product.producerNameWithoutSpecialChars.toLowerCase()}`}
                        alt={product.producerName}
                     />
                  </StyledTableCell>
                  <StyledTableCell>
                     <QuantityHolder>
                        <StyledQuantityButton
                           type="button"
                           disabled={isProductUpdating || product.cartWholesaleDTO.quantity === 0}
                           onClick={() =>
                              handleProductUpdate(
                                 product.uuid,
                                 product.cartWholesaleDTO.quantity - product.quantityInSet
                              )
                           }
                        >
                           -
                        </StyledQuantityButton>
                        <StyledQuantityButton
                           type="button"
                           disabled={isProductUpdating}
                           onClick={() =>
                              setModalInfo({
                                 modalType: 'QUANTITY',
                                 uuid: product.uuid,
                                 index: product.index,
                              })
                           }
                           style={{ fontWeight: 'normal' }}
                        >
                           {product.cartWholesaleDTO.quantity}
                        </StyledQuantityButton>
                        <StyledQuantityButton
                           type="button"
                           disabled={isProductUpdating || product.cartWholesaleDTO.quantity === MAX_PRODUCT_QUANTITY}
                           onClick={() =>
                              handleProductUpdate(
                                 product.uuid,
                                 product.cartWholesaleDTO.quantity + product.quantityInSet
                              )
                           }
                        >
                           +
                        </StyledQuantityButton>
                     </QuantityHolder>
                     {product.cartWholesaleDTO.availableQuantity <= 5 &&
                     product.cartWholesaleDTO.availableQuantity > 1 ? (
                        <p
                           style={{
                              marginBottom: 0,
                              marginTop: 0,
                              opacity: 0.5,
                              color: 'GrayText',
                              fontSize: 12,
                              fontWeight: 'lighter',
                           }}
                        >{`${t('MAIN_CART:available')} ${product.cartWholesaleDTO.availableQuantity}`}</p>
                     ) : null}
                  </StyledTableCell>
                  <StyledTableCell>
                     <Tooltip title={t('MAIN_CART:clickToEdit') || ''}>
                        <StyledInput
                           onBlur={(e) =>
                              changeClientPrice(
                                 Number(e.target.value),
                                 product.uuid,
                                 product.cartWholesaleDTO.netPrice,
                                 product.cartWholesaleDTO.taxRate
                              )
                           }
                           onFocus={(e) => e.target.select()}
                           disabled={isProductUpdating}
                           type="number"
                           min={product.cartWholesaleDTO.netPrice + 0.01}
                           onChange={(e) =>
                              changeStateClientPrice(
                                 Number(e.target.value),
                                 product.uuid,
                                 product.cartWholesaleDTO.taxRate
                              )
                           }
                           value={clientPrices.find((item) => item.uuid === product.uuid)?.clientPriceNet}
                        />
                     </Tooltip>
                     {product.cartWholesaleDTO.preferredCurrency}
                  </StyledTableCell>
                  <StyledTableCell>{product.cartWholesaleDTO.taxRate}%</StyledTableCell>
                  <StyledTableCell>
                     <Tooltip title={t('MAIN_CART:clickToEdit') || ''}>
                        <StyledInput
                           onBlur={(e) =>
                              changeClientPrice(
                                 Number(e.target.value) / (1 + product.cartWholesaleDTO.taxRate / 100),
                                 product.uuid,
                                 product.cartWholesaleDTO.netPrice * (1 + product.cartWholesaleDTO.taxRate / 100),
                                 product.cartWholesaleDTO.taxRate
                              )
                           }
                           type="number"
                           min={product.cartWholesaleDTO.grossPrice + 0.01}
                           onFocus={(e) => e.target.select()}
                           onChange={(e) =>
                              changeStateClientPrice(
                                 Number(e.target.value) / (1 + product.cartWholesaleDTO.taxRate / 100),
                                 product.uuid,
                                 product.cartWholesaleDTO.taxRate
                              )
                           }
                           disabled={isProductUpdating}
                           value={clientPrices.find((item) => item.uuid === product.uuid)?.clientPriceGross}
                        />
                     </Tooltip>
                     {product.cartWholesaleDTO.preferredCurrency}
                  </StyledTableCell>
                  <StyledTableCell>{product.cartWholesaleDTO.formattedClientGrossValue}</StyledTableCell>
                  <StyledTableCell>
                     <Checkbox
                        disabled={isProductUpdating || product.cartWholesaleDTO.quantity === 0}
                        onClick={() => handleProductUpdate(product.uuid, undefined, !product.includedProductInOrder)}
                        color="primary"
                        checked={product.includedProductInOrder}
                     />
                  </StyledTableCell>
                  <StyledTableCell>
                     <StyledDeleteIcon
                        onClick={() =>
                           setModalInfo({
                              modalType: 'DELETE',
                              uuid: product.uuid,
                              index: product.index,
                           })
                        }
                     />
                  </StyledTableCell>
               </StyledTableRow>
            ))}
      </>
   ) : (
      <TableRow>
         <TableCell colSpan={11}>
            <Box
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  opacity: 0.6,
                  fontWeight: 'bold',
                  color: 'grayText',
               }}
            >
               {t('MAIN_CART:noProducts')}
            </Box>
         </TableCell>
      </TableRow>
   )
}
