import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useNavigate, createSearchParams } from 'react-router-dom'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useSetActiveCartMutation } from 'shared/store/useSavedCarts'

import { NotificationInfo, ParsedMessageType } from '../../types'

interface Props {
   type: PropType<NotificationInfo, 'type'>
   message: string
   cartUuid: string
   handleClose: () => void
}

export default function CsvSummary({ type, message, cartUuid, handleClose }: Props) {
   const { t } = useTranslation('NOTIFICATIONS')
   const [parsedMessage, setParsedMessage] = useState<ParsedMessageType>()
   const [notFoundIndexes, setNotFoundIndexes] = useState<string[]>([])
   const { mutate } = useSetActiveCartMutation()
   const navigate = useNavigate()

   function handleClick() {
      mutate(cartUuid, {
         onSuccess: () => {
            navigate('/dashboard/main-cart')
         },
      })
   }

   function handleSearch() {
      navigate(
         {
            pathname: '/dashboard/search',
            search: `?${createSearchParams({
               value: notFoundIndexes[0],
               searchType: 'INDEX',
            })}`,
         },
         {
            state: { indexes: notFoundIndexes, activeIndex: 0 },
         }
      )
   }

   useEffect(() => {
      if (message) {
         setParsedMessage(JSON.parse(message))
      }
   }, [message])

   useEffect(() => {
      if (parsedMessage) {
         const tempNotFoundIndexes = [
            ...parsedMessage.notFoundIndexes.split(','),
            ...parsedMessage.rejectedIndexes.split(','),
         ]
         setNotFoundIndexes(tempNotFoundIndexes.filter((item) => item))
      }
   }, [parsedMessage])

   return (
      <div style={{ textAlign: 'center' }}>
         {type === 'AUTO_CART_CREATED' ? (
            <Typography variant="h5">{t('cartCreatedText')}</Typography>
         ) : (
            <Typography variant="h5">{t('cartCreatedFailedText')}</Typography>
         )}
         {type === 'AUTO_CART_CREATED' && (
            <Typography
               sx={{ mt: 0.5, '&:hover': { cursor: 'pointer', color: 'primary.dark' } }}
               onClick={handleClick}
               color="primary"
               variant="h5"
            >
               {t('goToCart')}
            </Typography>
         )}
         {parsedMessage && (parsedMessage.notFoundIndexes || parsedMessage.rejectedIndexes) ? (
            <Box>
               <Typography variant="h6" sx={{ mt: 2 }}>
                  {t('notFoundProducts', {
                     rejectedCount: parsedMessage.numberOfRejectedIndexes,
                     notFoundCount: parsedMessage.numberOfNotFoundIndexes,
                  })}
               </Typography>
               <Typography
                  sx={{ '&:hover': { cursor: 'pointer', color: 'primary.dark' } }}
                  onClick={handleSearch}
                  color="primary"
                  variant="h5"
               >
                  {t('notFoundProductsSearch')}
               </Typography>
               <Box sx={{ maxHeight: 250, overflowX: 'scroll', my: 3 }}>
                  {parsedMessage.notFoundIndexes &&
                     parsedMessage.notFoundIndexes?.split(',').map((item) => (
                        <Typography key={item} variant="h6">
                           {`${item} ${t('notFoundIndex')}`}
                        </Typography>
                     ))}
                  {parsedMessage.rejectedIndexes &&
                     parsedMessage.rejectedIndexes?.split(',').map((item) => (
                        <Typography key={item} variant="h6">
                           {`${item} ${t('rejectedIndex')}`}
                        </Typography>
                     ))}
               </Box>
            </Box>
         ) : null}
         <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <ContainedButton sx={{ mt: 2 }} variant="contained" onClick={handleClose}>
               OK
            </ContainedButton>
         </Box>
      </div>
   )
}
