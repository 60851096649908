import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import { Result, GetSubscriptionInfoResult, EditUserPayload, ChangePasswordPayload, CreateUserPayload } from '../types'

export function removeUserService(userUuid: string) {
   let errMessage = ''
   return httpClient
      .delete<Result>(`companies/users/${userUuid}`)
      .then((res) => ({ status: res.status }))
      .catch((err: Error | AxiosError<ErrorType>) => {
         if (axios.isAxiosError(err)) {
            if (err.response?.status === 403) {
               errMessage = 'userRemoveForbidden'
            } else {
               errMessage =
                  err.response?.data?.errors[0].defaultMessage || 'Something went wrong with removeUserService'
            }
         } else {
            errMessage = err.message
         }
         consoleMessage('usersAndSubscriptions removeUserService', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function getSubscriptionEndDateService() {
   let errMessage = ''
   return httpClient
      .get<GetSubscriptionInfoResult>('subscription/info')
      .then((res) => {
         return res.data
      })
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with getSubscriptionEndTimeService'
         } else {
            errMessage = err.message
         }
         consoleMessage('usersAndSubscriptions getSubscriptionEndTimeService', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function editUserService(payload: EditUserPayload, userUuid: string) {
   let errMessage = ''
   return httpClient
      .put<Result>(`companies/users/${userUuid}`, payload)
      .then((res) => ({ status: res.status }))
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with editUserService'
         } else {
            errMessage = err.message
         }
         consoleMessage('usersAndSubscriptions editUserService', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function changeUserPasswordService(payload: ChangePasswordPayload, userUuid: string) {
   let errMessage = ''
   return httpClient
      .put<Result>(`companies/users/${userUuid}/change-password`, payload)
      .then((res) => ({ status: res.status }))
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with changePasswordService'
         } else {
            errMessage = err.message
         }
         consoleMessage('usersAndSubscriptions changePasswordService', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function addUserService(payload: CreateUserPayload) {
   let errMessage = ''
   return httpClient
      .post<Result>('companies/users', payload)
      .then((res) => ({ status: res.status }))
      .catch((err: ErrorType | AxiosError<ErrorType>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data.errors[0].defaultMessage || 'Something went wrong with addUserService'
         } else {
            errMessage = err.errors[0].defaultMessage
         }
         consoleMessage('usersAndSubscriptions addUserService', errMessage, 'error')
         throw new Error(errMessage)
      })
}
