import { httpClient } from 'shared/lib'

import { StatisticsType } from './types'

export function fetchStatisticsData(): Promise<StatisticsType> {
   return httpClient
      .get('user/statistics/')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch(() => {
         throw new Error('apiErorrs.500')
      })
}
