import FormControl from '@mui/material/FormControl'
import React, { useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import { useQueryClient } from 'react-query'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'

import { useNotificationContext } from 'shared/context/Notifications'
import getCompanyUsersService from 'shared/services/getCompanyUsers'
import { LocationType } from 'shared/store/useLocations/types'
import { httpClient } from 'shared/lib'

interface Props {
   mainLocationUuid: string
   values?: LocationType
}
export default function AdminDetailsForm({ mainLocationUuid, values }: Props) {
   const { t } = useTranslation(['LOCATIONS', 'TRANSLATION'])
   const [adminOptions, setAdminOptions] = useState<OptionType[]>([])
   const queryClient = useQueryClient()
   const [locationAdminUuid, setLocationAdminUuid] = useState(values?.locationSettings.adminUuid)
   const { addNotification } = useNotificationContext()

   function handleAdminChange(newAdminUuid: string) {
      if (values) {
         httpClient
            .post(`locations/${values.locationSettings.uuid}/admin/${newAdminUuid}`)
            .then((res) => {
               if (res.status === 204) {
                  addNotification(t('LOCATIONS:successAdminChange'), 'success')
                  queryClient.invalidateQueries('locations')
               }
            })
            .catch((err) => {
               if (err) {
                  addNotification(t('TRANSLATION:failedSave'), 'error')
               }
            })
      }
   }

   useEffect(() => {
      getCompanyUsersService(false).then((res) => {
         if (res.status === 200) {
            const tempUsers = res.data.map((user) => ({
               name: `${user.name} ${user.surname}`,
               value: user.uuid,
            }))
            setAdminOptions([...tempUsers])
         }
      })
   }, [])

   useEffect(() => {
      if (values?.locationSettings.adminUuid) {
         setLocationAdminUuid(values.locationSettings.adminUuid)
      }
   }, [values?.locationSettings.uuid])

   return (
      <Stack direction="column" sx={{ mt: 3 }} spacing={{ xs: 1, md: 3 }}>
         {values?.locationSettings.uuid !== mainLocationUuid && (
            <FormControl component="fieldset" variant="outlined">
               <InputLabel id="admin-select-label">{t('LOCATIONS:newLocationAdminSelect')} </InputLabel>
               <Select
                  labelId="admin-select-label"
                  name="adminUuid"
                  label={t('LOCATIONS:newLocationAdminSelect')}
                  value={locationAdminUuid}
                  required
                  onChange={(e) => handleAdminChange(e.target.value)}
               >
                  {adminOptions.map((option) => (
                     <MenuItem key={option.value} value={option.value}>
                        {option.name}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         )}

         <TextField
            label={t('TRANSLATION:formFields.name')}
            fullWidth
            disabled
            value={values?.locationSettings.admin.userName.split(' ')[0]}
         />
         <TextField
            fullWidth
            disabled
            value={values?.locationSettings.admin.userName.split(' ')[1]}
            label={t('TRANSLATION:formFields.surname')}
         />
         <TextField
            fullWidth
            label={t('TRANSLATION:formFields.phoneNumber')}
            disabled
            value={values?.locationSettings.admin.phoneNumber}
         />
         <TextField
            label={t('TRANSLATION:formFields.email')}
            fullWidth
            disabled
            value={values?.locationSettings.admin.email}
         />
      </Stack>
   )
}
