import axios, { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import { unauthHttpClient } from 'shared/lib'

import { ChangeQuantityPayload } from 'shared/store/useCartSummary/types'
import {
   CheckTokenResult,
   FetchReflinkDetailsType,
   VerifySMSCodeResult,
   EndpointsPayload,
   FetchOrderReflinkDetails,
   IncludeProductInOrderPayload,
} from '../types'

export function checkTokenService(token: string) {
   let errMessage = ''
   return unauthHttpClient
      .post<CheckTokenResult>(`/order/reflink/validate/${token}`)
      .then((res) => ({ status: res.status, valid: res.data.valid }))
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with checkTokenService'
         } else {
            errMessage = err.message
         }
         consoleMessage('orderReflink checkTokenService', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function getReflinkDetails(token: string): Promise<FetchReflinkDetailsType> {
   return unauthHttpClient
      .get(`order/reflink/?token=${token}`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err) => {
         consoleMessage('orderReflink fetchUserReflinkDetails()', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}

export function veryfiSMSCodeService(smsCode: string): Promise<VerifySMSCodeResult> {
   let errMessage
   return unauthHttpClient
      .post('sms/verify', { smsCode })
      .then((res) => ({ valid: res.data.valid }))
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with VeryfiSMSCodeService'
         } else {
            errMessage = err.message
         }
         consoleMessage('orderReflink VeryfiSMSCodeService', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function fetchCartSummary(payload: EndpointsPayload): Promise<FetchOrderReflinkDetails> {
   return unauthHttpClient
      .post('order/reflink/getCartSummary?actual=true', payload)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err) => {
         consoleMessage('orderReflink fetchCartSummary()', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}

export function changeQuantity(payload: ChangeQuantityPayload) {
   let errMessage

   return unauthHttpClient
      .patch<Result>('order/reflink/product/changeQuantity', payload)
      .then((res) => ({ status: res.status }))
      .catch((err: AxiosError<BackendError> | Error) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong in changeQuantity()'
         } else {
            errMessage = err.message
         }
         consoleMessage('orderReflink changeQuantity()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function changeIncludeInOrder(payload: IncludeProductInOrderPayload) {
   let errMessage

   return unauthHttpClient
      .patch<Result>('order/reflink/product/includeProductInOrder', payload)
      .then((res) => ({ status: res.status }))
      .catch((err: AxiosError<BackendError> | Error) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong in changeIncludeInOrder()'
         } else {
            errMessage = err.message
         }
         consoleMessage('orderReflink changeIncludeInOrder()', errMessage, 'error')
         throw new Error(errMessage)
      })
}
