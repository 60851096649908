import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'

import consoleMessage from '../utils/helpers/consoleMessage'

export async function reFetchWholesalerData(productId: string, wholesalerId: string) {
   return httpClient
      .get<CooperatingWholesaler>(`products/${productId}/wholesales/${wholesalerId}/online-availability`)
      .then((res) => res.data)
      .catch((err: Error | AxiosError<BackendError>) => {
         let errMessage

         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with updatePrices'
         } else {
            errMessage = err.message
         }
         consoleMessage('updatePrices', errMessage, 'error')
         throw new Error(errMessage)
      })
}
