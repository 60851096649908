import React from 'react'

import { styled } from '@mui/material'

function translateDeliveryTime(minutes: number) {
   switch (true) {
      case minutes === 0:
         return <RedHour>0 min</RedHour>
      case minutes < 5:
         return <RedHour>{`< 5 min`}</RedHour>
      case minutes >= 60:
         // eslint-disable-next-line no-case-declarations
         const test = Math.floor(minutes / 60)
         return <GreenHour>{`> ${test} h`}</GreenHour>
      default:
         return <YellowHour>{`${minutes} min`}</YellowHour>
   }
}

export default function DeliveryTime(minutes: number) {
   if (minutes > 0) {
      return translateDeliveryTime(minutes)
   }
   return null
}

const HourDiv = styled('div')({
   margin: '0 auto',
   padding: 3,
   maxWidth: '90px',
   borderRadius: 5,
   textAlign: 'center',
   fontWeight: 'bold',
})

export const GreenHour = styled(HourDiv)({
   backgroundColor: 'rgba(89, 255, 0, 0.39)',
   color: 'green',
})

export const YellowHour = styled(HourDiv)({
   backgroundColor: 'rgba(255, 255, 0, 0.23)',
   color: 'orange',
})

export const RedHour = styled(HourDiv)({
   backgroundColor: 'rgba(255, 0, 0, 0.29)',
   color: 'red',
})
