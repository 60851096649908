import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'

import { UnAuthorizedVersion as LanguagePopover } from 'shared/components/LanguagePopover'

import { checkTokenService, getReflinkDetails } from './services'
import OrderReflinkContent from './components/OrderReflinkContent'
import { FetchReflinkDetailsType } from './types'
import { BoldTypography } from './styles'
import { pl, en, de } from './locales'

export default function OrderReflink() {
   const [params] = useSearchParams()
   const [error, setError] = useState('')
   const [data, setData] = useState<FetchReflinkDetailsType>()
   const [image, setImage] = useState<string>()
   const token = params.get('token')
   const navigate = useNavigate()
   const { i18n, t } = useTranslation(['TRANSLATION', 'ORDER_REFLINK'])
   i18n.addResourceBundle('pl-PL', 'ORDER_REFLINK', pl)
   i18n.addResourceBundle('en-EN', 'ORDER_REFLINK', en)
   i18n.addResourceBundle('de-DE', 'ORDER_REFLINK', de)

   function renderContent() {
      if (error === 'invalid') {
         return (
            <Typography sx={{ margin: '0 auto', textTransform: 'uppercase' }} variant="h5" color="red">
               {t('ORDER_REFLINK:reflinkExpired')}
            </Typography>
         )
      }
      if (error === 'timeout') {
         return (
            <Typography sx={{ margin: '0 auto', textTransform: 'uppercase' }} variant="h5" color="red">
               {t('ORDER_REFLINK:connectionProblem')}
            </Typography>
         )
      }
      return token && <OrderReflinkContent token={token} />
   }

   useEffect(() => {
      if (token) {
         checkTokenService(token)
            .then((res) => {
               if (!res.valid) {
                  setError('invalid')
               }
               getReflinkDetails(token).then((reflinkSettings) => {
                  if (reflinkSettings) {
                     setData(reflinkSettings)
                     setImage(reflinkSettings.image)
                  }
               })
            })
            .catch((err) => {
               if (err.status === 504) {
                  setError('timeout')
               }
            })
      } else navigate('/')
   }, [token])

   return (
      <>
         <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: { md: 10, xs: 3 }, py: 1 }}>
            <LanguagePopover />
         </Box>
         {data ? (
            <Card
               sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: { md: 5, xs: 4 },
                  margin: { md: '1% auto' },
                  pb: 10,
                  height: '80vh',
                  width: { md: '70%' },
                  overflowY: 'scroll',
               }}
            >
               <Box
                  sx={{
                     display: 'flex',
                     flexDirection: { md: 'row', xs: 'column-reverse' },
                     justifyContent: { md: 'space-between', xs: 'normal' },
                     gap: { xs: 3 },
                     padding: 4,
                     width: '100%',
                  }}
               >
                  {data ? (
                     <>
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'column',
                           }}
                        >
                           <BoldTypography variant="body1">{data.reflinkSettings.companyName}</BoldTypography>
                           {data.reflinkSettings.address && (
                              <BoldTypography>{data.reflinkSettings.address}</BoldTypography>
                           )}
                           {data.reflinkSettings.phoneNumber && (
                              <BoldTypography>{`Tel. ${data.reflinkSettings.phoneNumber}`}</BoldTypography>
                           )}
                        </Box>
                        {image && (
                           <img
                              style={{ alignSelf: 'center' }}
                              height="70px"
                              width="auto"
                              src={`data:image/png;base64,${image}`}
                              alt="company_logo"
                           />
                        )}
                     </>
                  ) : (
                     <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '100%' }}>
                        <CircularProgress color="primary" />
                     </Box>
                  )}
               </Box>
               {renderContent()}
            </Card>
         ) : (
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
               <CircularProgress />
            </Box>
         )}
      </>
   )
}
