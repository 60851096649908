import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import { OrderDetailsType, Result, RatingPayload, ReportType } from './types'

export function getOrderDetailsService(orderUuids: string[]) {
   let errMessage = ''
   return httpClient
      .post<OrderDetailsType>('orders/search', { orderUuids })
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with getOrderDetailsService()'
         } else {
            errMessage = err.message
         }
         consoleMessage('ORDERS_HISTORY getOrderDetailsService()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function addRatingService(payload: RatingPayload) {
   return httpClient
      .post<Result>('ratings/addRating', payload)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         consoleMessage('ORDERS_HISTORY addRating()', err.response?.data?.message, 'error')
         throw new Error('apiErrors.500')
      })
}

export function getCsvOrderReportService(orderUuids: string[]) {
   return httpClient
      .post<ReportType>('reports/orders', { orderUuids })
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch(() => {
         throw new Error('apiErrors.500')
      })
}
