import { useNavigate, useSearchParams } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import { useTranslation } from 'react-i18next'
import Card from '@mui/material/Card'

import { UnAuthorizedVersion as LanguagePopover } from 'shared/components/LanguagePopover'
import MuiPhone from 'shared/components/PhoneInput'
import validatePhoneNumber from 'shared/utils/helpers/validatePhoneNumber'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'

import { validatePhoneNumberService } from './services/validatePhoneNumberService'
import { validateSmsCodeService } from './services/validateSmsCodeService'
import { getReflinkDataService } from './services/getReflinkDataService'
import { ReflinkDataType, SearchResponseType } from './types'
import MainTable from './components/MainTable'
import { getSearchDataService } from './services/getSearchDataService'
import SearchModal from './components/SearchModal'
import { de, en, pl } from './locales'
import { confirmOrderService } from './services/confirmOrderService'
import AddressData from './components/AddressData'
import { BoldTypography } from './styles'

export default function ClientCartReflink() {
   const [data, setData] = useState<ReflinkDataType>()
   const [searchParams] = useSearchParams()
   const { t, i18n } = useTranslation(['TRANSLATION', 'CLIENT_CART'])
   i18n.addResourceBundle('pl-PL', 'CLIENT_CART', pl)
   i18n.addResourceBundle('en-EN', 'CLIENT_CART', en)
   i18n.addResourceBundle('de-DE', 'CLIENT_CART', de)
   const [phoneNumberValid, setPhoneNumberValid] = useState(false)
   const [smsValid, setSmsValid] = useState(false)
   const [phoneNumber, setPhoneNumber] = useState('')
   const [isAddressValid, setIsAddressValid] = useState(false)
   const [smsCode, setSmsCode] = useState('')
   const [searchValue, setSearchValue] = useState('')
   const [searchResponse, setSearchResponse] = useState<SearchResponseType>()
   const [isLoading, setIsLoading] = useState(false)
   const navigate = useNavigate()
   const { addNotification } = useNotificationContext()
   const [phoneNumberError, setPhoneNumberError] = useState(false)

   function handlePhoneNumber(value: string, country: string) {
      setPhoneNumber(value)
      const result = validatePhoneNumber(value, country)
      if (result === false) {
         setPhoneNumberError(true)
      } else {
         setPhoneNumberError(false)
      }
   }

   function submitPhoneNumber() {
      if (phoneNumber) {
         const uuid = window.localStorage.getItem('uuid')
         if (uuid) {
            setIsLoading(true)
            validatePhoneNumberService(uuid, phoneNumber)
               .then((res) => {
                  if (res.status === 204) {
                     addNotification(t('CLIENT_CART:verified'), 'success')
                     setPhoneNumberValid(true)
                  }
               })
               .catch((err) => {
                  if (err.message === 'Reflink verification code generation failed!') {
                     addNotification(t('CLIENT_CART:phoneNumberVerifyFailed'), 'error')
                  }
               })
               .finally(() => setIsLoading(false))
         }
      }
   }

   function submitSmsCode() {
      if (smsCode) {
         const token = window.localStorage.getItem('uuid')
         if (token) {
            setIsLoading(true)
            validateSmsCodeService(token, smsCode)
               .then((res) => {
                  if (res.status === 200) {
                     window.localStorage.setItem('reflink-verify-token', res.data)
                     addNotification(t('CLIENT_CART:verified'), 'success')
                     setSmsValid(true)
                  }
               })
               .catch((err) => {
                  if (err.message === 'Client cart reflink verification failed!') {
                     addNotification(t('CLIENT_CART:smsFailed'), 'error')
                  }
               })
               .finally(() => setIsLoading(false))
         }
      }
   }

   function handleOnSearch() {
      const uuid = window.localStorage.getItem('uuid')
      const token = window.localStorage.getItem('reflink-verify-token')
      setIsLoading(true)
      if (uuid && token) {
         getSearchDataService(uuid, token, searchValue)
            .then((res) => {
               if (res.data.products.length > 0) {
                  setSearchResponse(res.data)
               } else {
                  addNotification(t('CLIENT_CART:notFoundProduct'), 'error')
               }
            })
            .catch((err) => {
               if (err.message === 'Reflink verification code expired!') {
                  addNotification(t('CLIENT_CART:expired'), 'error')
                  setSmsValid(false)
                  setPhoneNumberValid(false)
                  window.localStorage.removeItem('reflink-verify-token')
               } else {
                  addNotification(t('CLIENT_CART:searchFailed'), 'error')
               }
            })
            .finally(() => setIsLoading(false))
      }
   }

   function handleOrderConfirm() {
      const uuid = window.localStorage.getItem('uuid')
      const token = window.localStorage.getItem('reflink-verify-token')
      setIsLoading(true)
      if (uuid && token) {
         confirmOrderService(uuid, token)
            .then((res) => {
               if (res.status === 204) {
                  setTimeout(() => {
                     window.localStorage.removeItem('uuid')
                     window.localStorage.removeItem('reflink-verify-token')
                     navigate('/order-reflink-complete')
                  }, 500)
               }
            })
            .catch((err) => {
               if (err.status === 500) {
                  addNotification(t('CLIENT_CART:orderFailed'), 'error')
               }
            })
            .finally(() => setIsLoading(false))
      }
   }

   function renderCart() {
      const token = window.localStorage.getItem('reflink-verify-token')
      const uuid = window.localStorage.getItem('uuid')
      if (token && uuid) {
         if (data) {
            return (
               <Card
                  sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     gap: 1,
                     p: 2,
                     width: '70%',
                     px: 15,
                     margin: '0 auto',
                     alignItems: 'center',
                  }}
               >
                  <Box
                     sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        gap: 1,
                        mb: 1,
                        width: '100%',
                     }}
                  >
                     {data.companyReflinkAdditionalDetails.logo && (
                        <img
                           height="70px"
                           width="auto"
                           src={`data:image/png;base64,${data.companyReflinkAdditionalDetails.logo}`}
                           alt="company_logo"
                        />
                     )}
                     <BoldTypography variant="body1">{data.companyReflinkAdditionalDetails.companyName}</BoldTypography>
                     {data.companyReflinkAdditionalDetails.address && (
                        <BoldTypography>{data.companyReflinkAdditionalDetails.address}</BoldTypography>
                     )}
                     {data.companyReflinkAdditionalDetails.phoneNumber && (
                        <BoldTypography>{`Tel. ${data.companyReflinkAdditionalDetails.phoneNumber}`}</BoldTypography>
                     )}
                  </Box>
                  <OutlinedInput
                     fullWidth
                     onChange={(e) => setSearchValue(e.target.value)}
                     value={searchValue}
                     onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                           handleOnSearch()
                        }
                     }}
                     endAdornment={
                        <Button
                           onClick={handleOnSearch}
                           disabled={
                              isLoading ||
                              !searchValue ||
                              searchValue.replace(/[.,/#!$%^+&*;:{}=\-_<>@|\\[\]`~()'"\s?\\]/g, '').length < 2
                           }
                           variant="contained"
                           sx={{ height: 35 }}
                        >
                           <Box component={SearchIcon} sx={{ color: 'white', width: 20, height: 20 }} />
                        </Button>
                     }
                     startAdornment={
                        <InputAdornment position="start">
                           <Box component={SearchIcon} sx={{ color: 'text.disabled', width: 15, height: 15 }} />
                        </InputAdornment>
                     }
                     placeholder={t('TRANSLATION:searchPlaceholder')}
                  />
                  <MainTable
                     orderValueNet={data.formattedNetClientPriceSum}
                     orderValueGross={data.formattedGrossClientPriceSum}
                     products={data.products}
                     updateData={(newData: ReflinkDataType) => setData(newData)}
                  />
                  <ContainedButton
                     sx={{ alignSelf: 'flex-end', mt: { md: 0, xs: 1 } }}
                     onClick={handleOrderConfirm}
                     disabled={isLoading || data.products.length === 0 || !isAddressValid}
                     variant="contained"
                  >
                     {t('CLIENT_CART:confirmOrder')}
                  </ContainedButton>
                  <AddressData
                     updateData={(newData: ReflinkDataType) => setData(newData)}
                     handleIsValid={(isValid: boolean) => setIsAddressValid(isValid)}
                     deliveryAddress={data.deliveryAddress}
                     deliveryType={data.deliveryType}
                  />
               </Card>
            )
         }
      }
      return null
   }

   function renderContent() {
      if (!window.localStorage.getItem('reflink-verify-token')) {
         if (!phoneNumberValid && !smsValid) {
            return (
               <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '40%', margin: '0 auto' }}>
                  <Typography variant="h4" color="primary">
                     {t('CLIENT_CART:phoneNumberVerify')}
                  </Typography>
                  <Box sx={{ width: '40%', margin: '0 auto' }}>
                     <MuiPhone
                        handleOnKeyDown={submitPhoneNumber}
                        onChange={(value, country) => handlePhoneNumber(value, country)}
                        value={phoneNumber}
                        variant="outlined"
                     />
                     <ContainedButton
                        onClick={submitPhoneNumber}
                        disabled={!phoneNumber || phoneNumberError || isLoading}
                        variant="contained"
                        sx={{ mt: 3 }}
                     >
                        {t('CLIENT_CART:confirm')}
                     </ContainedButton>
                  </Box>
               </Box>
            )
         }
         if (phoneNumberValid && !smsValid) {
            return (
               <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '40%', margin: '0 auto' }}>
                  <Typography variant="h4" color="primary">
                     {t('CLIENT_CART:confirmSmsCode')}
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 auto' }}>
                     <TextField
                        inputProps={{
                           maxLength: 6,
                        }}
                        onKeyPress={(e) => {
                           if (e.key === 'Enter') {
                              submitSmsCode()
                           }
                        }}
                        label={t('TRANSLATION:formFields.smsCode')}
                        value={smsCode}
                        onChange={(e) => setSmsCode(e.target.value)}
                     />
                     <ContainedButton
                        onClick={submitSmsCode}
                        disabled={!smsCode || smsCode.length < 6 || isLoading}
                        variant="contained"
                        sx={{ mt: 3 }}
                     >
                        {t('CLIENT_CART:confirm')}
                     </ContainedButton>
                  </Box>
               </Box>
            )
         }
      }
      return null
   }

   useEffect(() => {
      const reflinkUuid = searchParams.get('uuid')
      if (reflinkUuid) {
         if (reflinkUuid !== window.localStorage.getItem('uuid')) {
            setSmsValid(false)
            window.localStorage.removeItem('uuid')
            window.localStorage.removeItem('reflink-verify-token')
            window.localStorage.setItem('uuid', reflinkUuid)
            setPhoneNumberValid(false)
            renderContent()
         } else {
            window.localStorage.setItem('uuid', reflinkUuid)
            setPhoneNumberValid(false)
            setPhoneNumberValid(false)
         }
      } else {
         navigate('/')
      }
   }, [])

   useEffect(() => {}, [])

   useEffect(() => {
      const token = window.localStorage.getItem('reflink-verify-token')
      const uuid = window.localStorage.getItem('uuid')
      if (token && uuid) {
         getReflinkDataService(uuid, token)
            .then((res) => {
               setData(res.data)
            })
            .catch((err) => {
               if (err.message === 'Client cart reflink is not available - no client cart reflink found!') {
                  addNotification(t('CLIENT_CART:cartRejected'), 'error')
               } else if (err.message === 'Reflink verification code expired!') {
                  addNotification(t('CLIENT_CART:expired'), 'error')
                  setSmsValid(false)
                  setPhoneNumberValid(false)
                  window.localStorage.removeItem('reflink-verify-token')
               }
            })
      }
   }, [window.localStorage.getItem('reflink-verify-token')])

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
         <Container sx={{ minWidth: '100%', display: 'flex', justifyContent: 'flex-end', pt: 5 }}>
            <LanguagePopover />
         </Container>
         <Box
            sx={{
               display: 'flex',
               flexDirection: 'column',
               gap: 3,
               paddingTop: '5%',
               minHeight: 'calc(100vh - 199px)',
               textAlign: 'center',
            }}
         >
            {window.localStorage.getItem('reflink-verify-token') ? renderCart() : renderContent()}
         </Box>
         <SearchModal
            open={!!searchResponse}
            data={searchResponse}
            handleClose={() => {
               setSearchValue('')
               setSearchResponse(undefined)
            }}
            searchPhrase={searchValue}
            updateData={(newData: ReflinkDataType) => setData(newData)}
         />
      </Box>
   )
}
