import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import { StateType } from './types'

export function fetchSavedCarts(filter?: string): Promise<StateType> {
   return httpClient
      .get(`carts/?dateFilter=${filter || 'LAST_90_DAYS'}`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((err) => {
         consoleMessage('SAVED_CARTS fetchSavedCarts()', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}

export function deleteCart(uuid: string) {
   return httpClient
      .delete<Result>(`carts/${uuid}`)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         consoleMessage('SAVED_CARTS deleteCart()', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}

export function setActiveCart(uuid: string) {
   let errMessage

   return httpClient
      .put<Result>(`carts/${uuid}/active`)
      .then((res) => ({ status: res.status }))
      .catch((err: AxiosError<BackendError> | Error) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong in removeProduct()'
         } else {
            errMessage = err.message
         }
         consoleMessage('SAVED_CARTS setActiveCart()', err.message, 'error')
         throw new Error(errMessage)
      })
}
