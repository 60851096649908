import { httpClient } from 'shared/lib'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import { SettingsResponseType } from './types'

export function fetchSettingsData(): Promise<SettingsResponseType> {
   return httpClient
      .get('user/settings')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch(() => {
         throw new Error('apiErorrs.500')
      })
}

export function saveSettingsData(payload: SettingsType) {
   return httpClient
      .put<Result>('user/settings', payload)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         consoleMessage('PROFILE', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}
