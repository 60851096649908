import React, { useState } from 'react'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'

import { useTranslation } from 'react-i18next'
import { StyledTable, StyledTableCell, StyledTitleTableCell } from '../../../OrderReflink/styles'
import { Product, ReflinkDataType } from '../../types'
import { StyledContentTableCell, StyledDeleteIcon } from '../../styles'
import DeleteModal from '../DeleteModal'

interface Props {
   products: Product[]
   orderValueNet: string
   orderValueGross: string
   updateData: (data: ReflinkDataType) => void
}

export default function MainTable({ products, orderValueNet, orderValueGross, updateData }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'CLIENT_CART'])
   const [deleteModal, setDeleteModal] = useState({
      productUuid: '',
      wholesaleUuid: '',
   })

   return (
      <>
         <StyledTable>
            <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
               <TableRow>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.partIndex')}</StyledTitleTableCell>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.producer')}</StyledTitleTableCell>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.netPrice')}</StyledTitleTableCell>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.grossPrice')}</StyledTitleTableCell>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.quantity')}</StyledTitleTableCell>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.grossValue')}</StyledTitleTableCell>
                  <StyledTitleTableCell>{t('TRANSLATION:columnNames.removeProduct')}</StyledTitleTableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {products.length > 0 ? (
                  products.map((item) => (
                     <TableRow key={item.productUuid}>
                        <StyledContentTableCell>{item.productIndex}</StyledContentTableCell>
                        <StyledContentTableCell>
                           <Tooltip title={item.producerName}>
                              <img
                                 style={{ verticalAlign: 'middle', height: 20, width: 'auto' }}
                                 src={`https://files.motorro.eu/img/?name=${item.producerName.toLowerCase()}`}
                                 alt={item.producerName}
                              />
                           </Tooltip>
                        </StyledContentTableCell>
                        <StyledContentTableCell>{item.formattedNetClientPrice}</StyledContentTableCell>
                        <StyledContentTableCell>{item.formattedGrossClientPrice}</StyledContentTableCell>
                        <StyledContentTableCell>{item.quantity}</StyledContentTableCell>
                        <StyledContentTableCell>{item.formattedGrossClientValue}</StyledContentTableCell>
                        <StyledContentTableCell>
                           <StyledDeleteIcon
                              onClick={() =>
                                 setDeleteModal({ productUuid: item.productUuid, wholesaleUuid: item.wholesaleUuid })
                              }
                           />
                        </StyledContentTableCell>
                     </TableRow>
                  ))
               ) : (
                  <TableRow>
                     <StyledContentTableCell sx={{ fontSize: 14 }} colSpan={7}>
                        {t('CLIENT_CART:noProductsInCart')}
                     </StyledContentTableCell>
                  </TableRow>
               )}
            </TableBody>
            <TableFooter sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
               <StyledTableCell colSpan={4} sx={{ fontSize: 14, textAlign: 'right' }}>
                  {t('TRANSLATION:columnNames.netSum')}
               </StyledTableCell>
               <StyledContentTableCell sx={{ fontSize: 14 }}>{orderValueNet}</StyledContentTableCell>
               <StyledTableCell sx={{ fontSize: 14, textAlign: 'right' }}>
                  {t('TRANSLATION:columnNames.grossSum')}
               </StyledTableCell>
               <StyledContentTableCell sx={{ fontSize: 14 }}>{orderValueGross}</StyledContentTableCell>
            </TableFooter>
         </StyledTable>
         <DeleteModal
            productUuid={deleteModal.productUuid}
            open={!!deleteModal.productUuid}
            wholesaleUuid={deleteModal.wholesaleUuid}
            updateData={updateData}
            handleClose={() => setDeleteModal({ productUuid: '', wholesaleUuid: '' })}
         />
      </>
   )
}
