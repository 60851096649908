import React from 'react'
import Tooltip from '@mui/material/Tooltip'

interface Props {
   value: number
   data: any
   department: 1 | 2 | 3
}

export default function DepartmentAvailability({ value, data, department }: Props) {
   const wholesaleName = data.rowData[0]

   function getDepartment() {
      switch (department) {
         case 1: {
            return data.tableData.find((item: CooperatingWholesaler) => item.wholesaleName === wholesaleName)
               ?.firstDepartmentName
         }
         case 2: {
            return data.tableData.find((item: CooperatingWholesaler) => item.wholesaleName === wholesaleName)
               ?.secondDepartmentName
         }
         case 3: {
            return data.tableData.find((item: CooperatingWholesaler) => item.wholesaleName === wholesaleName)
               ?.thirdDepartmentName
         }
         default:
            return null
      }
   }

   function getFormattedValue() {
      if (getDepartment() !== '') {
         return (
            <Tooltip title={getDepartment() || ''}>
               <div>{value > 5 ? '> 5' : value || 0}</div>
            </Tooltip>
         )
      }
      return <div>-</div>
   }

   return <div>{getFormattedValue()}</div>
}
