import React from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { useWatchedProductsQuery } from 'shared/store/useWatchedProducts'

import WatchedProduct from './components/WatchedProduct'
import { pl, en, de } from './locales'

export default function Watchlist() {
   const { data, isFetched } = useWatchedProductsQuery()

   const { t, i18n } = useTranslation('WATCHLIST')
   i18n.addResourceBundle('pl-PL', 'WATCHLIST', pl)
   i18n.addResourceBundle('en-EN', 'WATCHLIST', en)
   i18n.addResourceBundle('de-DE', 'WATCHLIST', de)

   return (
      <Box>
         <Typography variant="h4" color="primary" sx={{ pb: 3, fontWeight: 'normal' }}>
            {t('watchlistTitle')}
         </Typography>
         {data?.length
            ? data.map((product) => (
                 <WatchedProduct
                    key={product.uuid}
                    index={product.productIndex}
                    uuid={product.uuid}
                    name={product.productName}
                    date={product.createdDate}
                    productUuid={product.productUuid}
                 />
              ))
            : isFetched && (
                 <Box>
                    <Typography variant="h5" color="primary">
                       {t('noResults')}
                    </Typography>
                 </Box>
              )}
      </Box>
   )
}
