import React, { useEffect, useState } from 'react'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import MailIcon from '@mui/icons-material/Mail'
import Tooltip from '@mui/material/Tooltip'
import Grid from '@mui/material/Grid'

import { AskCooperationPayload, WholesalerType } from 'shared/store/useWholesalers/types'
import { useAskForCoopMutation } from 'shared/store/useWholesalers'

import { StyledCard, StatusCorner, DeleteCorner, EditTimesCorner, EditCorner, StyledImg } from './styles'
import { WholesalerActionType } from '../../types'

type Props = {
   activeWholesaler: WholesalerType
   activeLocationUuid?: string
   handleWholesaler: (wholesalerId: string, type: WholesalerActionType) => void
}

export default function WholesalerCard({ activeWholesaler, handleWholesaler, activeLocationUuid }: Props) {
   const [selectedDepsCount, setSelectedDepsCount] = useState(0)
   const [handlingAction, setHandlingAction] = useState(false)
   const { mutate } = useAskForCoopMutation()

   function handleAskForCooperation(wholesaleUuid: string) {
      setHandlingAction(true)
      const payload: AskCooperationPayload = {
         locationUuid: activeLocationUuid || '',
         wholesaleUuid,
      }
      if (!handlingAction) {
         mutate(payload, {
            onSettled: () => setHandlingAction(false),
         })
      }
   }

   useEffect(() => {
      if (activeWholesaler) {
         const selectedDeps = activeWholesaler.departments.filter((item) => item.type && item.name)
         setSelectedDepsCount(selectedDeps.length)
      }
   }, [activeWholesaler])

   return (
      <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
         <StyledCard>
            <StatusCorner pendingcoop={`${activeWholesaler.askCooperation && !activeWholesaler.cooperation}`}>
               {activeWholesaler.askCooperation && !activeWholesaler.cooperation ? (
                  <QueryBuilderIcon color="primary" sx={{ mt: '3px', ml: '4px' }} />
               ) : (
                  <CheckIcon sx={{ color: 'green', mt: '3px', ml: '4px' }} />
               )}
            </StatusCorner>
            <DeleteCorner onClick={() => handleWholesaler(activeWholesaler.wholesaleUuid, 'deleteWholesaler')}>
               <DeleteIcon />
            </DeleteCorner>
            <EditTimesCorner
               onClick={
                  activeWholesaler.askCooperation && !activeWholesaler.cooperation
                     ? () => handleAskForCooperation(activeWholesaler.wholesaleUuid)
                     : () => handleWholesaler(activeWholesaler.wholesaleUuid, 'editTimes')
               }
            >
               {activeWholesaler.askCooperation && !activeWholesaler.cooperation ? <MailIcon /> : <AccessTimeIcon />}
            </EditTimesCorner>
            <EditCorner onClick={() => handleWholesaler(activeWholesaler.wholesaleUuid, 'editWholesaler')}>
               <EditIcon />
            </EditCorner>
            <Tooltip
               title={`${activeWholesaler.wholesaleName} ${selectedDepsCount}/3 min: ${
                  activeWholesaler.minLogistics === null ? '-' : activeWholesaler.minLogistics
               }`}
            >
               <StyledImg
                  src={`${process.env.REACT_APP_FILES_URL}/img/logos-wholesalers/${activeWholesaler.wholesaleName}.png`}
                  alt={activeWholesaler.wholesaleName}
               />
            </Tooltip>
         </StyledCard>
      </Grid>
   )
}
