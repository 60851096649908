import { styled } from '@mui/material'

export const Grid = styled('div')({
   width: '100%',
   height: '100%',
   display: 'grid',
   gap: 25,
   gridTemplateColumns: 'repeat(8, 1fr)',
   gridTemplateRows: 'repeat(3, 350px)',
})
