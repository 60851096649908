import React from 'react'
import { useTranslation } from 'react-i18next'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { useQueryClient } from 'react-query'
import Dialog from '@mui/material/Dialog'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'

import { DeleteWholesalerPayload, WholesalerType } from 'shared/store/useWholesalers/types'
import { useDeleteCooperationMutation } from 'shared/store/useWholesalers'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'

import WholesalerInfoModal from '../WholesalerInfoModal'
import { WholesalerActionType } from '../../types'
import EditTimesModal from '../EditTimesModal'

interface Props {
   data: {
      data: WholesalerType
      type: WholesalerActionType
   }
   activeLocationUuid?: string
   open: boolean
   handleClose: () => void
}

export default function Modal({ open, data: { data, type }, activeLocationUuid, handleClose }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'WHOLESALERS'])
   const { mutate } = useDeleteCooperationMutation()
   const queryClient = useQueryClient()
   const theme = useTheme()

   function handleDeleteCoop() {
      const payload: DeleteWholesalerPayload = {
         wholesalerUuid: data.cooperationUuid,
         activeLocationUuid,
      }
      mutate(payload, {
         onSuccess: () => {
            queryClient.invalidateQueries('wholesalers')
            handleClose()
         },
      })
   }

   function renderContent() {
      switch (type) {
         case 'deleteWholesaler':
            return (
               <>
                  <Typography variant="body1" sx={{ mt: 1, mb: 1, color: 'text.secondary' }}>
                     {t('WHOLESALERS:deleteWholesalerDialog')}
                  </Typography>
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 2,
                        pt: 2,
                     }}
                  >
                     <OutlinedButton variant="outlined" onClick={handleClose}>
                        {t('TRANSLATION:cancel')}
                     </OutlinedButton>
                     <ContainedButton variant="contained" onClick={handleDeleteCoop}>
                        {t('TRANSLATION:delete')}
                     </ContainedButton>
                  </Box>
               </>
            )
         case 'editTimes':
            return (
               <EditTimesModal
                  locationUuid={activeLocationUuid || ''}
                  wholesalerUuid={data.wholesaleUuid}
                  closeModal={handleClose}
               />
            )
         case 'editWholesaler':
            return (
               <WholesalerInfoModal
                  activeLocationUuid={activeLocationUuid}
                  closeModal={handleClose}
                  wholesalerInfo={data}
               />
            )
         case 'addWholesaler':
            return (
               <WholesalerInfoModal
                  activeLocationUuid={activeLocationUuid}
                  closeModal={handleClose}
                  wholesalerInfo={data}
               />
            )
         default:
            return null
      }
   }

   return (
      <Dialog fullWidth={type === 'editWholesaler'} maxWidth="md" open={open} onClose={handleClose}>
         <DialogTitle>
            <Typography sx={{ color: theme.palette.primary.main, fontSize: 20 }}>
               {type === 'editWholesaler' || type === 'addWholesaler' ? data?.wholesaleName : t(`WHOLESALERS:${type}`)}
            </Typography>
         </DialogTitle>
         <DialogContent>{renderContent()}</DialogContent>
      </Dialog>
   )
}
