import { styled } from '@mui/material'
import TableCell from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import CloseIcon from '@mui/icons-material/Close'

export const StyledContentTableCell = styled(TableCell)({
   color: 'grayText',
})

export const StyledTableCell = styled(TableCell)({
   color: 'grayText',
   textAlign: 'center',
})

export const StyledTable = styled(Table)({
   maxWidth: '90%',
   margin: '0 auto',
   marginTop: 40,
   WebkitBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   MozBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   boxShadow: ' 0px 0px 10px -4px rgba(66, 68, 90, 1)',
   borderRadius: '10px',
   color: 'grayText',
})

export const StyledCloseIcon = styled(CloseIcon)({
   color: 'grayText',
   '&:hover': {
      color: 'red',
   },
})
