import React from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'

import { BaselinkerType, ExternalTool, StateType } from 'shared/store/useWholesalers/types'

import Baselinker from './Baselinker'

interface Props {
   externalTools: PropType<StateType, 'externalTools'>
}

export default function ExternalTools({ externalTools }: Props) {
   const { t } = useTranslation('WHOLESALERS')

   function renderTools(name: string, data: ExternalTool) {
      switch (name) {
         case 'Baselinker':
            return <Baselinker data={data as BaselinkerType} />
         default:
            return null
      }
   }

   return (
      <>
         <Typography sx={{ fontWeight: 'normal', ml: 2, mt: 2 }} color="primary" variant="h6">
            {t('externalTools')}
         </Typography>
         <Grid
            container
            spacing={{ xs: 3, md: 2, lg: 2 }}
            sx={{
               paddingTop: { md: 4, xs: 5 },
               paddingLeft: { md: 4, xs: 5 },
            }}
         >
            {externalTools.map((item) => renderTools(item.name, item))}
         </Grid>
      </>
   )
}
