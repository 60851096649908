import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { useNotificationContext } from 'shared/context/Notifications'

import { deleteCart, fetchSavedCarts, setActiveCart } from './services'
import { StateType } from './types'

export function useSavedCartsQuery(): UseQueryResult<StateType> {
   return useQuery('savedCarts', () => fetchSavedCarts())
}

export function useRemoveCartMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, unknown, string, unknown>((uuid) => deleteCart(uuid), {
      onSuccess: (data) => {
         if (data.status === 204) {
            queryClient.invalidateQueries('savedCarts')
         }
      },
      onSettled: (data, error) => {
         if (data?.status === 204) {
            addNotification('successSave', 'success')
         } else if (error) {
            addNotification('failedSave', 'error')
         }
      },
   })
}

export function useSetActiveCartMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, unknown, string, unknown>((uuid) => setActiveCart(uuid), {
      onError: () => {
         addNotification('failedForward', 'error')
      },
      onSettled: () => {
         queryClient.invalidateQueries('mainCart')
         queryClient.invalidateQueries('minimums')
         queryClient.invalidateQueries('savedCarts')
      },
   })
}
export function useFilterSavedCarts() {
   const queryClient = useQueryClient()
   return useMutation<StateType, unknown, string, unknown>((filter) => fetchSavedCarts(filter), {
      onSuccess: (data) => {
         if (Array.isArray(data.carts)) {
            queryClient.setQueryData('savedCarts', data)
         }
      },
   })
}
