import axios, { AxiosError } from 'axios'
import { httpClient, authRequest } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import { AuthorizationError, AuthorizationResult, LoginPayload, SwapLoginPayload } from './types'

function isAxiosError<ResponseType>(error: unknown): error is AxiosError<ResponseType> {
   return axios.isAxiosError(error)
}

export function authorizeUser(payload: LoginPayload) {
   return httpClient
      .post<AuthorizationResult>(
         'user/login',
         { username: payload.email, password: payload.password, verificationCode: payload.smsCode },
         { headers: { 'X-Fingerprint': payload.fingerPrint } }
      )
      .then((res) => res.data)
      .catch((err: unknown) => {
         if (isAxiosError<AuthorizationError>(err)) {
            consoleMessage('LOGIN', err.message || 'PROBLEM WITH authorizeUser() SERVICE', 'error')
            return {
               accessToken: null,
               refreshToken: null,
               error: err.response?.data?.errors ? err.response.data.errors[0]?.defaultMessage : 'INVALID_CREDENTIALS',
            }
         }
         if (err instanceof Error) {
            consoleMessage('LOGIN', err.message || 'PROBLEM WITH authorizeUser() SERVICE', 'error')
            return {
               accessToken: null,
               refreshToken: null,
               error: err.message || 'INVALID_CREDENTIALS',
            }
         }
         consoleMessage('LOGIN', 'PROBLEM WITH authorizeUser() SERVICE which is unknown', 'error')
         return {
            accessToken: null,
            refreshToken: null,
            error: 'Unauthorized',
         }
      })
}

export async function revokeToken(params: URLSearchParams) {
   return authRequest.post('/token/revoke', params).catch((err: Error | AxiosError<BackendError>) => {
      if (axios.isAxiosError(err)) {
         consoleMessage('LOGOUT', err.response?.data.message || 'PROBLEM WITH revokeToken() SERVICE', 'error')
      }
      consoleMessage('LOGOUT', err.message || 'PROBLEM WITH revokeToken() SERVICE', 'error')
   })
}

export async function swapUser(payload: SwapLoginPayload) {
   return httpClient
      .post<AuthorizationResult>('session/swap', {
         ...payload,
      })
      .then((res) => res.data)
      .catch((err: Error | AxiosError<AuthorizationResult>) => {
         if (axios.isAxiosError(err)) {
            consoleMessage('LOGIN', err.message || 'PROBLEM WITH swapUser() SERVICE', 'error')
            return {
               accessToken: null,
               refreshToken: null,
            }
         }
         consoleMessage('LOGIN', err.message || 'PROBLEM WITH swapUser() SERVICE', 'error')
         return {
            accessToken: null,
            refreshToken: null,
         }
      })
}
