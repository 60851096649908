import Table from '@mui/material/Table'
import { styled } from '@mui/material'

export const StyledTable = styled(Table)({
   margin: '0 auto',
   marginTop: 10,
   width: '80%',
   WebkitBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   MozBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   boxShadow: ' 0px 0px 10px -4px rgba(66, 68, 90, 1)',
   borderRadius: '10px',
   '>*': {
      color: 'grayText',
   },
})
