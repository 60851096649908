import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import { BuyNowServicePayload, EstablishCoopService, MissingProductsService, ProductDetails } from './types'

export async function fetchProductDetails(productId: string) {
   return httpClient
      .get<ProductDetails>(`products/${productId}`)
      .then((request) => {
         if (request.data) {
            return request.data
         }
         throw new Error('There is no data')
      })
      .catch((err: Error | AxiosError<BackendError>) => {
         let errMessage = ''
         if (axios.isAxiosError(err)) {
            if (err.response?.status === 404) {
               errMessage = 'product not found'
            } else {
               errMessage = err.response?.data?.message || 'Something went wrong with fetchCooperationData()'
            }
         } else {
            errMessage = err.message
         }
         consoleMessage('fetchCooperationData()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export async function buyNowService(payload: BuyNowServicePayload) {
   return httpClient
      .post<BuyNowSummaryType>('/orders/buy-now', payload)
      .then((res) => res)
      .catch((err: Error | AxiosError<BackendError>) => {
         let errMessage

         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'apiErrors.500buyNow'
         } else {
            errMessage = err.message
         }
         consoleMessage('buyNowService()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export async function reportMissingProductService(payload: MissingProductsService) {
   return httpClient
      .post('/missingProducts/', payload)
      .then((res) => res)
      .catch((err: Error | AxiosError<BackendError>) => {
         let errMessage

         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with reportMissingProductService'
         } else {
            errMessage = err.message
         }
         consoleMessage('reportMissingProductService', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export async function establishCooperationService(payload: EstablishCoopService) {
   return httpClient
      .post('/cooperation/establish/', payload)
      .then((res) => res)
      .catch((err: Error | AxiosError<BackendError>) => {
         let errMessage

         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with establishCooperationService'
         } else {
            errMessage = err.message
         }
         consoleMessage('establishCooperationService', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export async function addToWatchlistService(productID: string) {
   return httpClient
      .post(`/products/observed/anyWholesale?productUuid=${productID}`)
      .catch((err: Error | AxiosError<BackendError>) => {
         let errMessage
         if (axios.isAxiosError(err)) {
            if (err.response?.status === 409) {
               errMessage = 'PRODUCT_DETAILS:errors.alreadyExists'
            } else {
               errMessage = err.response?.data?.message || 'Something went wrong with addToWatchlistService'
            }
         } else {
            errMessage = err.message
         }
         consoleMessage('addToWatchlistService', errMessage, 'error')
         throw new Error(errMessage)
      })
}
