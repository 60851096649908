import { Yup } from 'shared/lib'

const validTaxID = /^(?:\d{10}|[1-9][0-9]{6,8}|[1-9]\d{8}|[1-9]\d{7,9}|ATU\d{8})$/

const validCompanyName =
   /^([a-zA-Z0-9żźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßÁČĎÉĚChÍŇÓŘŠŤÚŮÝŽáčďéěchíňóřštúůýž'",-][.]?[\s]*)*([a-zA-Z0-9żźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßÁČĎÉĚChÍŇÓŘŠŤÚŮÝŽáčďéěchíňóřštúůýž]\.?)\s*['"\s]*$/
const validPostCode = /^(?:[A-Za-z]{2})?(?:\d{4}|\d{5}|\d{6}|\d{2}-\d{3}|\d{3}\s?\d{2})$/
const validCity =
   /^([a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßẞÁČĎÉĚèàèChÍŇÓŘŠŤÚŮÝŽáčďéěèàèchíňóřštúůýž'-]\s?)*([^\s\d][a-zA-ZżźćńółęąśŻŹŚŁĆŃÓĘĄäÄöÖüÜßẞÁČĎÉĚèàèChÍŇÓŘŠŤÚŮÝŽáčďéěèàèchíňóřštúůýž])$/

export const companySchema = Yup.object()
   .nullable()
   .shape({
      companyName: Yup.string().max(128).matches(validCompanyName, 'validation.companyNamePattern').required(),
      taxID: Yup.string().matches(validTaxID, 'validation.taxIdPattern'),
      typeService: Yup.object().shape(
         {
            workshopPositions: Yup.number().when('shopPositions', {
               is: (value: number) => value === 0,
               then: (thenSchema) => thenSchema.min(1, 'validation.greaterThan0').required(),
            }),
            shopPositions: Yup.number().when('workshopPositions', {
               is: (value: number) => value === 0,
               then: (thenSchema) => thenSchema.min(1, 'validation.greaterThan0').required(),
            }),
         },
         [
            ['workshopPositions', 'shopPositions'], // <--- adding your fields which need validation
         ]
      ),
      address: Yup.object().shape({
         street: Yup.string().max(100).required(),
         houseNumber: Yup.string().max(15).required(),
         zipCode: Yup.string().matches(validPostCode, 'validation.postCodePattern').required(),
         city: Yup.string().matches(validCity, 'validation.cityPattern').required(),
         country: Yup.string().required(),
         voivodeship: Yup.string().required(),
         county: Yup.string().required(),
      }),
   })
