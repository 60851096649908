import React, { useState } from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { useNotificationContext } from 'shared/context/Notifications'

import { useTranslation } from 'react-i18next'
import { ReflinkDataType } from '../../types'
import { removeProductService } from '../../services/removeProductService'

interface Props {
   productUuid: string
   wholesaleUuid: string
   open: boolean
   handleClose: () => void
   updateData: (data: ReflinkDataType) => void
}

export default function DeleteModal({ productUuid, wholesaleUuid, open, handleClose, updateData }: Props) {
   const [isLoading, setIsLoading] = useState(false)
   const { t } = useTranslation(['TRANSLATION', 'CLIENT_CART'])
   const { addNotification } = useNotificationContext()

   function handleRemoveProduct() {
      const uuid = window.localStorage.getItem('uuid')
      const token = window.localStorage.getItem('reflink-verify-token')
      if (uuid && token) {
         setIsLoading(true)
         removeProductService(uuid, token, productUuid, wholesaleUuid)
            .then((res) => {
               addNotification(t('CLIENT_CART:deleteModal.productDeleted'), 'success')
               if (res.data) {
                  updateData(res.data)
               }
               handleClose()
            })
            .catch((err) => {
               if (err.message === 'Reflink verification code expired!') {
                  addNotification(t('CLIENT_CART:expired'), 'error')
                  window.localStorage.removeItem('reflink-verify-token')
               } else {
                  addNotification('failedSave', 'error')
               }
            })
            .finally(() => setIsLoading(false))
      }
   }

   return (
      <Dialog open={open} onClose={handleClose}>
         <DialogTitle>
            <Typography variant="h4" color="primary" textAlign="center">
               {t('CLIENT_CART:deleteModal.confirmation')}
            </Typography>
         </DialogTitle>
         <DialogActions>
            <Box sx={{ display: 'flex', gap: 1, margin: '0 auto' }}>
               <OutlinedButton variant="outlined" onClick={handleClose}>
                  {t('TRANSLATION:cancel')}
               </OutlinedButton>
               <ContainedButton disabled={isLoading} variant="contained" onClick={handleRemoveProduct}>
                  {t('TRANSLATION:delete')}
               </ContainedButton>
            </Box>
         </DialogActions>
      </Dialog>
   )
}
