import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CircularProgress from '@mui/material/CircularProgress'
import TableFooter from '@mui/material/TableFooter'
import IconButton from '@mui/material/IconButton'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import MenuItem from '@mui/material/MenuItem'
import TableRow from '@mui/material/TableRow'
import Select from '@mui/material/Select'
import Box from '@mui/material/Box'

import { useNotificationsQuery, useReadNotification } from 'shared/store/useNotifications'
import { NotificationActionsPayload } from 'shared/store/useNotifications/types'

import { StyledTable, StyledTableCell, StyledTableRow, BasicTableCell } from './styles'
import { FormType, NotificationInfo } from './types'
import Modal from './components/Modal'
import { en, pl, de } from './locales'

export default function Notifications() {
   const { data } = useNotificationsQuery()
   const { mutate } = useReadNotification()
   const navigate = useNavigate()
   const [openModal, setOpenModal] = useState(false)
   const [modalInfo, setModalInfo] = useState<NotificationInfo>()
   const [modalType, setModalType] = useState<FormType>()
   const [activePage, setActivePage] = useState(0)
   const [itemsPerPage, setItemsPerPage] = useState(10)
   const { t, i18n } = useTranslation('NOTIFICATIONS')

   i18n.addResourceBundle('pl-PL', 'NOTIFICATIONS', pl)
   i18n.addResourceBundle('en-EN', 'NOTIFICATIONS', en)
   i18n.addResourceBundle('de-DE', 'NOTIFICATIONS', de)

   function handleItemsPerPage(event: any) {
      setActivePage(0)
      setItemsPerPage(event.target.value)
   }

   function handleOpenModal(
      type: PropType<NotificationInfo, 'type'> | 'DELETE',
      notificationDetails: NotificationInfo,
      isRead: boolean
   ) {
      if (!type) return

      if (!isRead && notificationDetails.notificationUuid) {
         const payload: NotificationActionsPayload = { uuid: notificationDetails.notificationUuid }
         mutate(payload)
      }

      setModalInfo(notificationDetails)
      setModalType(type)
      setOpenModal(true)
   }

   function getNotificationType(notificationType: PropType<NotificationInfo, 'type'>) {
      switch (notificationType) {
         case 'PRODUCT_AVAILABILITY_IN_WHOLESALE':
            return t('watched')
         case 'CLIENT_FINISHED_ORDER':
            return t('pricing')
         case 'WAITING_FOR_PAYMENT':
            return t('payment')
         case 'AUTO_CART_CREATION_FAILED':
         case 'AUTO_CART_CREATED':
            return t('automaticCart')
         case 'ORDER_CREATED':
         case 'ORDER_CREATION_FAILED':
            return t('orderCreation')
         case 'COOPERATION_REJECTED_BY_WHOLESALE':
            return t('coopRejected')
         case 'CLIENT_REFLINK_ORDER_CREATED':
         case 'CLIENT_REFLINK_CART_CREATED':
            return t('clientCarts')
         default:
            return null
      }
   }

   function getNotificationTitle(
      notificationType: PropType<NotificationInfo, 'type'>,
      message: string,
      productIndex?: string,
      productName?: string,
      orderUuid?: string,
      cartUuid?: string,
      wholesaleName?: string
   ) {
      if (notificationType === 'ORDER_CREATED' && cartUuid) {
         return t('orderCreatedWithProblemsTitle')
      }
      switch (notificationType) {
         case 'PRODUCT_AVAILABILITY_IN_WHOLESALE':
            return `${productName} ( id: ${productIndex})`
         case 'CLIENT_FINISHED_ORDER':
            return message
         case 'WAITING_FOR_PAYMENT':
            return t('paymentTitle', { orderId: orderUuid })
         case 'AUTO_CART_CREATION_FAILED':
            return t('automaticFailedTitle')
         case 'AUTO_CART_CREATED':
            return t('automaticTitle')
         case 'ORDER_CREATED':
            return t('orderCreatedTitle')
         case 'ORDER_CREATION_FAILED':
            return t('orderCreationFailedTitle')
         case 'COOPERATION_REJECTED_BY_WHOLESALE':
            return t('coopRejectedTitle', { wholesaleName })
         case 'CLIENT_REFLINK_CART_CREATED':
            return t('clientCartCreated')
         case 'CLIENT_REFLINK_ORDER_CREATED':
            return t('clientOrderCreated')
         default:
            return null
      }
   }

   function handleForward(notificationUuid: string, isRead: boolean, productIndex?: string, productUuid?: string) {
      if (!isRead) {
         const payload: NotificationActionsPayload = { uuid: notificationUuid }
         mutate(payload)
      }
      if (productIndex && productUuid) {
         navigate({
            pathname: '/dashboard/product',

            search: `?${createSearchParams({
               uuid: productUuid,
            })}`,
         })
      }
   }

   return (
      <Box sx={{ backgroundColor: 'rgba(0,0,0,0)' }}>
         <Typography variant="h4" color="primary" sx={{ fontWeight: 'normal' }}>
            {t('notifications')}
         </Typography>
         {data ? (
            <StyledTable>
               <TableHead sx={{ backgroundColor: 'rgba(33,147,224,0.1)' }}>
                  <TableRow>
                     <StyledTableCell>{t('table.date')}</StyledTableCell>
                     <StyledTableCell>{t('table.subject')}</StyledTableCell>
                     <StyledTableCell>{t('table.content')}</StyledTableCell>
                     <StyledTableCell>{t('DELETE')}</StyledTableCell>
                  </TableRow>
               </TableHead>
               <TableBody sx={{ backgroundColor: 'rgba(255,255,255,0.7)' }}>
                  {data.length > 0 ? (
                     data
                        .slice(itemsPerPage * activePage, itemsPerPage * activePage + itemsPerPage)
                        .map((notification) => (
                           <StyledTableRow
                              sx={{ '>td': { fontWeight: notification.read ? 'normal' : 'bold' } }}
                              key={notification.uuid}
                              onClick={() =>
                                 notification.type === 'CLIENT_FINISHED_ORDER' ||
                                 notification.type === 'WAITING_FOR_PAYMENT' ||
                                 notification.type === 'AUTO_CART_CREATED' ||
                                 notification.type === 'AUTO_CART_CREATION_FAILED' ||
                                 notification.type === 'ORDER_CREATED' ||
                                 notification.type === 'ORDER_CREATION_FAILED' ||
                                 notification.type === 'CLIENT_REFLINK_CART_CREATED' ||
                                 notification.type === 'CLIENT_REFLINK_ORDER_CREATED'
                                    ? handleOpenModal(
                                         notification.type,
                                         {
                                            orderUuid: notification.orderUuid,
                                            notificationUuid: notification.uuid,
                                            emailAddress: notification.email,
                                            message: notification.message,
                                            cartUuid: notification.cartUuid,
                                            phoneNumber: notification.phoneNumber,
                                            cartName: notification.cartName,
                                         },
                                         notification.read
                                      )
                                    : handleForward(
                                         notification.uuid,
                                         notification.read,
                                         notification.productIndex,
                                         notification.productUuid
                                      )
                              }
                           >
                              <BasicTableCell>{notification.date}</BasicTableCell>
                              <BasicTableCell>{getNotificationType(notification.type)}</BasicTableCell>
                              <BasicTableCell>
                                 {getNotificationTitle(
                                    notification.type,
                                    notification.message,
                                    notification.productIndex,
                                    notification.productName,
                                    notification.orderUuid,
                                    notification.cartUuid,
                                    notification.wholesaleName
                                 )}
                              </BasicTableCell>
                              <BasicTableCell>
                                 <IconButton
                                    onClick={(e) => {
                                       e.stopPropagation()
                                       handleOpenModal(
                                          'DELETE',
                                          {
                                             notificationUuid: notification.uuid,
                                          },
                                          notification.read
                                       )
                                    }}
                                 >
                                    <DeleteOutlineOutlinedIcon />
                                 </IconButton>
                              </BasicTableCell>
                           </StyledTableRow>
                        ))
                  ) : (
                     <TableRow>
                        <StyledTableCell colSpan={4}>{t('notFound')}</StyledTableCell>
                     </TableRow>
                  )}
               </TableBody>
               <TableFooter sx={{ backgroundColor: 'rgba(33,147,224,0.1)' }}>
                  <TableRow>
                     <TableCell sx={{ textAlign: 'right', paddingRight: 7 }} colSpan={4}>
                        {t('itemsPerPage')}
                        <Select
                           sx={{ mx: 1, color: 'grayText', fontSize: 14 }}
                           variant="standard"
                           disabled={data.length === 0}
                           value={itemsPerPage}
                           onChange={(e) => handleItemsPerPage(e)}
                        >
                           <MenuItem value={5}> 5 </MenuItem>
                           <MenuItem value={10}> 10 </MenuItem>
                           <MenuItem value={15}> 15 </MenuItem>
                        </Select>
                        {t('displayed', {
                           displayed: `${itemsPerPage * activePage + 1} - ${
                              itemsPerPage * activePage + itemsPerPage > data.length
                                 ? data.length
                                 : itemsPerPage * activePage + itemsPerPage
                           }`,
                           all: data?.length,
                        })}
                        <IconButton
                           disabled={data.length === 0 || activePage === 0}
                           onClick={() => setActivePage((page) => page - 1)}
                        >
                           <ChevronLeftIcon />
                        </IconButton>
                        <IconButton
                           disabled={
                              data
                                 ? data.length === 0 || activePage === Math.ceil(data.length / itemsPerPage) - 1
                                 : false
                           }
                           onClick={() => setActivePage((page) => page + 1)}
                        >
                           <ChevronRightIcon />
                        </IconButton>
                     </TableCell>
                  </TableRow>
               </TableFooter>
            </StyledTable>
         ) : (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
               <CircularProgress color="primary" />
            </div>
         )}
         <Modal open={openModal} type={modalType} handleClose={() => setOpenModal(false)} modalInfo={modalInfo} />
      </Box>
   )
}
