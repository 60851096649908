import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import axios, { AxiosError } from 'axios'
import isEqual from 'lodash/isEqual'

import { useNotificationContext } from 'shared/context/Notifications'

import { fetchProfileData, saveProfileData } from './services'
import { FetchUserProfileResult, UpdateProfilePayload, UserDataType } from './types'

async function getUserProfileData() {
   const request = await fetchProfileData().then((response) => ({
      uuid: response.uuid,
      locationUuid: response.locationUuid,
      image: response.image,
      loyaltyPoints: response.loyaltyPoints,
      userName: response.userName,
      locationName: response.locationName,
      priceType: response.priceType,
      currency: response.currency,
      formattedCurrency: response.formattedCurrency,
   }))

   return request
}

export function useProfileQuery(): UseQueryResult<FetchUserProfileResult> {
   return useQuery('userProfile', getUserProfileData, { staleTime: 10 * (60 * 1000), refetchOnWindowFocus: 'always' })
}

export function useProfileMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, Error | AxiosError<BackendError>, UpdateProfilePayload, unknown>(
      (payload) => {
         if (payload.type === 'admin') {
            const initialData = queryClient.getQueryData<UserDataType>('userProfile')
            const initialPayload = initialData
               ? {
                    type: 'admin',
                    uuid: initialData.uuid,
                    name: initialData.name,
                    surname: initialData.surname,
                    email: initialData.email,
                    phoneNumber: initialData.phoneNumber,
                 }
               : {}

            if (isEqual(initialPayload, payload)) {
               throw new Error('Nothing is changed')
            } else {
               return saveProfileData(payload)
            }
         } else {
            const initialData = queryClient.getQueryData<UserDataType>('userProfile')
            const initialPayload = initialData
               ? {
                    type: 'user',
                    uuid: initialData.uuid,
                    name: initialData.name,
                    locationUuid: initialData.locationUuid,
                    surname: initialData.surname,
                    email: initialData.email,
                    phoneNumber: initialData.phoneNumber,
                 }
               : {}
            if (isEqual(initialPayload, payload)) {
               throw new Error('Nothing is changed')
            } else {
               return saveProfileData(payload)
            }
         }
      },
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('userProfile')
            }
         },
         onSettled: (data, error) => {
            if (data?.status === 200) {
               addNotification('successSave', 'success')
            } else if (error) {
               if (axios.isAxiosError(error)) {
                  addNotification('failedSave', 'error')
               } else {
                  addNotification(error.message, 'error')
               }
            }
         },
      }
   )
}
