import styled from '@emotion/styled'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'

export const RootStyle = styled(Box)({
   display: 'flex',
   margin: 10,
})

export const StyledTable = styled(Table)({
   WebkitBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   MozBoxShadow: '0px 0px -4px 0px rgba(66, 68, 90, 1)',
   boxShadow: ' 0px 0px 10px -4px rgba(66, 68, 90, 1)',
   borderRadius: '10px',
   color: 'text.secondary',
   marginTop: 40,
})

export const StyledTableRow = styled(TableRow)({
   '&:hover': {
      backgroundColor: 'rgba(28,202,255,0.03)',
      cursor: 'pointer',
   },
})

const BasicTableCell = styled(TableCell)({
   padding: '8px 4px 8px 4px',
})

export const UnReadTableCell = styled(BasicTableCell)({
   color: 'black',
   fontWeight: 'bold',
   textAlign: 'center',
})

export const ReadTableCell = styled(BasicTableCell)({
   color: 'text.secondary',
   textAlign: 'center',
})

export const StyledTableCell = styled(BasicTableCell)({
   color: 'text.secondary',
   fontWeight: 'normal',
   textAlign: 'center',
})
