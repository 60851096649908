import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import { BuySubscriptionPayload, Result } from '../types'

export default function buySubscriptionService(payload: BuySubscriptionPayload) {
   let errMessage = ''
   return httpClient
      .post<Result>('subscription/', payload)
      .then((res) => ({ status: res.status, redirectUrl: res.data.redirectUrl }))
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong with buySubscriptionService'
         } else {
            errMessage = err.message
         }
         consoleMessage('payments buySubscriptionService', errMessage, 'error')
         throw new Error(errMessage)
      })
}
