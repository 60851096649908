import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import { GetOrdersResult } from './types'

function generateUrl(searchPhrase?: string, filter?: string) {
   if (searchPhrase) {
      if (filter) {
         return `orders/?search=${searchPhrase}&orderDate=${filter}`
      }
      return `orders/?search=${searchPhrase}`
   }
   if (filter) {
      return `orders/?orderDate=${filter}`
   }
   return 'orders/'
}

export function getOrdersService(searchPhrase?: string, filter?: string) {
   let errMessage = ''
   const url = generateUrl(searchPhrase, filter)
   return httpClient
      .get<GetOrdersResult>(url)
      .then((res) => {
         if (res.data) {
            return {
               data: res.data.orders.filter((item) => item.grossValueSum !== 0),
               startingYear: res.data.years.startingYear,
               selectedFilter: res.data.years.selectedFilter,
               availableFilters: res.data.years.availableFilters,
               currentYear: res.data.years.currentYear,
            }
         }
         throw new Error('noData')
      })
      .catch((err: AxiosError<BackendError> | Error) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong in fetchOrdersHistory()'
         } else {
            errMessage = err.message
         }
         consoleMessage('ORDERS HISTORY fetchOrdersHistory()', errMessage, 'error')
         throw new Error(errMessage)
      })
}
