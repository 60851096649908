import React, { useState } from 'react'

import { BaselinkerType } from 'shared/store/useWholesalers/types'

import BaselinkerModal from './components/BaselinkerModal'
import NonCoopCard from './components/NonCoopCard'
import CoopCard from './components/CoopCard'
import { ModalType } from './types'

interface Props {
   data: BaselinkerType
}

export default function Baselinker({ data }: Props) {
   const [openModal, setOpenModal] = useState(false)
   const [modalType, setModalType] = useState<ModalType>('new')

   function handleModalType(type: ModalType) {
      setModalType(type)
      setOpenModal(true)
   }

   return (
      <div>
         {data.token ? (
            <CoopCard handleModalType={handleModalType} />
         ) : (
            <NonCoopCard handleModalType={handleModalType} />
         )}
         <BaselinkerModal data={data} open={openModal} handleClose={() => setOpenModal(false)} type={modalType} />
      </div>
   )
}
