/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { useNotificationContext } from 'shared/context/Notifications'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import getCompanyUsersService from 'shared/services/getCompanyUsers'
import { useLocationsQuery } from 'shared/store/useLocations'
import Hidden from 'shared/components/Hidden'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'
import { httpClient } from 'shared/lib'

import { getRefreshToken } from 'shared/utils/helpers/refreshToken'
import { GetSubscriptionInfoResult, ModalInfoType } from './types'
import { getSubscriptionEndDateService } from './services'
import DesktopTable from './components/DesktopTable'
import MobileTable from './components/MobileTable'
import Modal from './components/Modal'
import { en, pl, de } from './locales'

export default function UsersAndSub() {
   const { t, i18n } = useTranslation(['TRANSLATION', 'USERS_AND_SUBSCRIPTIONS'])
   i18n.addResourceBundle('pl-PL', 'USERS_AND_SUBSCRIPTIONS', pl)
   i18n.addResourceBundle('en-EN', 'USERS_AND_SUBSCRIPTIONS', en)
   i18n.addResourceBundle('de-DE', 'USERS_AND_SUBSCRIPTIONS', de)
   const [modalInfo, setModalInfo] = useState<ModalInfoType>()
   const [isLoading, setIsLoading] = useState(false)
   const [refetch, setRefetch] = useState(false)
   const queryClient = useQueryClient()
   const { data } = useLocationsQuery()
   const [users, setUsers] = useState<UsersType>()
   const [subscriptionDetails, setSubscriptionDetails] = useState<GetSubscriptionInfoResult>()
   const { addNotification } = useNotificationContext()
   const { user } = useLogin()

   const noUsers = users?.length === 0
   const isAdmin = user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN)
   const isLocationAdmin = user.roles.includes(UserRoles.LOCALIZATION_ADMIN)
   const salesRepOn = user.roles.includes(UserRoles.ULTRA)

   function handleSalesRepChange(remove: boolean, userUuid: string) {
      setIsLoading(true)
      httpClient
         .post(`companies/users/${userUuid}/change-sales-rep-role?remove=${remove}`)
         .then(() => {
            getRefreshToken()
            queryClient.invalidateQueries('user')
            addNotification('successSave', 'success')
         })
         .catch(() => {
            addNotification('failedSave', 'error')
         })
         .finally(() => setIsLoading(false))
   }

   useEffect(() => {
      setIsLoading(true)
      getCompanyUsersService(true)
         .then((res) => {
            setUsers(res.data)
         })
         .finally(() => {
            setIsLoading(false)
            setRefetch(false)
         })
      if (user.roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN)) {
         getSubscriptionEndDateService().then((res) => {
            if (res) {
               setSubscriptionDetails({ ...res })
            }
         })
      }
   }, [refetch])

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
         <Typography variant="h4" color="primary" sx={{ fontWeight: 'normal' }}>
            {t('USERS_AND_SUBSCRIPTIONS:usersAndSubTitle')}
         </Typography>
         <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {!noUsers ? (
               users && !isLoading ? (
                  <>
                     <Hidden width="mdDown">
                        <DesktopTable
                           handleRefetch={() => setRefetch(true)}
                           setModalInfo={setModalInfo}
                           isAdmin={isAdmin}
                           salesRepOn={salesRepOn}
                           isLoading={isLoading}
                           loading={isLoading}
                           data={users}
                           handleSalesRepChange={handleSalesRepChange}
                        />
                     </Hidden>
                     <Hidden width="mdUp">
                        <MobileTable
                           handleRefetch={() => setRefetch(true)}
                           setModalInfo={setModalInfo}
                           isAdmin={isAdmin}
                           salesRepOn={salesRepOn}
                           isLoading={isLoading}
                           loading={isLoading}
                           data={users}
                           handleSalesRepChange={handleSalesRepChange}
                        />
                     </Hidden>
                  </>
               ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                     <CircularProgress color="primary" />
                  </Box>
               )
            ) : (
               <Box sx={{ display: 'flex', textAlign: 'center', color: 'grayText' }}>
                  {t('USERS_AND_SUBSCRIPTIONS:noUsers')}
               </Box>
            )}
            {(isAdmin || isLocationAdmin) && data && (
               <OutlinedButton
                  onClick={() =>
                     setModalInfo({
                        userInfo: {
                           uuid: '',
                           name: '',
                           surname: '',
                           phoneNumber: '',
                           email: '',
                           locationUuids: [],
                        },
                        modalType: 'CREATEUSER',
                     })
                  }
                  sx={{ alignSelf: 'center', mt: 4, fontSize: 11 }}
                  variant="outlined"
               >
                  {t('USERS_AND_SUBSCRIPTIONS:addUserButton')}
               </OutlinedButton>
            )}
         </Box>
         <Modal
            handleRefetch={() => setRefetch(true)}
            modalInfo={modalInfo}
            handleClose={() => setModalInfo(undefined)}
         />
         {!isLoading && (
            <Typography variant="body1" sx={{ color: 'text.secondary', alignSelf: 'center' }}>
               {isAdmin &&
                  subscriptionDetails &&
                  t('USERS_AND_SUBSCRIPTIONS:expiration', {
                     expirationDate: subscriptionDetails.expirationDate,
                     subscriptionType: subscriptionDetails.subscriptionType,
                  })}
            </Typography>
         )}
      </Box>
   )
}
