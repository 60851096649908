import axios, { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import { httpClient } from 'shared/lib'

import { FetchNotificationsResult, NotificationActionsPayload } from './types'

export function fetchNotifications(): Promise<FetchNotificationsResult> {
   let errMessage = ''

   return httpClient
      .get('notifications/')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'something went wrong in fetchNotifications()'
         } else {
            errMessage = err.message
         }
         consoleMessage('NOTIFICATIONS fetchNotifications()', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function deleteNotification(payload: NotificationActionsPayload) {
   let errMessage = ''

   return httpClient
      .delete<Result>(`notifications/${payload.uuid}`)
      .then((res) => ({ status: res.status }))
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong in notifications view'
         } else {
            errMessage = err.message
         }
         consoleMessage('notifications deleteNotification', errMessage, 'error')
         throw new Error(errMessage)
      })
}

export function readNotification(payload: NotificationActionsPayload) {
   let errMessage = ''

   return httpClient
      .put(`notifications/${payload.uuid}/isRead/`)
      .then((res) => ({ status: res.status }))
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || 'Something went wrong in notifications view'
         } else {
            errMessage = err.message
         }
         consoleMessage('notification readNotification', errMessage, 'error')
         throw new Error(errMessage)
      })
}
