import { unauthHttpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { EndReflinkPayload, ErrorType, ReflinkInfoType, Result } from '../types'

export function endReflinkService(reflinkUuid: string, payload: EndReflinkPayload) {
   return unauthHttpClient
      .post<Result>(`cooperation/reflink/${reflinkUuid}`, payload)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         if (isAxiosError<ErrorType>(err)) {
            throw new Error(`apiErrors.${err.response?.data?.errors[0].defaultMessage}`)
         }
         throw new Error('apiErrors.500')
      })
}

export function getReflinkDetailsService(uuid: string): Promise<ReflinkInfoType> {
   return unauthHttpClient
      .get(`cooperation/reflink/${uuid}`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((err) => {
         consoleMessage('COOPERATION_REFLINK', err.response?.data?.errors[0].defaultMessage, 'error')
         throw new Error(err.response?.data?.errors[0].defaultMessage)
      })
}

export function rejectReflinkService(uuid: string) {
   return unauthHttpClient
      .delete<Result>(`cooperation/reflink/${uuid}`)
      .then((res) => ({ status: res.status }))
      .catch((err) => {
         if (isAxiosError<ErrorType>(err)) {
            throw new Error(`apiErrors.${err.response?.data?.errors[0].defaultMessage}`)
         }
         throw new Error('apiErrors.500')
      })
}
