import { AxiosError } from 'axios'

import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { ReflinksResult, CreateReflinkPayload } from './types'

export function fetchReflinks() {
   let errMessage = ''

   return httpClient
      .get<ReflinksResult>('/client-carts/reflinks')
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((err: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(err)) {
            errMessage = err.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = err.message
         }
         throw new Error(errMessage)
      })
}

export function createReflink(payload: CreateReflinkPayload) {
   let errMessage

   return httpClient
      .post(`/client-carts/reflinks`, payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((err: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(err)) {
            errMessage = err.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = err.message
         }
         throw new Error(errMessage)
      })
}

export function deleteReflink(reflinkUuid: string) {
   let errMessage

   return httpClient
      .delete(`/client-carts/reflinks/${reflinkUuid}`)
      .then((res) => ({
         status: res.status,
      }))
      .catch((err: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(err)) {
            errMessage = err.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = err.message
         }
         throw new Error(errMessage)
      })
}
