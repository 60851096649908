import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import axios, { AxiosError } from 'axios'

import { useNotificationContext } from 'shared/context/Notifications'

import { fetchCompanyData, saveCompanyData } from './services'
import { CompanySettingsType } from './types'

export function useCompanyQuery(): UseQueryResult<CompanySettingsType> {
   return useQuery('companyData', fetchCompanyData, { staleTime: 10 * (60 * 1000) })
}

export function useCompanyMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, Error | AxiosError<BackendError>, CompanySettingsType, unknown>(
      (payload) => {
         return saveCompanyData(payload)
      },
      {
         onSuccess: (data) => {
            if (data.status === 204) {
               queryClient.invalidateQueries('companyData')
            }
         },
         onSettled: (data, error) => {
            if (data?.status === 204) {
               addNotification('successSave', 'success')
            } else if (error) {
               if (axios.isAxiosError(error)) {
                  addNotification('failedSave', 'error')
               } else {
                  addNotification(error.message, 'error')
               }
            }
         },
      }
   )
}
