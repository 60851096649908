import consoleMessage from 'shared/utils/helpers/consoleMessage'
import axios, { AxiosError } from 'axios'
import { httpClient } from 'shared/lib'

import { ProcessFileServiceType } from './types'

type Result = {
   status: number
}

export default function processFileService(payload: ProcessFileServiceType) {
   let errMessage

   return httpClient
      .post<Result>('carts/auto', payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((err: Error | AxiosError<ErrorType>) => {
         if (axios.isAxiosError(err)) {
            switch (err.response?.data.errors[0].defaultMessage) {
               case 'Auto order is not available - no auto order conditions found!':
                  errMessage = 'condition error'
                  break
               default:
                  errMessage = ''
            }
         } else {
            errMessage = err.message
         }
         consoleMessage('CSV_IMPORT', errMessage, 'error')
         throw new Error(errMessage)
      })
}
