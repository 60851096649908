import axios, { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { useNotificationContext } from 'shared/context/Notifications'

import { addLocation, fetchLocations, removeLocation, saveLocationDetails } from './services'
import {
   AddLocationPayload,
   LocationErrorType,
   RemoveLocationPayload,
   SaveLocationDetailsPayload,
   StateType,
} from './types'

export function useLocationsQuery(): UseQueryResult<StateType> {
   return useQuery('locations', fetchLocations)
}

export function useDataLocationsMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<
      Result,
      Error | AxiosError<BackendError>,
      { payload: SaveLocationDetailsPayload; uuid: string },
      unknown
   >(
      ({ payload, uuid }) => {
         return saveLocationDetails(payload, uuid)
      },
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('locations')
               queryClient.invalidateQueries('userProfile')
            }
         },
         onSettled: (data, error) => {
            if (data?.status === 200) {
               addNotification('successSave', 'success')
            } else if (error) {
               if (axios.isAxiosError(error)) {
                  addNotification('failedSave', 'error')
               } else {
                  addNotification(error.message, 'error')
               }
            }
         },
      }
   )
}

export function useAddLocationMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, Error | AxiosError<LocationErrorType>, AddLocationPayload, unknown>(
      (payload) => addLocation(payload),
      {
         onSuccess: (data) => {
            if (data.status === 200) {
               queryClient.invalidateQueries('locations')
            }
         },
         onSettled: (data, error) => {
            if (data?.status === 200) {
               addNotification('successSave', 'success')
            } else if (error) {
               if (axios.isAxiosError(error)) {
                  addNotification('failedSave', 'error')
               } else {
                  addNotification(error.message, 'error')
               }
            }
         },
      }
   )
}

export function useRemoveLocationMutation() {
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()

   return useMutation<Result, unknown, RemoveLocationPayload, unknown>((payload) => removeLocation(payload), {
      onSuccess: (data) => {
         if (data.status === 200) {
            queryClient.invalidateQueries('locations')
         }
      },
      onSettled: (data, error) => {
         if (data?.status === 200) {
            addNotification('successSave', 'success')
         } else if (error) {
            addNotification('failedSave', 'error')
         }
      },
   })
}
