import { AxiosError } from 'axios'
import { jwtDecode } from 'jwt-decode'

import { LocalStorageKeys } from 'shared/consts'
import { authRequest, httpClient } from 'shared/lib'
import { loginOut } from 'shared/store/useLogin'
import { JwtTokenData } from 'shared/store/useLogin/types'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'

type Result = {
   status: number
   couponType: string
   rebateCouponDTO: {
      rebatePercent: string
   }
}

async function getRefreshToken() {
   try {
      const refreshToken = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN) || ''
      const params = new URLSearchParams()
      params.append('refresh_token', refreshToken)

      const res = await authRequest.post<{ accessToken: string }>('token/refresh', params)
      const token = res.data.accessToken
      const user = jwtDecode<JwtTokenData>(token)
      const expirationDate = new Date(new Date().getTime() + user.expires * 1000)

      localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, token)
      localStorage.setItem(LocalStorageKeys.EXPIRES_IN, `${expirationDate}`)
   } catch (err: any) {
      if (err.response?.status === 423) {
         loginOut('/session-end').then(() => {
            if (isAxiosError<ErrorType>(err)) {
               switch (err.response?.data.errors[0].defaultMessage) {
                  case 'Refresh token expired':
                     window.location.href = '/session-end?reason=expired'
                     break
                  case 'Refresh token already invalidated':
                     window.location.href = '/session-end?reason=loggedOut'
                     break
                  case 'Subscription assigned to that session expired':
                     window.location.href = '/session-end?reason=subscription'
                     break
                  default:
                     window.location.href = '/session-end?reason=expired'
               }
            }
         })
      }
   }
}

export default function checkCouponService(coupon: string) {
   let errMessage = ''

   return httpClient
      .post<Result>('coupon/use', { coupon })
      .then((res) => {
         if (res.data.couponType === 'SUBSCRIPTION') {
            getRefreshToken()
         }
         return { status: res.status, data: res.data }
      })
      .catch((err: Error | AxiosError<BackendError>) => {
         if (isAxiosError<ErrorType>(err)) {
            errMessage = err.response?.data?.errors[0].defaultMessage || 'Something went wrong in checkCouponService'
         } else {
            errMessage = err.message
         }
         consoleMessage('payments checkCouponService()', errMessage, 'error')
         throw new Error(errMessage)
      })
}
