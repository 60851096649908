import axios, { AxiosError } from 'axios'

import { httpClient } from 'shared/lib'
import consoleMessage from 'shared/utils/helpers/consoleMessage'

import { ContactType } from './types'

type Result = {
   status: number
}

export default function sendMessageService(payload: ContactType) {
   let errMessage = ''

   return httpClient
      .post<Result>('/messages/send', payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((err: Error | AxiosError<BackendError>) => {
         if (axios.isAxiosError(err)) {
            errMessage = err.response?.data?.message || ''
         } else {
            errMessage = err.message
         }
         consoleMessage('CONTACT', errMessage, 'error')
         throw new Error(errMessage)
      })
}
