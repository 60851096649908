import React, { useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'

import SelectField from 'shared/components/SelectField'
import InputField from 'shared/components/InputField'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import MeetsIcon from 'assets/images/meet-icon.png'
import EdgeIcon from 'assets/images/browserIcons/edge.png'
import ChromeIcon from 'assets/images/browserIcons/chrome.png'
import OperaIcon from 'assets/images/browserIcons/opera.png'
import BraveIcon from 'assets/images/browserIcons/brave.png'
import VivaldiIcon from 'assets/images/browserIcons/vivaldi.png'
import FirefoxIcon from 'assets/images/browserIcons/firefox.png'

import sendMessageService from './sendMessageService'
import { ContactType } from './types'
import { pl, en, de } from './locales'
import schema from './schema'

const StyledLink = styled(RouterLink)(({ theme }) => ({
   textDecoration: 'none',
   color: 'grayText',
   '&:hover': {
      color: theme.palette.primary.main,
   },
}))

const StyledContactLink = styled('a')(({ theme }) => ({
   textDecoration: 'none',
   color: 'grayText',
   '&:hover': {
      color: theme.palette.primary.main,
   },
}))

const StyledTypography = styled(Typography)({
   color: 'grayText',
   fontSize: 15,
   marginTop: 2,
   marginLeft: -2,
})

export default function Contact() {
   const { t, i18n } = useTranslation('CONTACT')
   i18n.addResourceBundle('pl-PL', 'CONTACT', pl)
   i18n.addResourceBundle('en-EN', 'CONTACT', en)
   i18n.addResourceBundle('de-DE', 'CONTACT', de)
   const [isLoading, setIsLoading] = useState(false)
   const { addNotification } = useNotificationContext()

   const theme = useTheme()

   const formik = useFormik<ContactType>({
      initialValues: {
         message: '',
         typeMessage: 'System functionality problems',
      },
      validationSchema: schema,
      onSubmit: handleSubmit,
   })

   const { values, setValues } = formik

   function handleSubmit() {
      setIsLoading(true)
      const payload: ContactType = {
         message: values.message,
         typeMessage: values.typeMessage,
      }

      sendMessageService(payload)
         .then((res) => {
            if (res.status === 204) {
               addNotification('successSend', 'success')
               setValues({ message: '', typeMessage: '' })
            }
         })
         .catch((err) => {
            if (err) {
               addNotification('failedSend', 'error')
            }
         })
         .finally(() => setIsLoading(false))
   }

   return (
      <Box
         sx={{
            height: '50%',
            transform: { md: 'translateY(30%)' },
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
         }}
      >
         <Box
            sx={{
               width: { md: '50%', xs: '100%' },
               flexDirection: 'column',
               pt: 3,
               textAlign: 'center',
               display: 'flex',
               color: theme.palette.primary.main,
            }}
         >
            <Typography variant="h3">{t('anyProblems')}</Typography>
            <Typography variant="h5" sx={{ color: 'grayText', marginBottom: 1 }}>
               {t('contactWithUs')}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
               <StyledTypography variant="body1">
                  <LocalPhoneOutlinedIcon
                     style={{ color: theme.palette.primary.main, marginBottom: -4, marginRight: 4 }}
                  />
                  <StyledContactLink sx={{ fontSize: 15 }} href="tel:+48507425105">
                     Tel: 507 425 105
                  </StyledContactLink>
               </StyledTypography>
               <StyledTypography variant="body1">
                  <EmailOutlinedIcon style={{ color: theme.palette.primary.main, marginBottom: -4, marginRight: 4 }} />
                  <StyledContactLink sx={{ fontSize: 15 }} href="mailto:kontakt@motorro.pl">
                     kontakt@motorro.pl
                  </StyledContactLink>
               </StyledTypography>
               <StyledTypography variant="body1">
                  <img height={12} style={{ marginRight: 4 }} width="auto" src={MeetsIcon} alt="chrome" />
                  <StyledContactLink sx={{ fontSize: 15 }} href="https://meet.google.com/zrn-fdrh-qko" target="_blank">
                     {t('remoteHelp', { name: 'Emilia' })}
                  </StyledContactLink>
               </StyledTypography>
               <StyledTypography variant="body1">
                  <img height={12} style={{ marginRight: 4 }} width="auto" src={MeetsIcon} alt="chrome" />
                  <StyledContactLink sx={{ fontSize: 15 }} href="https://meet.google.com/umr-tdqj-vrp" target="_blank">
                     {t('remoteHelp', { name: 'Daniel' })}
                  </StyledContactLink>
               </StyledTypography>
               <StyledTypography variant="body1">
                  <img height={12} style={{ marginRight: 4 }} width="auto" src={MeetsIcon} alt="chrome" />
                  <StyledContactLink sx={{ fontSize: 15 }} href="https://meet.google.com/kky-rzok-yyx" target="_blank">
                     {t('remoteHelp', { name: 'Łukasz' })}
                  </StyledContactLink>
               </StyledTypography>
               <StyledTypography variant="body1">
                  <img height={12} style={{ marginRight: 4 }} width="auto" src={MeetsIcon} alt="chrome" />
                  <StyledContactLink sx={{ fontSize: 15 }} href="https://meet.google.com/abf-hffk-owq" target="_blank">
                     {t('remoteHelp', { name: 'Paweł' })}
                  </StyledContactLink>
               </StyledTypography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
               <Typography variant="h5" sx={{ mt: 2 }}>
                  {t('extensionDownload')}
               </Typography>
               <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                  <IconButton
                     onClick={() =>
                        window.open(
                           'https://chromewebstore.google.com/detail/motorro/cmhpolmcnpnamcfkjnmdjdgkokckbpkg?hl=pl',
                           '_blank'
                        )
                     }
                  >
                     <img height={30} width="auto" src={ChromeIcon} alt="chrome" />
                  </IconButton>
                  <IconButton
                     onClick={() => window.open('https://addons.mozilla.org/af/firefox/addon/motorro/', '_blank')}
                  >
                     <img height={30} width="auto" src={FirefoxIcon} alt="firefox" />
                  </IconButton>
                  <IconButton
                     onClick={() =>
                        window.open(
                           'https://chromewebstore.google.com/detail/motorro/cmhpolmcnpnamcfkjnmdjdgkokckbpkg?hl=pl',
                           '_blank'
                        )
                     }
                  >
                     <img height={30} width="auto" src={EdgeIcon} alt="edge" />
                  </IconButton>
                  <IconButton
                     onClick={() =>
                        window.open(
                           'https://chromewebstore.google.com/detail/motorro/cmhpolmcnpnamcfkjnmdjdgkokckbpkg?hl=pl',
                           '_blank'
                        )
                     }
                  >
                     <img height={30} width="auto" src={OperaIcon} alt="opera" />
                  </IconButton>
                  <IconButton
                     onClick={() =>
                        window.open(
                           'https://chromewebstore.google.com/detail/motorro/cmhpolmcnpnamcfkjnmdjdgkokckbpkg?hl=pl',
                           '_blank'
                        )
                     }
                  >
                     <img height={30} width="auto" src={VivaldiIcon} alt="vivaldi" />
                  </IconButton>
                  <IconButton
                     onClick={() =>
                        window.open(
                           'https://chromewebstore.google.com/detail/motorro/cmhpolmcnpnamcfkjnmdjdgkokckbpkg?hl=pl',
                           '_blank'
                        )
                     }
                  >
                     <img height={30} width="auto" src={BraveIcon} alt="brave" />
                  </IconButton>
               </Box>
            </Box>
         </Box>
         <Box
            sx={{
               width: { md: '50%', xs: '100%' },
               paddingX: 13,
               display: 'flex',
               mt: { xs: 3 },
               alignItems: 'center',
               flexDirection: 'column',
            }}
         >
            <FormikProvider value={formik}>
               <Form>
                  <Typography
                     variant="h3"
                     textAlign="center"
                     sx={{ color: theme.palette.primary.main, marginBottom: 1 }}
                  >
                     {t('contactForm')}
                  </Typography>
                  <Stack direction="column" spacing={1}>
                     <SelectField
                        inputVariant="outlined"
                        name="typeMessage"
                        label={t('messageType')}
                        options={[
                           { name: t('reportTypes.wholesaler'), value: 'Application of new wholesaler' },
                           { name: t('reportTypes.application'), value: 'System functionality problems' },
                           { name: t('reportTypes.suggestions'), value: 'Suggestions' },
                        ]}
                     />
                     <InputField fullWidth type="text" name="message" multiline rows={6} label={t('message')} />
                     <Typography textAlign="center" variant="body1" fontWeight="bold">
                        {t('bySending')}
                        <StyledLink to="https://motorro.pl/polityka-prywatnosci/" target="_blank">
                           {t('privacyPolicy')}{' '}
                        </StyledLink>
                        {t('and')}{' '}
                        <StyledLink to="https://motorro.pl/regulamin/" target="_blank">
                           {t('termsOfUse')}
                        </StyledLink>
                     </Typography>
                     <ContainedButton
                        disabled={isLoading}
                        fullWidth
                        type="submit"
                        variant="contained"
                        sx={{ height: 30, fontSize: 14 }}
                     >
                        {t('send')}
                     </ContainedButton>
                  </Stack>
               </Form>
            </FormikProvider>
         </Box>
      </Box>
   )
}
