import { AxiosError } from 'axios'
import { unauthHttpClient } from 'shared/lib'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { ReflinkDataType } from '../types'

export function removeProductService(
   reflinkUuid: string,
   verificationHeader: string,
   productUuid: string,
   wholesaleUuid: string
) {
   let errMessage = ''
   return unauthHttpClient
      .delete<ReflinkDataType>(`/client-carts/reflinks/${reflinkUuid}/products`, {
         data: {
            productUuid,
            wholesaleUuid,
         },
         headers: {
            'X-Reflink-Verification-Code': verificationHeader,
         },
      })
      .then((res) => ({ data: res.data, status: res.status }))
      .catch((err: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(err)) {
            errMessage = err.response?.data.errors[0].defaultMessage || 'Something went wrong with checkTokenService'
         } else {
            errMessage = err.message
         }
         throw new Error(errMessage)
      })
}
