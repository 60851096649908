import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Papa from 'papaparse'
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'
import UploadIcon from '@mui/icons-material/Upload'
import { styled, useTheme } from '@mui/material/styles'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { ArrowBack } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Badge from '@mui/material/Badge'

import GetOilLogo from 'assets/images/oil/index'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import OilModal from './components/OilModal/index'
import OutlinedButton from '../Buttons/OutlinedButton'
import ContainedButton from '../Buttons/ContainedButton'

export const SearchbarStyle = styled('div')(() => ({
   display: 'flex',
   alignItems: 'center',
}))

export const StyledInput = styled('input')({
   width: '50px',
   border: 'none',
   backgroundColor: 'rgba(0,0,0,0)',
   '&:hover': {
      cursor: 'pointer',
      fontWeight: 'bold',
   },
})

export default function Searchbar() {
   const navigate = useNavigate()
   const [openOilModal, setOpenOilModal] = useState(false)
   const { user } = useLogin()
   const theme = useTheme()
   const [csvValues, setCsvValues] = useState<string[][]>()
   const [isLoading, setIsLoading] = useState<boolean>(false)
   const [csvIndex, setCsvIndex] = useState<number>(0)
   const [searchParams] = useSearchParams()
   const location = useLocation()
   const [notFoundIndexes, setNotFoundIndexes] = useState<string[]>()
   const [activeIndex, setActiveIndex] = useState(0)
   const searchParam = searchParams.get('value') || ''
   const [searchValue, setSearchValue] = useState(searchParam)
   const { t } = useTranslation('TRANSLATION')

   function handleOnSearch() {
      if (searchValue && searchValue.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '').length > 1) {
         if (user.roles.includes(UserRoles.PZU)) {
            navigate({
               pathname: '/dashboard/erp-product',
               search: `?${createSearchParams({
                  value: searchValue,
               })}`,
            })
         } else {
            navigate({
               pathname: '/dashboard/search',
               search: `?${createSearchParams({
                  value: searchValue,
                  searchType: 'INDEX',
               })}`,
            })
         }
      }
   }

   function parseCSV(event: React.ChangeEvent<HTMLInputElement>) {
      if (event.target.files) {
         Papa.parse(event.target.files[0], {
            header: false,
            complete(result: any) {
               setCsvValues(result.data)
            },
         })
      }
   }

   function renderNotFoundIndexes() {
      if (notFoundIndexes) {
         return (
            <Box sx={{ display: 'flex', gap: 1, ml: 2, alignItems: 'center' }}>
               <ContainedButton
                  onClick={() => {
                     setActiveIndex((prevState) => prevState - 1)
                  }}
                  disabled={activeIndex === 0 || isLoading}
                  variant="contained"
               >
                  <ArrowBack />
               </ContainedButton>
               <Typography variant="body1">{`${activeIndex + 1}/${notFoundIndexes.length}`}</Typography>
               <ContainedButton
                  onClick={() => {
                     setActiveIndex((prevState) => prevState + 1)
                  }}
                  disabled={activeIndex === notFoundIndexes.length - 1 || isLoading}
                  variant="contained"
               >
                  <ArrowBack sx={{ transform: 'rotate(180deg)' }} />
               </ContainedButton>
               <IconButton onClick={() => setNotFoundIndexes(undefined)}>
                  <CloseIcon />
               </IconButton>
            </Box>
         )
      }
      return null
   }

   function renderReplacementsCsv() {
      if (user.roles.includes(UserRoles.DATA_MANAGER)) {
         if (!csvValues) {
            return (
               <Box sx={{ display: { md: 'flex', xs: 'none' }, gap: 2, ml: 2, maxWidth: 320 }}>
                  <Box
                     sx={{
                        border: `2px solid ${theme.palette.primary.light}`,
                        borderRadius: 0.5,
                        height: 35,
                        maxWidth: 130,
                        color: theme.palette.primary.light,
                        overflow: 'hidden',
                        '&:hover': {
                           borderColor: theme.palette.primary.darker,
                           color: theme.palette.primary.darker,
                           '*': {
                              cursor: 'pointer',
                           },
                        },
                     }}
                  >
                     <Box sx={{ display: 'flex', justifyItems: 'center' }}>
                        <span style={{ zIndex: 1, fontSize: 12, margin: '0 auto' }}>Replacements CSV</span>
                     </Box>
                     <input
                        style={{
                           zIndex: 2,
                           position: 'relative',
                           height: 43,
                           top: -53,
                           fontSize: 0,
                           opacity: 0,
                           width: 120,
                        }}
                        type="file"
                        accept=".csv"
                        onChange={(e) => parseCSV(e)}
                     />
                  </Box>
               </Box>
            )
         }
         if (csvValues) {
            return (
               <Box sx={{ display: { md: 'flex', xs: 'none' }, gap: 2, ml: 2, maxWidth: 320 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, fontSize: 20 }}>
                     <IconButton disabled={csvIndex === 0} onClick={() => setCsvIndex((prevState) => prevState - 1)}>
                        {'<'}
                     </IconButton>
                     {`${csvIndex}/${csvValues.length - 2}`}
                     <IconButton sx={{ '&:hover': { color: 'red' } }} onClick={() => setCsvValues(undefined)}>
                        X
                     </IconButton>
                     <IconButton
                        disabled={csvIndex === csvValues.length - 2}
                        onClick={() => setCsvIndex((prevState) => prevState + 1)}
                     >
                        {'>'}
                     </IconButton>
                  </Box>
               </Box>
            )
         }
      }
      return null
   }

   useEffect(() => {
      if (csvValues) {
         navigate(`/dashboard/search?value=${csvValues[csvIndex]}&index=null&searchType=SUBSTITUTES`)
      }
   }, [csvIndex, csvValues])

   useEffect(() => {
      if (!location.pathname.includes('search') && !location.pathname.includes('product')) {
         setSearchValue('')
      }
   }, [location.pathname])

   useEffect(() => {
      if (location.state) {
         if (location.state.indexes) {
            setNotFoundIndexes(location.state.indexes)
         }
      } else setNotFoundIndexes(undefined)
   }, [location])

   useEffect(() => {
      if (location.state) {
         if (location.state.indexes) {
            setActiveIndex(location.state.activeIndex || 0)
            setSearchValue(location.state.indexes[activeIndex])
         }
      }
   }, [navigate])

   useEffect(() => {
      setIsLoading(true)
      setTimeout(() => setIsLoading(false), 1500)
      if (notFoundIndexes) {
         navigate(
            {
               pathname: '/dashboard/search',
               search: `?${createSearchParams({
                  value: notFoundIndexes[activeIndex],
                  searchType: 'INDEX',
               })}`,
            },
            { state: { indexes: notFoundIndexes, activeIndex } }
         )
         setSearchValue(notFoundIndexes[activeIndex])
      }
   }, [activeIndex])

   return (
      <SearchbarStyle>
         <OutlinedInput
            sx={{
               transition: 'width .5s',
               pr: 0,
               [theme.breakpoints.up('md')]: {
                  width: '300px',
                  '&.Mui-focused': {
                     width: '400px',
                  },
               },
               [theme.breakpoints.down('sm')]: {
                  width: '200px',
               },
            }}
            size="small"
            value={searchValue}
            onFocus={(e) => {
               e.target.select()
            }}
            onKeyPress={(e) => {
               if (e.key === 'Enter') {
                  handleOnSearch()
               }
            }}
            onChange={(e) => setSearchValue(e.target.value)}
            autoFocus
            fullWidth
            inputProps={{
               maxLength: 30,
            }}
            placeholder={t('components.searchProduct.placeholder')}
            startAdornment={
               <InputAdornment position="start">
                  <Box component={SearchIcon} sx={{ color: 'text.disabled', width: 15, height: 15 }} />
               </InputAdornment>
            }
            color="primary"
            endAdornment={
               <Button
                  onClick={handleOnSearch}
                  disabled={
                     !searchValue || searchValue.replace(/[.,/#!$%^+&*;:{}=\-_<>@|\\[\]`~()'"\s?\\]/g, '').length < 2
                  }
                  variant="contained"
                  sx={{ height: 35 }}
               >
                  <Box component={SearchIcon} sx={{ color: 'white', width: 20, height: 20 }} />
               </Button>
            }
         />
         <Badge badgeContent="BETA" color="error">
            <Button onClick={() => setOpenOilModal(true)} sx={{ ml: 2 }} variant="contained">
               <img src={GetOilLogo()} style={{ width: 25, height: 'auto' }} alt="oil" />
            </Button>
         </Badge>
         <Box sx={{ display: { md: 'flex', xs: 'none' }, gap: 2, ml: 2, maxWidth: 320 }}>
            {!user.roles.includes(UserRoles.PRO) && !user.roles.includes(UserRoles.ULTRA) ? (
               <Tooltip title={t('components.csvSearch.disabledTooltip') || ''}>
                  <OutlinedButton disabled variant="outlined">
                     Upload CSV <UploadIcon color="disabled" sx={{ fontSize: 20, mb: -0.5 }} />
                  </OutlinedButton>
               </Tooltip>
            ) : (
               <OutlinedButton variant="outlined" onClick={() => navigate('/dashboard/import-cart')}>
                  Upload CSV <UploadIcon sx={{ fontSize: 20, mb: 0.5 }} />
               </OutlinedButton>
            )}
         </Box>
         {(user.roles.includes(UserRoles.PRO) || user.roles.includes(UserRoles.ULTRA)) && renderNotFoundIndexes()}

         {renderReplacementsCsv()}
         <OilModal open={openOilModal} handleClose={() => setOpenOilModal(false)} />
      </SearchbarStyle>
   )
}
