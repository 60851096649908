import consoleMessage from 'shared/utils/helpers/consoleMessage'
import { httpClient } from 'shared/lib'

import { FetchMinimumsResult } from './types'

export function fetchMinimums(hideUnchanged?: boolean): Promise<FetchMinimumsResult> {
   return httpClient
      .get(`wholesale/carts/logisticMinimums?hideUnchanged=${hideUnchanged || false}`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((err) => {
         consoleMessage('LOCATIONS fetchLocations()', err.message, 'error')
         throw new Error('apiErrors.500')
      })
}
